import { AttackAction } from './AttackAction'
import { Campaign } from './Campaign'
import { Feature } from './Feature'
import { CheckMap } from '../Common/Interfaces'
import { Character } from './Character'
import { TurnAction } from './TurnAction'
import { DiceCollection } from './Dice'
import { FightingStyle } from './FightingStyle'
import { Utility } from '../Common/Utility'
import { Dictionary } from '../Common/Types'

export class CharacterInfo {
  id: number
  name: string
  race: string
  url: string
  totalLevel: number
  proficiencyBonus: number
  classNames: [string, number][]
  analyticsClassNames: Dictionary[]
  featNames: string[]
  fightingStyles: FightingStyle[]
  attacks: AttackAction[]
  bonusAttacks: AttackAction[]
  avatarUrl: string
  spells: AttackAction[]
  abilityScores: number[]
  allFeatures: Feature[]
  campaign: Campaign
  spellcastingAbilityModifier: number

  attackCount: number
  fixedSpellLevel: number
  highestLevelSpellSlot: number
  defaultFeatures: CheckMap = {}

  prevState: null | null = null

  constructor(character: Character) {
    this.name = character.name
    this.id = character.id
    this.url = 'https://www.dndbeyond.com/characters/' + character.id
    this.race = character.race
    this.proficiencyBonus = character.proficiencyBonus
    this.spellcastingAbilityModifier = character.spellcastingAbilityModifier
    this.totalLevel = character.totalLevel
    this.classNames = character.classNames()
    this.featNames = character.featNames()
    this.fightingStyles = character.fightingStyles
    this.attacks = character.attackActions
    this.bonusAttacks = character.attackBonusActions()
    this.avatarUrl = character.avatarUrl
    this.spells = character.damagingSpellActions()
    this.abilityScores = character.abilityScores
    this.allFeatures = character.features
    this.attackCount = character.attackCount
    this.fixedSpellLevel = character.warlockSpellLevel()
    this.highestLevelSpellSlot = character.highestLevelSpellSlot
    this.defaultFeatures = character.defaultEnabledFeatureMap()
    this.campaign = character.campaign
    this.analyticsClassNames = character.classAnalyticsNames()
  }

  modifierForAbilityIndex(attrIndex: number): number {
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  totalDamageStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalDamageForTurns(turnActions).displayString()
  }

  totalDamageForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.autoCrit
        ? turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
        : turnAction.allDamageDiceCollection(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }
    return allDiceCollection
  }

  totalCritDiceStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalCritDiceForTurns(turnActions).displayString()
  }

  totalCritDiceForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }
    return allDiceCollection
  }

  classNamesForDisplay(): string {
    const sortedClassNames = this.classNames.sort((a, b) => b[1] - a[1])
    return sortedClassNames.map((className: [string, number]) => `${className[0]} ${className[1]}`).join(' / ')
  }

  featNamesForDisplay(): string {
    const sortedFeatNames = this.featNames.sort((a, b) => a.localeCompare(b))
    return sortedFeatNames.join(', ')
  }

  testDescriptorForDisplay(): string {
    return this.classNamesForDisplay() + ' – ' + this.featNamesForDisplay()
  }
}
