import { ABILITY_NAMES } from '../Common/Constants'
import { CheckMap, ActionLevelMap, NumberMap } from './Interfaces'
import { Dictionary } from './Types'

export class Utility {
  static readonly isDevelopment: boolean = Utility.loadDevelopmentStatus()

  static range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i)
  }
  static modifierForScore(score: number): number {
    return Math.floor((score - 10) / 2)
  }

  static loadDevelopmentStatus(): boolean {
    return process.env.NODE_ENV === 'development'
  }

  static kiPointsString(kiPoints: number): string {
    // return `${kiPoints} ki point${kiPoints > 1 ? 's' : ''}`
    return `${kiPoints} ki`
  }

  static shortNameForAbilityID(id: number | null): string | null {
    if (id === null) {
      return null
    }

    if (id < 0 || id > 6) {
      console.error('Looking up invalid ability ID ' + id)
      return null
    }

    return ABILITY_NAMES[id].substring(0, 3).toUpperCase()
  }

  static hackAcToSaveMod(ac: number) {
    return ac - 10
  }

  static hackAcToSaveModString(ac: number) {
    const save = this.hackAcToSaveMod(ac)
    return `${save >= 0 ? '+' : ''}${save}`
  }
}

export class URLUtility {
  static checkedFeaturesFromURLParams(): CheckMap | undefined {
    const urlParams = new URLSearchParams(window.location.search)
    const foundFeatures = urlParams.get('features')?.split(',').map(Number)

    return foundFeatures?.reduce((map, feature) => {
      map[feature] = true
      return map
    }, {} as CheckMap)
  }

  static advantageOverridesFromURLParams(): NumberMap | undefined {
    const urlParams = new URLSearchParams(window.location.search)
    const overrides = urlParams.get('overrides')
    if (overrides) {
      const overrideJSON = JSON.parse(overrides) as Dictionary
      const newOverrides: NumberMap = new NumberMap()

      for (const key in overrideJSON) {
        newOverrides[Number(key)] = overrideJSON[key]
      }

      return newOverrides
    }

    return undefined
  }

  static actionIdListFromURLParams(): ActionLevelMap[] | undefined {
    const urlParams = new URLSearchParams(window.location.search)
    const foundActions: number[] | undefined = urlParams.get('actions')?.split(',').map(Number)
    if (foundActions === undefined) {
      return undefined
    }

    const actionIdList: ActionLevelMap[] = []
    for (let i = 0; i < foundActions.length; i += 2) {
      actionIdList.push({ actionId: foundActions[i], level: foundActions[i + 1] } as ActionLevelMap)
    }
    return actionIdList
  }

  static urlFromFieldValue(urlFieldValue: string): URL | null {
    let url = null
    // www.dndbeyond.com/characters/117498251/YgW0tI
    try {
      if (!urlFieldValue.startsWith('http://') && !urlFieldValue.startsWith('https://')) {
        urlFieldValue = 'https://' + urlFieldValue
        console.log('Prepending https:// to URL:', urlFieldValue)
      }

      url = new URL(urlFieldValue)
      const parts: string[] = url.pathname.split('/')
      if (!parts.includes('characters')) {
        console.error('Could not parse URL:', urlFieldValue)
        return null
      }
    } catch (error) {
      console.error('Invalid URL:', urlFieldValue)
      return null
    }

    return url
  }

  static acFromURLParams(): number | null {
    const ac = this.loadParamFromURL('ac')
    return ac ? parseInt(ac) : null
  }

  static loadParamFromURL(param: string): string | null {
    const queryParameters = new URLSearchParams(window.location.search)
    return queryParameters.get(param)
  }

  static shareURLForParams(cid: number, params: URLSearchParams): string {
    const getTopLevelDomain = () => {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      const tld = parts[parts.length - 1]
      return tld
    }

    const tld = getTopLevelDomain()

    const baseURL =
      tld === 'localhost'
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
        : `${window.location.protocol}//${window.location.hostname}`

    return `${baseURL}/character/${cid}?${params.toString()}`
  }
}

export class DiceUtility {
  static diceFaceList(dice: number): number[] {
    return Utility.range(1, dice)
  }
}
