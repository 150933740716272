import { AttackType } from '../Common/Constants'
import { Dictionary } from '../Common/Types'

export class Range {
  origin: string // Weapon, etc
  range: number
  longRange?: number
  rangeValue?: number
  aoeType?: string
  aoeSize?: number
  aoeValue?: number

  constructor(data: Dictionary) {
    this.origin = data.origin
    this.range = data.range
    this.longRange = data.longRange
    this.rangeValue = data.rangeValue
    this.aoeType = data.aoeType
    this.aoeValue = data.aoeValue
    this.aoeSize = data.aoeSize
  }

  static makeWeaponRange(theRange: number, longRange?: number) {
    return new Range({
      origin: AttackType.WEAPON,
      range: theRange,
      longRange: longRange && longRange > 0 ? longRange : theRange
    })
  }

  static makeSpellAttackRange(theRange: number) {
    return new Range({
      origin: AttackType.SPELL_ATTACK,
      range: theRange,
      longRange: theRange
    })
  }

  static makeConeAoeRange(theRange: number) {
    return new Range({
      origin: 'Self',
      rangeValue: 0,
      aoeValue: theRange,
      aoeType: 'Cone'
    })
  }

  static makeLineAoeRange(theRange: number) {
    return new Range({
      origin: 'Self',
      rangeValue: 0,
      aoeValue: theRange,
      aoeType: 'Line'
    })
  }
}
