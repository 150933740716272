import { Dice } from '../DDB/Dice'
import { Activation } from '../DDB/Activation'
import { Feature, FeatureSource } from '../DDB/Feature'
import { Spell } from '../DDB/Spell'
import { Utility } from '../Common/Utility'
import { ABILITY_NAMES, Class, NON_BUFF_RACIAL_TRAITS, AttackType } from '../Common/Constants'
import { AttackAction } from '../DDB/AttackAction'
import { Weapon } from '../DDB/Weapon'
import { CharacterClass } from './CharacterClass'
import { Feat } from '../DDB/Feat'
import { WeaponMastery } from './WeaponMastery'
import { Creature } from '../DDB/Creature'
import { Attack } from '../DDB/Attack'
import { NumberMap } from '../Common/Interfaces'
import { Dictionary } from '../Common/Types'
import { Campaign } from './Campaign'
import { ClassActionFactory } from './ClassActionFactory'
import { FightingStyle } from './FightingStyle'
import { SpellSource, CheckMap } from '../Common/Interfaces'
import { Range } from './Range'

const FIGHTING_STYLES = [
  'Great Weapon Fighting',
  'Archery',
  'Dueling',
  'Two-Weapon Fighting',
  'Unarmed Fighting',
  'Blessed Warrior',
  'Blind Fighting',
  'Defense',
  'Druidic Warrior',
  'Interception',
  'Protection',
  'Superior Technique',
  'Thrown Weapon Fighting'
]

export class Character {
  name: string
  classes: CharacterClass[]
  features: Feature[]
  race: string

  attackActions: AttackAction[]
  extraAttackActions: AttackAction[]
  hasShieldEquipped: boolean = false
  hasOffHand: boolean = false
  id: number
  customizedInventoryValuesMap: Dictionary
  avatarUrl: string
  attackCount: number = 1

  feats: Feat[]
  weaponMasteries: WeaponMastery[]
  spells: Spell[]
  fightingStyles: FightingStyle[]
  weapons: Weapon[]

  abilityScores: number[]
  proficiencyBonus: number

  highestLevelSpellSlot: number
  spellcastingAbilityModifier: number
  spellAttackModifier: number
  spellSaveDC: number = 0
  classOptionNames: string[]
  creatures: Creature[] = []
  totalLevel: number
  backgroundId: number
  campaign: Campaign

  constructor(data: Dictionary, subclassSpells: Dictionary[]) {
    // Common root data
    const {
      inventory: inventoryData,
      classes: classesData,
      options: { class: classOptionsData, feat: featOptionsData },
      modifiers: { class: classModifiersData, feat: featModifiersData },
      feats: featsData,
      characterValues: characterValuesData,
      campaign: campaignData,
      actions: { class: classActionsData, feat: featActionsData, race: raceActionsData },
      race: { racialTraits: racialTraitsData },
      preferences: { showUnarmedStrike: showUnarmedStrikeData }
    } = data

    // Load basics
    this.name = data.name
    this.id = data.id
    this.avatarUrl = data.decorations.avatarUrl
    this.backgroundId = data.background?.definition?.id
    this.race = this.loadRace(data.race)

    this.classes = this.loadRealClasses(classesData, classOptionsData)
    this.totalLevel = this.calculateTotalLevel()
    this.highestLevelSpellSlot = this.calculateHighestLevelSpellSlot()

    this.proficiencyBonus = this.calculateProficiencyBonus()

    this.feats = this.loadFeats(featsData, featModifiersData, featOptionsData)
    this.weaponMasteries = this.loadWeaponMasteries(featModifiersData, classModifiersData)
    this.abilityScores = this.loadAbilityScores(data, inventoryData)
    this.campaign = new Campaign(campaignData)

    // Load custom character values
    const customizedNamesMap: Dictionary = this.loadCustomizedNames(characterValuesData)
    this.customizedInventoryValuesMap = this.loadCustomizedInventoryValues(characterValuesData)

    this.hasOffHand = this.hasOffhandWeapon(inventoryData, this.customizedInventoryValuesMap)
    this.fightingStyles = this.loadFightingStyles(classOptionsData, featOptionsData)
    this.classOptionNames = this.loadClassOptionNames(classOptionsData) // TODO what even is this?

    const pb = this.proficiencyBonus
    this.spellcastingAbilityModifier = this.calculateSpellcastingAbilityModifier()
    const invSpellAttackMods = this.calculateItemSpellAttackModifiers(inventoryData)
    const invSaveDCMods = this.calculateItemSpellSaveDCModifiers(inventoryData)

    this.spellAttackModifier = pb + this.spellcastingAbilityModifier + invSpellAttackMods
    this.spellSaveDC = 8 + pb + this.spellcastingAbilityModifier + invSaveDCMods

    if (this.spellcastingAbilityModifier === 0 && this.classLevel(Class.MONK) > 0) {
      this.spellSaveDC = 8 + pb + this.modifierForAbility('wisdom') + invSaveDCMods
    }

    this.hasShieldEquipped = this.isShieldEquipped(inventoryData)
    this.weapons = this.loadWeapons(inventoryData, customizedNamesMap, this.customizedInventoryValuesMap)

    const spellSources: SpellSource[] = this.loadSpellSources(data, subclassSpells)
    this.spells = this.loadSpells(spellSources, customizedNamesMap)

    this.features = this.loadAllFeatures(
      classActionsData,
      classesData,
      racialTraitsData,
      raceActionsData,
      classOptionsData,
      inventoryData,
      this.feats,
      this.fightingStyles
    )

    this.creatures = this.loadCreatures(data.creatures)

    this.extraAttackActions = this.loadExtraAttackActions(
      classActionsData,
      featActionsData,
      raceActionsData,
      racialTraitsData,
      this.creatures,
      this.spells,
      customizedNamesMap,
      showUnarmedStrikeData
    )
    this.attackActions = this.loadAttackActions(this.weapons, this.extraAttackActions)
    this.applyWeaponMasteriesToAttackActions(this.attackActions, this.weaponMasteries)

    this.attackCount = this.countAttacksPerTurn(classModifiersData)
  }

  loadRace(raceData: Dictionary): string {
    const { fullName, baseRaceName } = raceData
    return fullName || baseRaceName
  }

  hasOffhandWeapon(inventoryData: Dictionary[], inventoryMap: Dictionary): boolean {
    for (const itemData of inventoryData) {
      const { definition, equipped, id } = itemData
      const { damage, canEquip } = definition
      if (damage !== null && canEquip === true && equipped === true) {
        if (id in inventoryMap) {
          const valueMap = inventoryMap[id]
          for (const [typeID, value] of valueMap) {
            if (typeID === 18 && value === true) {
              return true
            }
          }
        }
      }
    }

    return false
  }

  defaultEnabledFeatureMap(): CheckMap {
    const racialTraits: CheckMap = {}

    for (const feature of this.features) {
      if (feature.defaultEnabled) {
        racialTraits[feature.id] = true
      }
    }

    return racialTraits
  }

  calculateHighestLevelSpellSlot(): number {
    const highestLevelSpellSlots = this.classes.map((characterClass) => characterClass.highestLevelSpellSlot())
    return Math.max(...highestLevelSpellSlots)
  }

  warlockSpellLevel(): number {
    const warlockLevel = this.classLevel(Class.WARLOCK)
    return warlockLevel === 0 ? 0 : Math.min(5, Math.ceil(warlockLevel / 2))
  }

  loadCreatures(creaturesData: Dictionary[]): Creature[] {
    const creatures: Creature[] = []
    for (const creatureData of creaturesData) {
      creatures.push(new Creature(creatureData, this))
    }
    return creatures
  }

  countAttacksPerTurn(data: Dictionary[]) {
    let extraAttackCount: number = 0
    for (const classFeature of data) {
      if (classFeature.subType === 'extra-attacks' && classFeature.isGranted) {
        extraAttackCount = Math.max(extraAttackCount, parseInt(classFeature.value))
      }
    }

    return 1 + extraAttackCount
  }

  loadSpellSources(data: Dictionary, subclassSpells: Dictionary[]) {
    const spellSources: SpellSource[] = []

    for (const charClass of data.classSpells) {
      spellSources.push({ spells: charClass.spells, forceKnown: false })
    }

    for (const subclassSpell of subclassSpells) {
      spellSources.push({ spells: subclassSpell.data, forceKnown: true })
    }

    spellSources.push({ spells: data.spells.class, forceKnown: false })
    spellSources.push({ spells: data.spells.spells, forceKnown: false })
    spellSources.push({ spells: data.spells.race, forceKnown: true })
    spellSources.push({ spells: data.spells.feat, forceKnown: true })
    spellSources.push({ spells: data.spells.background, forceKnown: true })

    return spellSources
  }

  rageBonusDamage(): number {
    const barbarianLevel = this.classLevel(Class.BARBARIAN)
    if (barbarianLevel >= 16) {
      return 4
    } else if (barbarianLevel >= 9) {
      return 3
    }
    return 2
  }

  clericDivineSparkDieCount(): number {
    const clericLevel = this.classLevel(Class.CLERIC)
    if (clericLevel >= 18) {
      return 4
    } else if (clericLevel >= 13) {
      return 3
    } else if (clericLevel >= 7) {
      return 2
    }
    return 1
  }

  superiorityDice(): Dice {
    const fighterLevel = this.classLevel(Class.FIGHTER)
    if (fighterLevel >= 18) {
      return Dice.Create(1, 12)
    } else if (fighterLevel >= 10) {
      return Dice.Create(1, 10)
    } else if (fighterLevel >= 3) {
      return Dice.Create(1, 8)
    }

    console.error('Trying to get superiority dice for non-fighter')
    return Dice.Create(0, 0)
  }

  modifierForAbilityIndex(attrIndex: number) {
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  modifierForAbility(ability: string) {
    const attrIndex = ABILITY_NAMES.indexOf(ability)
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  calculateProficiencyBonus(): number {
    return 1 + Math.ceil(this.totalLevel / 4)
  }

  calculateSpellModsOfType(inventoryData: Dictionary[], type: string): number {
    let modifierValue = 0
    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const modifiersData = definitionData.grantedModifiers
      for (const modifierData of modifiersData) {
        if (modifierData.type === 'bonus' && modifierData.subType.includes(type)) {
          modifierValue += modifierData.value // Or is it fixedValue?
        }
      }
    }
    return modifierValue
  }

  calculateItemSpellAttackModifiers(inventoryData: Dictionary[]): number {
    return this.calculateSpellModsOfType(inventoryData, 'spell-attacks')
  }

  calculateItemSpellSaveDCModifiers(inventoryData: Dictionary[]): number {
    return this.calculateSpellModsOfType(inventoryData, 'spell-save-dc')
  }

  baseDieSizeForLevel(level: number): number {
    if (level === 0) {
      return 0
    } else if (level <= 4) {
      return 4
    } else if (level <= 10) {
      return 6
    } else if (level <= 16) {
      return 8
    }

    return 10
  }

  sporeDamageDie(): Dice {
    const druidLevel = this.classLevel(Class.DRUID)
    if (druidLevel >= 14) {
      return Dice.Create(1, 10)
    } else if (druidLevel >= 10) {
      return Dice.Create(1, 8)
    } else if (druidLevel >= 6) {
      return Dice.Create(1, 6)
    }
    return Dice.Create(1, 4)
  }

  soulknifeEnergyDieSize(characterClass: Class): number {
    // 2024 Soulknife. Scales trhe same as the old one.
    return this.psionicEnergyDieSize(characterClass)
  }

  psionicEnergyDieSize(characterClass: Class): number {
    const level = this.classLevel(characterClass)
    return 2 + this.baseDieSizeForLevel(level)
  }

  cantripDieCount(): number {
    return Math.floor((this.totalLevel + 1) / 6) + 1
  }

  maxKiPointsForMonkSpell() {
    const level = this.classLevel(Class.MONK)
    if (level >= 17) {
      return 6
    } else if (level >= 13) {
      return 5
    } else if (level >= 9) {
      return 4
    } else if (level >= 5) {
      return 3
    }
    return 0
  }

  monkDieSize(): number {
    const level = this.classLevel(Class.MONK)
    return this.baseDieSizeForLevel(level)
  }

  bardicInspirationDieSize(level?: number): number {
    if (!level) {
      level = this.classLevel(Class.BARD)
    }
    return level === 20 ? 12 : Math.floor(level / 5) * 2 + 6
  }

  loadSpells(spellSources: SpellSource[], customNameMap: Dictionary): Spell[] {
    const spells: Spell[] = []
    for (const spellSource of spellSources) {
      const spellList = spellSource.spells
      if (spellList) {
        for (const spellData of spellList) {
          const spell = new Spell(spellData, this)
          if (spell.isPrepared || spellSource.forceKnown) {
            if (spell.id in customNameMap) {
              spell.displayName = customNameMap[spell.id]
            }

            spells.push(spell)
          }
        }
      }
    }

    return spells
  }

  classLevel(name: Class): number {
    return this.classes!.find((characterClass) => String(characterClass.className) === String(name))?.level || 0
  }

  calculateSpellcastingAbilityModifier(): number {
    // TODO: THIS IS A HACK FOR NOW, JUST FINDS FIRST. WON'T WORK FOR MULTIPLE SPELLCASTER CLASSES
    for (const characterClass of this.classes!) {
      if (characterClass.spellCastingAbility !== null) {
        const ability = characterClass.spellCastingAbility
        const score = this.abilityScores![ability]
        return Utility.modifierForScore(score)
      }
    }

    return 0
  }

  loadCustomizedInventoryValues(characterValuesData: Dictionary[]): Dictionary {
    const map: Dictionary = {}
    for (const valueData of characterValuesData) {
      const { valueId, typeId, value } = valueData
      if (valueId in map) {
        map[valueId].push([typeId, value])
      } else {
        map[valueId] = [[typeId, value]]
      }
    }
    return map
  }

  loadCustomizedNames(characterValuesData: Dictionary[]): Dictionary {
    const map: Dictionary = {}
    for (const valueData of characterValuesData) {
      const { valueId: id, typeId, value } = valueData
      if (typeId === 8) {
        map[id] = value
      }
    }
    return map
  }

  loadWeapons(inventoryData: Dictionary[], inventoryTitlesMap: Dictionary, inventoryValuesMap: Dictionary): Weapon[] {
    const weapons: Weapon[] = []
    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const weaponBehaviors: Dictionary[] = definitionData.weaponBehaviors
      const hasDamage = definitionData.damage !== null || weaponBehaviors.some((behavior) => 'damage' in behavior)

      const isEquipped = definitionData.canEquip === true && inventoryItemData.equipped === true
      const isWeapon = definitionData.filterType === 'Weapon' || hasDamage
      if (isWeapon && isEquipped) {
        weapons.push(new Weapon(inventoryItemData, inventoryTitlesMap, inventoryValuesMap, this))
      }
    }
    return weapons
  }

  loadClassOptionNames(classOptionsData: Dictionary[]): string[] {
    const options: string[] = []
    for (const optionsData of classOptionsData) {
      const definition = optionsData.definition
      const name = definition.name
      options.push(name)
    }
    return options
  }

  loadFightingStyles(classOptionsData: Dictionary[], featData: Dictionary[]): FightingStyle[] {
    const fightingStyles: FightingStyle[] = []

    for (const classOptionData of [classOptionsData, featData].flat()) {
      const definitionData = classOptionData.definition
      const optionName = definitionData.name
      if (FIGHTING_STYLES.includes(optionName)) {
        const fightingStyle = new FightingStyle(definitionData)
        fightingStyles.push(fightingStyle)
      }
      // }

      // for (const feat of featData) {
      //   const definitionData = feat.definition
      //   const optionName = definitionData.name
      //   if (FIGHTING_STYLES.includes(optionName)) {
      //     const fightingStyle = new FightingStyle(definitionData)
      //     fightingStyles.push(fightingStyle)
      //   }
    }

    return fightingStyles
  }

  calculateTotalLevel(): number {
    return this.classes.reduce((totalLevel, characterClass) => totalLevel + characterClass.level, 0)
  }

  loadRealClasses(classesData: Dictionary[], classOptions: Dictionary[]): CharacterClass[] {
    return classesData.map((classData: Dictionary) => new CharacterClass(classData, classOptions))
  }

  loadFeats(featsData: Dictionary[], featModifiersData: Dictionary[], featOptionsData: Dictionary[]): Feat[] {
    return featsData.map((featData: Dictionary) => new Feat(featData, featModifiersData, featOptionsData))
  }

  loadWeaponMasteries(featModifiersData: Dictionary[], classModifiersData: Dictionary[]): WeaponMastery[] {
    const masteries = []

    for (const modifier of [featModifiersData, classModifiersData].flat()) {
      if (modifier.type === 'weapon-mastery') {
        masteries.push(new WeaponMastery(modifier))
      }
    }

    return masteries
  }

  abilityScoreForIndex(index: number): number {
    return this.abilityScores[index]
  }

  strength(): number {
    return this.abilityScores[0]
  }

  dexterity(): number {
    return this.abilityScores[1]
  }

  constitution(): number {
    return this.abilityScores[2]
  }

  intelligence(): number {
    return this.abilityScores[3]
  }

  wisdom(): number {
    return this.abilityScores[4]
  }

  classNames(): [string, number][] {
    return this.classes.map((characterClass) => {
      return [`${characterClass.classDisplayString()}`, characterClass.level]
    })
  }

  classAnalyticsNames(): Dictionary[] {
    return this.classes.map((characterClass) => {
      return {
        className: characterClass.className,
        subclassName: characterClass.subclassName ?? '',
        level: characterClass.level
      }
    })
  }

  featNames(): string[] {
    return this.feats.map((feat) => feat.name)
  }

  attackRollSpells(): Spell[] {
    return this.spells.filter((spell) => spell.requiresAttackRoll)
  }

  damagingSpells(): Spell[] {
    return this.spells.filter((spell) => spell.dice && spell.dice.diceCount)
  }

  // TODO This is a clumsy way to adapt the data
  damagingSpellActions(): AttackAction[] {
    return this.damagingSpells().map((spell) => spell.attackAction())
  }

  attackBonusActions(): AttackAction[] {
    return this.extraAttackActions.filter((action) => action.activation.usesBonusAction())
  }

  applyWeaponMasteriesToAttackActions(attackActions: AttackAction[], masteries: WeaponMastery[]) {
    for (const attack of attackActions) {
      for (const mastery of masteries) {
        if (attack.attributes.weaponType === mastery.weaponType) {
          attack.attributes.weaponMastery = mastery.name
        }
      }
    }
  }

  loadAttackActions(weapons: Weapon[], extraAttackActions: AttackAction[]): AttackAction[] {
    const attacks = weapons.map((weapon) => new Attack(weapon, this))
    const attackActions: AttackAction[] = []
    attackActions.push(
      ...attacks.filter((attack) => !attack.attributes.isOffHand).map((attack) => attack.attackAction())
    )
    attackActions.push(...extraAttackActions.filter((action) => action.activation.usesAction()))

    return attackActions
  }

  isShieldEquipped(inventoryData: Dictionary[]): boolean {
    for (const inventoryItemData of inventoryData) {
      const { definition, equipped } = inventoryItemData
      const { type, baseArmorName } = definition
      if ((type === 'Shield' || baseArmorName === 'Shield') && equipped === true) {
        return true
      }
    }
    return false
  }

  getSubStats(data: Dictionary[], scoreId: number, base: number): number {
    const index = scoreId - 1
    const stat = data[index]
    const value = stat?.value
    return value ?? base
  }

  getRacialStatMod(statMods: Dictionary[], scoreId: number): number {
    const index = scoreId - 1
    const targetSubtype = ABILITY_NAMES[index] + '-score'

    for (const mod of statMods) {
      if (mod.type !== 'bonus') continue
      if (mod.subType === targetSubtype) return parseInt(mod.value)
    }

    return 0
  }

  loadExtraAttackActions(
    classActions: Dictionary[],
    featActions: Dictionary[],
    racialActions: Dictionary[],
    racialTraits: Dictionary[],
    creatures: Creature[],
    spells: Spell[],
    customNameMap: Dictionary,
    showUnarmedStrike: boolean
  ): AttackAction[] {
    const attackActions: AttackAction[] = []

    const classActionFactory = new ClassActionFactory(this)
    const ba = Activation.BonusAction()

    for (const weapon of this.weapons) {
      if (!weapon.isOffHand) {
        continue
      }
      const dualWielder = this.featNames().includes('Dual Wielder')
      if (weapon.isLight || (dualWielder && !weapon.isTwoHanded)) {
        const attack = new Attack(weapon, this)
        const offhand = true
        const attackAction = attack.attackAction(offhand)
        attackActions.push(attackAction)
      }
    }

    // Racial actions
    for (const action of racialActions) {
      if (action.name.startsWith('Breath Weapon')) {
        const coneRange: Range = Range.makeConeAoeRange(15)
        const coneId = parseInt(action.id)
        const attackAction = classActionFactory.createBreathWeaponAction(coneId, action, coneRange)
        attackActions.push(attackAction)

        const lineRange: Range = Range.makeLineAoeRange(30)
        const lineId = coneId + 1000001
        const lineAttackAction = classActionFactory.createBreathWeaponAction(lineId, action, lineRange)
        attackActions.push(lineAttackAction)
      }
    }

    // Racial traits
    for (const trait of racialTraits) {
      const definition = trait.definition
      let newAction = undefined
      if (definition.name === 'Claws') {
        let clawDice = Dice.Create(1, 4) // Leonin
        if (this.race === 'Tabaxi' || this.race === 'Tortle') {
          clawDice = Dice.Create(1, 6)
        }
        newAction = classActionFactory.createBasicUnarmedStrikeAttackAction(definition, clawDice)
      } else if (['Bite', 'Horns', 'Talons', 'Cat’s Claws', 'Ram'].includes(definition.name)) {
        newAction = classActionFactory.createBasicUnarmedStrikeAttackAction(definition, Dice.Create(1, 6))
        newAction.attributes.isHorns = definition.name === 'Horns'
      } else if (!NON_BUFF_RACIAL_TRAITS.includes(definition.name)) {
        //console.log(`Unknown Racial Trait: ${definition.name}`) // Too many for noww
      }

      if (newAction) {
        attackActions.push(newAction)
      }
    }

    // Creatures
    for (const creature of creatures) {
      for (const attack of creature.attacks) {
        attackActions.push(attack)
      }
    }

    // Class Actions
    for (const action of classActions) {
      const newAction = classActionFactory.parseAction(action, attackActions)
      if (newAction) {
        attackActions.push(newAction)
      }
    }

    const poleStrike = featActions.find((feat) => feat.name === 'Pole Strike')
    const pamBonus = featActions.find((feat) => feat.name === 'Polearm Master - Bonus Attack')

    if (pamBonus || poleStrike) {
      const strikeId = poleStrike ? poleStrike.id : pamBonus!.id
      for (const weapon of this.weapons) {
        if ((pamBonus && weapon.isPolearm) || (poleStrike && weapon.is2024Polearm)) {
          const polearmAttack = new Attack(weapon, this)
          const name = `${pamBonus ? 'Polearm Master Bonus' : 'Pole Strike '} (${weapon.name})`
          const polearmAttackAction = polearmAttack.attackAction()
          const toHit = polearmAttackAction.attackMod
          const damageMod = polearmAttack.damageMod
          const dice = Dice.Create(1, 4, damageMod)
          const attributes = { ...weapon.weaponAttributes() }
          if (attributes.isThrown) {
            // The only thrown polearm is a spear, give it 5ft melee range
            attributes.range = Range.makeWeaponRange(5)
          }

          attributes.id = attributes.id + strikeId
          const pamBonusAttack = new AttackAction(attributes.id, name, toHit, dice, attributes, ba)
          attackActions.push(pamBonusAttack)
        }
      }
    }

    const fakeIDBase = 100000000
    let fakeIDIncrement = 1

    for (const spell of spells) {
      if (spell.name === 'Spiritual Weapon') {
        const toHit = this.spellAttackModifier
        const abilityMod = this.spellcastingAbilityModifier
        const maxSpellLevel = this.highestLevelSpellSlot
        const diceCount = Math.floor(maxSpellLevel / 2)
        const dice = Dice.Create(diceCount, 8, abilityMod)
        const attributes = { range: spell.range, type: AttackType.SPELL_ATTACK }
        const newAction = new AttackAction(
          fakeIDBase + fakeIDIncrement++,
          spell.displayName,
          toHit,
          dice,
          attributes,
          ba
        )
        attackActions.push(newAction)

        this.spells = this.spells.filter((value) => value.id !== spell.id)
      } else if (spell.name === 'Shadow Blade') {
        const maxLevel = Math.min(this.highestLevelSpellSlot, 7)
        const dieCount = 2 + Math.floor((maxLevel - 1) / 2)
        const shadowBladeAction = classActionFactory.createShadowBlades(
          spell,
          Dice.Create(dieCount, 8),
          Activation.Action()
        )
        attackActions.push(shadowBladeAction)

        this.spells = this.spells.filter((value) => value.id !== spell.id)
      } else if (spell.name === 'Magic Stone') {
        const toHit = this.spellAttackModifier
        const dice = spell.damageDice()
        const attrs = {
          range: Range.makeWeaponRange(60),
          type: AttackType.SPELL_ATTACK,
          isThrown: true
        }
        const stone = new AttackAction(spell.id, spell.displayName, toHit, dice, attrs, Activation.Action())
        attackActions.push(stone)
        this.spells = this.spells.filter((value) => value.id !== spell.id)
      } else if (spell.name === 'Shillelagh') {
        const theSpell = { ...spell, abilityModifierStatId: this.spellcastingAbilityModifier }
        const attack = classActionFactory.createWeaponAttackAction(spell.displayName, theSpell, 5)
        attack.activation = Activation.Action()
        attackActions.push(attack)
        this.spells = this.spells.filter((value) => value.id !== spell.id)
      } else if (spell.name === 'Flame Arrows') {
        // This is a buff, remove it
        this.spells = this.spells.filter((value) => value.id !== spell.id)
      }
    }

    if (showUnarmedStrike) {
      let unarmedStrikeDice = Dice.flatAmountDie(1)

      if (this.featNames().includes('Tavern Brawler')) {
        unarmedStrikeDice = Dice.Create(1, 4)
      }

      if (this.classLevel(Class.MONK) > 0) {
        unarmedStrikeDice = Dice.Create(1, this.monkDieSize())
      }

      const fightingStyleNames = this.fightingStyles.map((style) => style.name)
      if (fightingStyleNames.includes('Unarmed Fighting')) {
        const strId = ABILITY_NAMES.indexOf('strength') + 1
        const action = {
          abilityModifierStatId: strId,
          dice: Dice.Create(1, 8),
          id: fakeIDBase + fakeIDIncrement++,
          activation: Activation.Action()
        }

        const unarmedStrikeAction = classActionFactory.createWeaponAttackAction('Unarmed Fighting', action, 5)
        unarmedStrikeAction.attributes.subType = 'unarmed'
        attackActions.push(unarmedStrikeAction)

        const armedAction = {
          abilityModifierStatId: strId,
          dice: Dice.Create(1, 6),
          id: fakeIDBase + fakeIDIncrement++,
          activation: Activation.Action()
        }
        const armedUnarmedStrikeAction = classActionFactory.createWeaponAttackAction(
          'Unarmed Fighting (armed)',
          armedAction,
          5
        )
        armedUnarmedStrikeAction.attributes.subType = 'unarmed'
        attackActions.push(armedUnarmedStrikeAction)
      }

      const unarmedStrikeAction = classActionFactory.createUnarmedStrikeAttackAction(
        'Unarmed Strike',
        Activation.Action(),
        fakeIDBase + fakeIDIncrement++,
        unarmedStrikeDice
      )

      attackActions.push(unarmedStrikeAction)

      // // At the start of each of your turns, you can deal 1d4 Bludgeoning damage to one creature Grappled by you.
      const hasBardicDamage = classActions.some((classAction) => classAction.name === 'Bardic Damage')
      if (hasBardicDamage) {
        const bardicDieSize = this.bardicInspirationDieSize()
        const dice = Dice.Create(1, bardicDieSize)
        const bardicDamageAction = classActionFactory.createUnarmedStrikeAttackAction(
          'Bardic Damage',
          Activation.Action(),
          fakeIDBase + fakeIDIncrement++,
          dice,
          1,
          true
        )

        attackActions.push(bardicDamageAction)
      }

      if (this.classLevel(Class.MONK) > 0) {
        const unarmedStrikeBonusAction = classActionFactory.createUnarmedStrikeAttackAction(
          'Unarmed Strike',
          Activation.BonusAction(),
          fakeIDBase + fakeIDIncrement++,
          unarmedStrikeDice
        )
        attackActions.push(unarmedStrikeBonusAction)
      }

      const unarmedFighting2024Feat = this.feats.find((feat) => feat.name === 'Unarmed Fighting')

      if (unarmedFighting2024Feat) {
        const spellAttackRange = Range.makeSpellAttackRange(5)
        const attributes = {
          range: spellAttackRange,
          attackMod: 0,
          type: AttackType.SPELL,
          requiresSavingThrow: false,
          requiresAttackRoll: false
        }

        const dice = Dice.Create(1, 4)
        const grappleDamageAction = new AttackAction(
          unarmedFighting2024Feat.id + fakeIDBase,
          'Unarmed Fighting: Grapple Damage',
          0,
          dice,
          attributes,
          Activation.Action()
        )
        attackActions.push(grappleDamageAction)
      }
    }

    for (const attackAction of attackActions) {
      if (attackAction.id in customNameMap) {
        attackAction.name = customNameMap[attackAction.id]
      }
    }
    return attackActions
  }

  createUpcastSmiteFeatures(
    baseId: number,
    action: Dictionary,
    minLevel: number,
    maxLevel: number,
    features: Feature[],
    source: FeatureSource
  ) {
    const highestLevelSlot = this.highestLevelSpellSlot

    for (let level = minLevel; level <= Math.min(highestLevelSlot, maxLevel); level++) {
      const newSmiteAction = { ...action }
      newSmiteAction.upcastLevel = level
      newSmiteAction.id = baseId + level
      const leveledSmite = new Feature(newSmiteAction, this, source)

      features.push(leveledSmite)
    }
  }

  loadAllFeatures(
    classActions: Dictionary[],
    classesData: Dictionary[],
    racialTraitsData: Dictionary[],
    raceActionsData: Dictionary[],
    classOptionsData: Dictionary[],
    inventoryData: Dictionary[],
    feats: Feat[],
    fightingStyles: FightingStyle[]
  ): Feature[] {
    const features: Feature[] = []

    // TO make this some global ID generator?
    let fakeIDIncrement = 1
    const fakeIDBase = 1000000

    // Create external effects
    const effect = FeatureSource.Effect

    features.push(new Feature({ name: 'Advantage this turn', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Advantage on next attack', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Bless', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Bardic Inspiration die', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Haste', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Perkins crit', id: fakeIDBase + fakeIDIncrement++ }, this, effect))
    features.push(new Feature({ name: 'Heroic Inspiration', id: fakeIDBase + fakeIDIncrement++ }, this, effect))

    // Fighting Styles
    for (const fightingStyle of fightingStyles) {
      features.push(new Feature({ name: fightingStyle.name, id: fightingStyle.id }, this, FeatureSource.FightingStyle))
    }

    for (const action of classActions) {
      const feature = new Feature(action, this, FeatureSource.Class)
      features.push(feature)

      // Create leveled smites if needed
      if (action.name === 'Divine Smite') {
        const smiteBaseId = fakeIDBase + feature.id
        features.push(
          new Feature({ name: 'Divine Smite (Undead or Fiend)', id: smiteBaseId + 1 }, this, FeatureSource.Class)
        )

        const minSmiteLevel = 2
        const maxSmiteLevel = 4 // Can only go to 5d8
        this.createUpcastSmiteFeatures(smiteBaseId, action, minSmiteLevel, maxSmiteLevel, features, FeatureSource.Class)
      }

      // Create 2 and 3 ki version of some monk skills
      if (['Focused Aim', 'Sharpen the Blade'].includes(action.name)) {
        for (let ki: number = 2; ki <= 3; ki++) {
          const focusedAim: Dictionary = { ...action, kiPoints: ki, id: fakeIDBase + feature.id + ki }
          features.push(new Feature(focusedAim, this, FeatureSource.Class))
        }
      }
    }

    // Grab subclass features too
    for (const characterClass of classesData) {
      const subclass = characterClass.subclassDefinition
      if (!subclass) {
        continue
      }

      const subclassDefinition = characterClass.subclassDefinition
      if (subclassDefinition) {
        for (const feature of subclassDefinition.classFeatures) {
          const newFeature = new Feature(feature, this, FeatureSource.Class)
          if (newFeature.isBuff) {
            features.push(newFeature)

            if (newFeature.name === 'Assassinate') {
              const name = `${newFeature.name}: Surprised`

              const id = fakeIDBase + newFeature.id + 1
              const surprised = new Feature({ name, id }, this, FeatureSource.Class)
              features.push(surprised)
            }
          }
        }
      }
    }

    // Grab racial traits

    for (const trait of racialTraitsData) {
      const definition = trait.definition
      const newFeature = new Feature(definition, this, FeatureSource.RacialTrait)

      if (newFeature.isBuff) {
        features.push(newFeature)
      }
    }

    // Racial Actions (2024)
    for (const racialRaction of raceActionsData) {
      const feature = new Feature(racialRaction, this, FeatureSource.RacialTrait)
      if (feature.isBuff) {
        features.push(feature)
      }
    }

    // Next do spells (for hex, smites, hunters mark, etc)
    const spellsToRemove: Spell[] = []
    for (const spell of this.spells) {
      if (spell.name.includes('Smite') && spell.higherLevelDice) {
        const smiteBaseId = fakeIDBase + spell.id
        const minSpellLevel = Math.max(spell.level, this.warlockSpellLevel())
        this.createUpcastSmiteFeatures(smiteBaseId, spell, minSpellLevel, 9, features, FeatureSource.Spell)
      } else {
        const feature = new Feature(spell, this, FeatureSource.Spell)

        if (feature.isBuff) {
          features.push(feature)
          spellsToRemove.push(spell)

          if (feature.name === 'Booming Blade') {
            const bbMove = new Feature(
              { name: 'Booming Blade: Target Moves', id: fakeIDBase + feature.id + 1 },
              this,
              FeatureSource.Spell
            )
            features.push(bbMove)
          }
        }
      }

      if (spell.name === 'Toll the Dead') {
        const name = 'Toll the Dead: Injured'
        const id = fakeIDBase + spell.id + 1
        const tollTheDead = new Feature({ name, id }, this, FeatureSource.Spell)
        features.push(tollTheDead)
      }
    }

    // If a spell is just a buff, remove it from the spell list
    for (const spellToRemove of spellsToRemove) {
      this.spells = this.spells.filter((value) => value.id !== spellToRemove.id)
    }

    // Grab specific Feats, like Sharpshooter and GWM
    for (const feat of feats) {
      const action = {
        name: feat.name,
        activation: Activation.None(),
        id: feat.id,
        requiresConcentration: false,
        snippet: '',
        option: feat.optionDefinition
      }

      if (action) {
        const source: FeatureSource = feat.isFightingStyle ? FeatureSource.FightingStyle : FeatureSource.Feat

        const feature = new Feature(action, this, source)
        if (feature.isBuff) {
          features.push(feature)
        }
      }
    }

    // TODO - look at other data.options.class stuff that AREN'T invocations.
    // this.loadClassOptionNames(optionsData.class) is starting to do that
    const classOptions = this.loadChosenClassOptions(classOptionsData)
    for (const classOption of classOptions) {
      if (FIGHTING_STYLES.includes(classOption.name)) continue
      const feature = new Feature(classOption, this, FeatureSource.ClassOption)
      features.push(feature)
    }

    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const itemFeature = new Feature(definitionData, this, FeatureSource.Item)
      if (itemFeature.isBuff) {
        features.push(itemFeature)
      }
    }

    return features.sort((a, b) => a.name.localeCompare(b.name))
  }

  loadChosenClassOptions(classOptions: Dictionary[]): Dictionary[] {
    const ENTITY_TYPE_ID = 258900837 // TODO - this may not be right, but works for invocations at least
    return classOptions
      .filter((classOption) => classOption.definition.entityTypeId === ENTITY_TYPE_ID)
      .map((classOption) => classOption.definition)
  }

  // TODO - copied this code from feat parsing
  // LOL - choose-an-ability-score is in there now 2024
  loadAttributeModifiersIntoMaps(
    data: Dictionary,
    asiBonuses: NumberMap,
    attrOverrides: NumberMap,
    requiresGranted: boolean = false
  ) {
    const type = data.type
    const subType: string = data.subType
    if (subType.endsWith('-score')) {
      const subtype = data.subType
      const attrName = subtype.slice(0, -'-score'.length)
      const attrIndex = ABILITY_NAMES.indexOf(attrName)
      const value = parseInt(data.value)
      const fixedValue = parseInt(data.fixedValue)
      const isGranted: boolean = requiresGranted ? data.isGranted : true
      if (type === 'bonus' && isGranted) {
        const existingBonus = asiBonuses[attrIndex]
        if (existingBonus) {
          asiBonuses[attrIndex] = existingBonus + value
        } else {
          asiBonuses[attrIndex] = value
        }
      }
      if (type === 'set') {
        const existingBonus = attrOverrides[attrIndex]
        if (existingBonus) {
          attrOverrides[attrIndex] = Math.max(attrOverrides[attrIndex], fixedValue)
        } else {
          attrOverrides[attrIndex] = fixedValue
        }
      }
    }
  }

  loadAbilityScores(data: Dictionary, inventoryData: Dictionary[]): number[] {
    const abilityScores: number[] = []
    const asiBonuses: NumberMap = new NumberMap()
    const attrOverrides: NumberMap = new NumberMap()
    const baseAttributes = data.stats.map((item: Dictionary) => item.value)
    for (const classData of data.modifiers.class) {
      this.loadAttributeModifiersIntoMaps(classData, asiBonuses, attrOverrides)
    }

    for (const itemData of inventoryData) {
      const { equipped, isAttuned } = itemData

      if (equipped && isAttuned) {
        for (const modifier of itemData.definition.grantedModifiers) {
          this.loadAttributeModifiersIntoMaps(modifier, asiBonuses, attrOverrides)
        }
      }
    }

    for (const classData of data.modifiers.feat) {
      this.loadAttributeModifiersIntoMaps(classData, asiBonuses, attrOverrides)
    }

    const grantedFeats: Dictionary[] = data.background?.definition?.grantedFeats ?? []
    const hasNewBackground = grantedFeats.some((feat) => feat.name === 'Ability Scores')

    for (let index = 0; index < 6; index++) {
      const scoreId = index + 1
      const overrideStats = data.overrideStats
      const { bonusStats, modifiers } = data

      const base = baseAttributes[index]
      const racialStatMod = hasNewBackground ? 0 : this.getRacialStatMod(modifiers.race, scoreId)
      const bonus = this.getSubStats(bonusStats, scoreId, 0)
      const ddbOverride = this.getSubStats(overrideStats, scoreId, 0)
      const inventoryOverride = attrOverrides[index] || 0
      const override = Math.max(ddbOverride, inventoryOverride)

      let total = 0
      if (override > 0) {
        total = override
      } else {
        const asiBonus = asiBonuses[index] || 0
        total = base + bonus + racialStatMod + asiBonus
      }

      abilityScores.push(total)
    }

    return abilityScores
  }
}
