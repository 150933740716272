import { Dice, DiceCollection } from './Dice'
import { DamageData, AttackActionInterface, NumberMap, DictMap } from '../Common/Interfaces'
import { AttackType } from '../Common/Constants'
import { DiceCalc } from '../Dice/DiceCalc'
import { Dictionary } from '../Common/Types'
import { Activation } from './Activation'
import { CreatureType } from './Creature'
import { DictionaryCache } from '../Common/Cache'

// Hacky to put this here, but it's a temporary solution
const compoundStatsCache = new DictionaryCache()

export class TurnAction {
  attackAction?: AttackActionInterface
  attackNumber: number

  advantage: number = 0 // 1 = advantage, 2 = elven accuracy. TODO make this an enum?
  forgoAdvantage: boolean = false
  disadvantage: boolean = false
  critThreshold: number = 20
  spellSaveDCIncrease: number = 0

  id: number
  activation?: Activation = undefined
  minimumD20Roll: number = 0
  bonusToHitDice: Dice[] = []
  bonusDamageDiceCollection: DiceCollection = new DiceCollection()
  replacementDamageDice?: Dice
  singleEffectBonusDamageDiceCollection: DiceCollection = new DiceCollection()
  rerollDamageDiceThreshold: number = 0
  rerollAllDamageDiceOnHit: boolean = false
  rerollToHit: boolean = false
  maxDamage: boolean = false
  autoCrit: boolean = false
  autoHit: boolean = false
  minimumDieRoll: number = 0
  missForHalfDamage: boolean = false // Potent Cantrip, and later - weapon masteries
  saveForHalfDamage: boolean = false // Potent Cantrip

  damageMultiplier: number = 1
  critDiceDealMaxDamage: boolean = false

  damageVulnerability: boolean = false
  additionalCritDice: number = 0
  additionalDamageDice: number = 0
  damageData?: DamageData[]

  // This is a temporary chart hack
  asSpecifiedDamageData?: DamageData[]
  extendedNormalDamageData?: DamageData[]
  extendedAdvantageDamageData?: DamageData[]
  extendedDisadvantageDamageData?: DamageData[]
  extendedDamageDataACs?: number[]

  constructor(attackNumber: number, action?: AttackActionInterface) {
    if (action) {
      this.attackAction = action
      this.id = action.attributes.id + 100 * attackNumber
    } else {
      this.id = 1000000000 + attackNumber
    }
    this.attackNumber = attackNumber
  }

  advantageType(): number {
    if (this.attackAction === undefined) {
      return 0
    }

    // if (this.attackAction.saveDCString.length > 0) {
    //   return 0
    // }

    if (this.advantage) {
      return this.advantage
    }

    if (this.disadvantage) {
      return -1
    }

    // TODO later we can invalidate advantage if we have disadvantage, but make sure we do that in the damage calculations as well
    return 0
  }

  attackAttributes(): Dictionary {
    if (this.attackAction?.attributes) {
      return this.attackAction?.attributes
    }
    return {}
  }

  isThrownWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isThrown
  }

  isDamagelessWeapon(): boolean {
    return this.isWeapon() && this.attackAttributes().dealsDamage !== undefined && !this.attackAttributes().dealsDamage
  }

  isRangedWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isRanged
  }

  isBow(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isBow
  }

  isHorns(): boolean {
    return this.attackAttributes().isHorns
  }

  isFinesseWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isFinesse
  }

  isLightWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isLight
  }

  isHeavyWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isHeavy
  }

  isEvocationSpell(): boolean {
    return this.attackAttributes().school === 'Evocation'
  }

  isSpell(): boolean {
    const type = this.attackAttributes().type
    return type === AttackType.SPELL || type === AttackType.SPELL_ATTACK
  }

  isSingleTargetSpell(): boolean {
    if (this.name() === 'Acid Splash') {
      // It's not AOE, but it's not single target either. TODO: Figure out how to detect this from the json.
      return false
    }
    return this.attackAttributes().range.aoeType === null
  }

  isTollTheDead(): boolean {
    return this.attackAttributes().name === 'Toll the Dead'
  }

  isLightningDamage(): boolean {
    return this.isDamageType('lightning')
  }

  isRadiantDamage(): boolean {
    return this.isDamageType('radiant')
  }

  isPiercingDamage(): boolean {
    return this.isDamageType('Piercing')
  }
  isFireDamage(): boolean {
    return this.isDamageType('fire')
  }

  isThunderDamage(): boolean {
    return this.isDamageType('thunder')
  }

  isDamageType(type: string): boolean {
    return this.attackAttributes().damageTypes?.includes(type)
  }

  isPactWeapon(): boolean {
    return this.attackAttributes().isPactWeapon
  }

  isEldritchBlast(): boolean {
    return this.attackAttributes().name === 'Eldritch Blast'
  }

  isVersatileWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isVersatile
  }

  isTwoHandedWeapon(): boolean {
    if (!this.isWeapon()) {
      return false
    }

    return this.attackAttributes().isTwoHanded
  }

  isOffHand(): boolean {
    if (!this.isWeapon()) {
      return false
    }
    return this.attackAttributes().isOffHand
  }

  isMeleeAttack(): boolean {
    return this.isUnarmed() || this.isMeleeWeapon()
  }

  isMeleeWeapon(): boolean {
    if (this.isWildShapeAttack()) {
      return true
    }

    if (!this.isWeapon()) {
      return false
    }

    return !this.isRangedWeapon() && !this.isUnarmed()
  }

  isAttackRoll(): boolean {
    return this.attackAttributes().requiresAttackRoll
  }

  isSpellAttack(): boolean {
    return this.isSpell() && this.attackAttributes().requiresAttackRoll
  }

  spellLevel(): number {
    if (this.isSpell()) {
      return this.attackAttributes().level
    }
    return 0
  }

  isCantrip(): boolean {
    return this.isSpell() && this.attackAttributes().level === 0
  }

  isUnarmed(): boolean {
    return this.attackAttributes().subType === 'unarmed'
  }

  isFlurryOfBlows(): boolean {
    return this.attackAttributes().flurryOfBlows === true
  }

  isRadiantSunBolt(): boolean {
    return this.attackAttributes().radiantSunBolt === true
  }

  isPsychicBlades(): boolean {
    return this.attackAttributes().psychicBlades === true
  }

  isAstralArms(): boolean {
    return this.attackAttributes().astralArms === true
  }

  isWeapon(): boolean {
    const attributes = this.attributes()

    if (attributes.type && attributes.type === AttackType.COMPANION) {
      return false
    }

    if (attributes.type && attributes.type === AttackType.WEAPON) {
      return true
    }

    if (attributes.range && attributes.range.origin === AttackType.WEAPON) {
      return true
    }

    return false
  }

  isWildShapeAttack(): boolean {
    if (this.attackAction === undefined) {
      return false
    }
    const attributes = this.attributes()
    return attributes && attributes.creatureType === CreatureType.WildShape
  }

  isCompanion(): boolean {
    const attributes = this.attributes()
    return (
      attributes.type && attributes.type === AttackType.COMPANION && attributes.creatureType !== CreatureType.WildShape
    )
  }

  name(): string {
    if (!this.attackAction) {
      return ''
    }

    return this.attackAction.name
  }

  attackRollToHitDiceCollection(): DiceCollection {
    if (!this.attackAction) {
      return new DiceCollection()
    }
    const baseDie: Dice = Dice.flatAmountDie(this.attackAction.attackMod)
    if (this.attackAction.attributes.requiresAttackRoll) {
      const diceArray: Dice[] = [baseDie, ...this.bonusToHitDice]
      return new DiceCollection().addDiceList(diceArray)
    }

    return new DiceCollection().addDice(baseDie)
  }

  hasCompoundToHitDice(): boolean {
    if (this.isDamagelessWeapon()) return false
    const hasDiceCountGreaterThanZero = this.bonusToHitDice.some((dice) => dice.diceCount > 0)
    return this.attackAction && this.attackAction.attributes.requiresAttackRoll && hasDiceCountGreaterThanZero
  }

  baseToHitString(): string {
    if (!this.attackAction) {
      return ''
    }

    return this.attackAction.attackModString(this.spellSaveDCIncrease)
  }

  consolidatedToHitString(): string {
    if (!this.attackAction) {
      return ''
    }

    if (this.attackAction.attributes.requiresAttackRoll) {
      const diceCollection = this.attackRollToHitDiceCollection()
      return diceCollection.displayString(0, true)
    }

    return this.attackAction.attackModString(this.spellSaveDCIncrease)
  }

  baseDiceCollectionForLevel(level: number): DiceCollection | null {
    if (this.isDamagelessWeapon()) return null
    if (level <= 0 || !this.attackAction) {
      return null
    }

    const levelMap = this.attributes().diceCollectionsForLevels
    if (!levelMap || levelMap.length === 0) {
      return null
    }

    return levelMap[level - 1]
  }

  baseDiceCollection(level: number): DiceCollection {
    let leveledDice = this.baseDiceCollectionForLevel(level)

    if (leveledDice) {
      if (this.additionalDamageDice > 0) {
        const diceKeys = Object.keys(leveledDice.dice)
        if (diceKeys.length === 1) {
          const key = parseInt(diceKeys[0])
          const value = leveledDice.dice[key] + this.additionalDamageDice
          leveledDice = new DiceCollection().addDice(Dice.Create(value, key, leveledDice.modifier))
        } else {
          console.warn('Multiple dice in baseDiceCollectionForLevel?', leveledDice)
        }
      }

      return leveledDice
    }

    if (this.isDamagelessWeapon()) return new DiceCollection()
    let effectCount = this.effectCount()
    if (level > 1) {
      const effectCountsForLevels = this.attributes().effectCountsForLevels
      if (effectCountsForLevels && effectCountsForLevels.length > 0) {
        effectCount = effectCountsForLevels[level - 1]
      }
    }

    const dice = this.replacementDamageDice ? this.replacementDamageDice.copy() : this.attackAction!.dice.copy() // attackAction should always exist at this point (famous last words), even if there is no level (weapon, cantrip, etc)

    if (effectCount > 1) {
      dice.diceCount *= effectCount
      dice.fixedValue *= effectCount
    }

    if (this.additionalDamageDice > 0) {
      dice.diceCount += this.additionalDamageDice
    }

    if (this.attributes().bonusDamageDice) {
      return this.attributes().bonusDamageDice.copy().addDice(dice)
    }

    return new DiceCollection().addDice(dice)
  }

  effectCount(): number {
    return this.attributes().effectCount || 1
  }

  allDamageDiceCollection(level: number): DiceCollection {
    if (!this.attackAction || this.isDamagelessWeapon()) {
      return new DiceCollection()
    }

    const baseDiceCollection = this.baseDiceCollection(level)
    let bonusDiceCollection = this.bonusDamageDiceCollection.copy()
    const effectCount = this.effectCount()
    if (effectCount > 1) {
      bonusDiceCollection = bonusDiceCollection.multiplyDice(effectCount, effectCount)
    }

    const singleEffectBonusDiceCollection = this.singleEffectBonusDamageDiceCollection.copy() // copy redundant?
    const allDamageDice = baseDiceCollection
      .copy()
      .addDiceCollection(bonusDiceCollection)
      .addDiceCollection(singleEffectBonusDiceCollection)

    if (this.damageMultiplier > 1) {
      allDamageDice.multiplyDice(this.damageMultiplier, this.damageMultiplier)
    }

    if (this.damageVulnerability) {
      return allDamageDice.multiplyDice(2, 2)
    }

    return allDamageDice
  }

  consolidatedDamageStringForLevel(level: number, forDisplay: boolean = false): string {
    if (this.isDamagelessWeapon()) return '0'
    if (this.attackAction === undefined) {
      return ''
    }
    const rerollThreshold = this.rerollDamageDiceThreshold
    const rerollAllDice = this.rerollAllDamageDiceOnHit
    const minDieRoll = this.minimumDieRoll

    if (!forDisplay && (rerollThreshold > 0 || rerollAllDice || minDieRoll > 0)) {
      const baseDiceCollection = this.baseDiceCollection(level).copy()
      const bonusDiceCollection = this.bonusDamageDiceCollection.copy()
      // TODO - not using effectCount here as a multiplier for bonus damage dice, should we? See other use of multiplyDiceCollectionAndModifier.

      if (this.damageMultiplier > 1) {
        baseDiceCollection.multiplyDice(this.damageMultiplier, this.damageMultiplier)
        bonusDiceCollection.multiplyDice(this.damageMultiplier, this.damageMultiplier)
      }
      // Manually consolidate the modifier, as we've split apart the base dice and bonus dice
      bonusDiceCollection.modifier += baseDiceCollection.modifier
      baseDiceCollection.modifier = 0

      const baseDiceString = baseDiceCollection.displayString(rerollThreshold, false, minDieRoll)
      const bonusDiceString = bonusDiceCollection.displayString() // TODO minimumDieRoll for bonus dice… for things like green-flame blade?
      const appendBonusString = bonusDiceString.length > 0 ? ` + ${bonusDiceString}` : ''
      let total = `${baseDiceString}${appendBonusString}`

      // TODO… later we need reroll some dice (GWF) and reroll all dice (Savage Attacker) for bonus dice too, gotta do this in the crit logic as well

      if (rerollAllDice) {
        total = `2kh1(${baseDiceString})${appendBonusString}`
      }

      return total.replace(/\+\s*\+/g, '+').replace(/\s+/g, ' ')
    }

    let diceCollection = this.allDamageDiceCollection(level)

    if (this.maxDamage) {
      diceCollection = diceCollection.maxDice()
    }

    return diceCollection.displayString()
  }

  critDiceCollectionForLevel(level: number): DiceCollection {
    if (this.isDamagelessWeapon()) return new DiceCollection()

    // TODO: This doesn't double GWF or Flames of Phlegethos rerolls, but it should
    const diceCollection = this.allDamageDiceCollection(level)
    if (!this.isAttackRoll()) {
      // ASSUMPTION: Only attack rolls double crit dice
      return diceCollection
    }

    let critDiceCollection = diceCollection.copy().multiplyDice(2)
    if (this.critDiceDealMaxDamage) {
      const collection = diceCollection.copy()
      collection.modifier = 0
      const maxDamageDiceCollection = collection.maxDice()
      critDiceCollection = diceCollection.addDiceCollection(maxDamageDiceCollection)
    }

    if (this.attributes().critDamageDice) {
      critDiceCollection.addDice(this.attributes().critDamageDice)
    }

    if (this.additionalCritDice > 0) {
      const weaponDieSize = this.attackAction!.dice.diceValue
      const additionalCritDice = Dice.Create(this.additionalCritDice, weaponDieSize)
      critDiceCollection.addDice(additionalCritDice)
    }

    return critDiceCollection
  }

  diceString(): string {
    if (!this.attackAction) {
      return ''
    }

    const diceCollection = this.bonusDamageDiceCollection.copy()
    return diceCollection.addDice(this.attackAction.dice).displayString()
  }

  attributes() {
    if (!this.attackAction) {
      return {}
    }

    return this.attackAction.attributes
  }

  diceModStringForAC(ac: number) {
    return this.diceModString(ac, this.advantage, this.disadvantage)
  }

  asSpecifiedDamageDataForACs(acs: number[]): DamageData[] {
    if (!this.asSpecifiedDamageData || !this.extendedDamageDataACs) {
      return []
    }

    const firstAC = this.extendedDamageDataACs[0]
    return acs.map((ac) => this.asSpecifiedDamageData![ac - firstAC])
  }

  calculateAverageDamageArray(
    acList: number[],
    advantageOverride?: number,
    disadvantageOverride?: boolean
  ): DamageData[] {
    if (!this.attackAction) {
      return []
    }

    const damageArray: DamageData[] = []

    let advantage: number = advantageOverride !== undefined ? advantageOverride : this.advantage
    const disadvantage = disadvantageOverride !== undefined ? disadvantageOverride : this.disadvantage

    // disable elven if this is a companion
    if (advantage === 2 && this.isCompanion()) {
      advantage = 1
    }

    let lastValue: Dictionary | undefined = undefined
    const lastToHitEvaluation: DictMap = {} // only for compound hit dice
    for (const armorClass of acList) {
      const diceString = this.diceModString(armorClass, disadvantage ? 0 : advantage, disadvantage)
      // TODO don't store and cache the compound +1d4 stuff in the normal cache
      let evaluation = undefined

      if (!this.hasCompoundToHitDice()) {
        evaluation = DiceCalc.evaluateStatistics(diceString)
        // TODO - later for half damage math
        // if (evaluation) {
        //   console.log(`ac: ${armorClass}, diceString: ${diceString}, miss: ${evaluation.percentiles[0]}`)
        //   console.log(evaluation.percentiles)
        // }
        // this is where basic cantrip stuff goes.
      } else if (compoundStatsCache.has(diceString)) {
        evaluation = compoundStatsCache.get(diceString)
      } else {
        const diceCollection = this.attackRollToHitDiceCollection()
        const freqMap = diceCollection.diceSumFrequency()

        let averageSum: number = 0
        const percentilesSum: NumberMap = new NumberMap()
        let freqTotal = 0

        for (const [attackMod, frequency] of Object.entries(freqMap)) {
          const compoundStatDiceString = this.diceModString(
            armorClass,
            disadvantage ? 0 : advantage,
            disadvantage,
            attackMod
          )

          let evaluation = DiceCalc.evaluateStatistics(compoundStatDiceString)
          if (evaluation?.percentiles[0] === 1) {
            evaluation = lastToHitEvaluation[armorClass - Number(attackMod) - 1]
            lastToHitEvaluation[armorClass - Number(attackMod)] = evaluation
          } else {
            lastToHitEvaluation[armorClass - Number(attackMod)] = evaluation!
          }

          const percentiles = evaluation?.percentiles
          const average = evaluation?.average
          averageSum += average * frequency
          freqTotal += frequency
          for (const [percentile, value] of Object.entries(percentiles)) {
            const p = Number(percentile)
            const v = Number(value)
            if (percentilesSum[p] !== undefined) {
              percentilesSum[p] += v * frequency
            } else {
              percentilesSum[p] = v * frequency
            }
          }
        }

        const newAverage = averageSum / freqTotal
        for (const [percentile] of Object.entries(percentilesSum)) {
          percentilesSum[Number(percentile)] /= freqTotal
        }

        evaluation = {
          average: newAverage,
          total: 0,
          expression: diceString,
          percentiles: percentilesSum
        }

        compoundStatsCache.set(diceString, evaluation)
      }

      if (evaluation === undefined) {
        console.error(`Failed to evaluate dice string [${diceString}]`)
      } else if (lastValue && evaluation.average === 0) {
        damageArray.push(lastValue as DamageData)
        // Warning: This is a hack because dice.clockworkmod.com doesn't consider a nat 20 a hit if the AC is impossible to hit, but this seems to do the trick
      } else {
        damageArray.push(evaluation as DamageData)
        lastValue = evaluation
      }
    }

    return damageArray
  }

  diceModString(
    ac: number,
    advantage: number = 0,
    disadvantage: boolean = false,
    toHitOverride: string | null = null
  ): string {
    if (!this.attackAction) {
      return ''
    }

    if (advantage > 0 && disadvantage) {
      advantage = 0
      disadvantage = false
      console.warn('Cannot have advantage and disadvantage at the same time')
    }
    const baseDamageDiceString = this.consolidatedDamageStringForLevel(this.attackAction.turnLevel)
    const attrs = this.attributes()

    let d20String = this.rerollToHit ? 'hd20' : 'd20'
    if (this.minimumD20Roll > 0) {
      d20String = `${this.minimumD20Roll}>${d20String}`
    }

    if (attrs.requiresAttackRoll) {
      const toHit = this.autoHit
        ? '+ 30'
        : toHitOverride !== null
          ? new DiceCollection().addDice(Dice.flatAmountDie(parseInt(toHitOverride))).displayString(0, true)
          : this.consolidatedToHitString()

      let string = `(${d20String}`

      if (advantage > 0) {
        for (let i = 0; i < advantage; i++) {
          string += ` > ${d20String}`
        }
      } else if (disadvantage) {
        string += ` < ${d20String}`
      }

      const level = this.attackAction.turnLevel
      const critDice = this.critDiceCollectionForLevel(level)

      const rerollThreshold = this.rerollDamageDiceThreshold
      const minDieRoll = this.minimumDieRoll
      const rerollAllDice = this.rerollAllDamageDiceOnHit

      let critString = critDice.displayString(rerollThreshold, false, minDieRoll)
      if (rerollAllDice) {
        critString = `2kh1(${critString})`
      }

      if (this.autoCrit) {
        string += ' ' + toHit + ' AC ' + ac + ') * (' + critString + ')'
      } else {
        string += ' ' + toHit + ' AC ' + ac + ') * (' + baseDamageDiceString + ')'

        if (this.critThreshold < 20) {
          const extra = 21 - this.critThreshold
          string += ' xcrit' + extra + ' (' + critString + ')'
        } else {
          string += ' crit (' + critString + ')'
        }
      }

      return string
    }

    if (attrs.requiresSavingThrow) {
      // TODO - need to handle stuff like Bane
      const saveBonus = ac - 10 // hack for now
      const saveBonusString = saveBonus >= 0 ? `+ ${saveBonus}` : saveBonus
      const saveDC = attrs.saveDcValue + this.spellSaveDCIncrease
      const isCantrip = attrs.isCantrip
      const saveString = isCantrip && !this.saveForHalfDamage ? '' : 'save half' // TODO - Assumption for now. Assuming all spells that require saving throw have a "save half"
      return `(${d20String} ${disadvantage ? `< ${d20String} ` : ''}${advantage ? `> ${d20String} ` : ''} ${saveBonusString} DC ${saveDC}) * (${baseDamageDiceString}) ${saveString}`
    }

    return baseDamageDiceString
  }
}
