import { Dice, DiceCollection } from './Dice'
import { Feature } from './Feature'
import { TurnAction } from './TurnAction'
import { Spell } from './Spell'
import { Utility } from '../Common/Utility'
import { Activation } from './Activation'
import { CharacterInfo } from './CharacterInfo'

export class TurnActionEngine {
  applyFeaturesToTurnActions(features: Feature[], turnActions: TurnAction[], characterInfo: CharacterInfo) {
    if (turnActions.length === 0) {
      return
    }

    const scores = characterInfo.abilityScores

    // Handle the things that affect multiple turns up-front
    const elvenAccuracy = features.some((thisFeature) => thisFeature.rerollOnAdvantage)
    const advantageValue = elvenAccuracy ? 2 : 1
    this.applyAdvantageToNextAttacks(features, turnActions, advantageValue)
    this.applyPsyshicBladesVex(features, turnActions, advantageValue) // TODO - initial implementation of VEX… but just for psychic blades
    this.applyPsychicBladeRendMind(features, turnActions, advantageValue)

    // Modify sneak attack feaure based on other active features
    this.modifySneakAttack(features, turnActions, advantageValue)

    // Assassinate is a sneak attack only… but also provides advantage on all attacks
    const sneakAttackForcedAdvantage = features
      .filter((feature) => feature.sneakAttackOnly)
      .some((saFeature) => saFeature.advantage)

    if (sneakAttackForcedAdvantage) {
      for (const turn of turnActions) {
        if (turn.isAttackRoll()) {
          turn.advantage = turn.advantage = turn.isCompanion() ? 1 : advantageValue
        }
      }
    }

    // Set up conditional feaatures
    this.configureBoomingBlade(features)

    for (const feature of features) {
      const {
        oncePerTurn,
        cantripsOrWeaponsOnly,
        cantripOnly,
        potentCantrip,
        rangedWeaponOnly,
        unarmedOnly,
        flurryOfBlowsOnly,
        radiantSunBoltOnly,
        astralArmsOnly,
        finessOrRangedeWeaponOnly,
        bowOnly,
        thrownWeaponOnly,
        meleeWeaponOnly,
        meleeAttackOnly,
        heavyWeaponOnly,
        finesseWeaponOnly,
        hornsOnly,
        twoHandedOrVersatileOnly,
        singleWieldingOnly,
        offHandOnly,
        weaponOnly,
        additionalMeleeRange,
        alsoAppliesToCompanionAttacks,
        onlyAppliesToCompanionAttacks,
        spellOnly,
        evocationSpellsOnly,
        fireDamageOnly,
        lightningOrThunderDamageOnly,
        singleTargetSpellOnly,
        tollTheDeadOnly,
        spellAttackOnly,
        attackRollOnly,
        pactWeaponOnly,
        eldritchBlastOnly,
        fireOrRadiantDamageOnly,
        wildshapeAttackOnly,
        piercingDamageOnly,
        elementalAdeptDamageType,
        psychicBladeOnly,
        recklessAttackOnly,
        lightWeaponOnly,
        ragingOnly,
        sneakAttackOnly,
        cunningStrikePoisonOnly
      } = feature

      const addFeature = (featureCode: (turn: TurnAction) => void) => {
        for (const turn of turnActions) {
          if (sneakAttackOnly || cunningStrikePoisonOnly) continue // This gets handled above where we modify the sneak attack object
          if (!alsoAppliesToCompanionAttacks && !onlyAppliesToCompanionAttacks && turn.isCompanion()) continue
          if (weaponOnly && !turn.isWeapon()) continue
          if (unarmedOnly && !turn.isUnarmed()) continue
          if (bowOnly && !turn.isBow()) continue
          if (finessOrRangedeWeaponOnly && !(turn.isFinesseWeapon() || turn.isRangedWeapon())) continue
          if (rangedWeaponOnly && !turn.isRangedWeapon()) continue
          if (meleeWeaponOnly && !turn.isMeleeWeapon()) continue
          if (lightWeaponOnly && !turn.isLightWeapon()) continue
          if (meleeAttackOnly && !turn.isMeleeAttack()) continue
          if (cantripsOrWeaponsOnly && !(turn.isWeapon() || turn.isCantrip())) continue
          if (cantripOnly && !turn.isCantrip()) continue
          if (potentCantrip && !turn.isCantrip()) continue
          if (feature.maxSpellLevel > 0 && (turn.isCantrip() || turn.spellLevel() > feature.maxSpellLevel)) continue
          if (finesseWeaponOnly && !turn.isFinesseWeapon()) continue
          if (hornsOnly && !turn.isHorns()) continue
          if (heavyWeaponOnly && !turn.isHeavyWeapon()) continue
          if (spellAttackOnly && !turn.isSpellAttack()) continue
          if (attackRollOnly && !turn.isAttackRoll()) continue
          if (twoHandedOrVersatileOnly && !(turn.isVersatileWeapon() || turn.isTwoHandedWeapon())) continue
          if (singleWieldingOnly && (turn.isTwoHandedWeapon() || turnActions.some((t) => t.isOffHand()))) continue
          if (offHandOnly && !turn.attackAttributes().isOffHand) continue
          if (spellOnly && !turn.isSpell()) continue
          if (evocationSpellsOnly && !turn.isEvocationSpell()) continue
          if (fireDamageOnly && !turn.isFireDamage()) continue
          if (fireOrRadiantDamageOnly && !(turn.isFireDamage() || turn.isRadiantDamage())) continue
          if (piercingDamageOnly && !turn.isPiercingDamage()) continue
          if (lightningOrThunderDamageOnly && !(turn.isThunderDamage() || turn.isLightningDamage())) continue
          if (pactWeaponOnly && !turn.isPactWeapon()) continue
          if (eldritchBlastOnly && !turn.isEldritchBlast()) continue
          if (singleTargetSpellOnly && !turn.isSingleTargetSpell()) continue
          if (tollTheDeadOnly && !turn.isTollTheDead()) continue
          if (thrownWeaponOnly && !turn.isThrownWeapon()) continue
          if (additionalMeleeRange && !turn.isMeleeWeapon()) continue
          if (onlyAppliesToCompanionAttacks && !turn.isCompanion()) continue
          if (flurryOfBlowsOnly && !turn.isFlurryOfBlows()) continue
          if (radiantSunBoltOnly && !turn.isRadiantSunBolt()) continue
          if (psychicBladeOnly && !turn.isPsychicBlades()) continue
          if (astralArmsOnly && !turn.isAstralArms()) continue
          if (wildshapeAttackOnly && !turn.isWildShapeAttack()) continue
          if (elementalAdeptDamageType && !turn.isDamageType(elementalAdeptDamageType)) continue
          if (recklessAttackOnly && !features.some((feature) => feature.name === 'Reckless Attack')) continue
          if (ragingOnly && !features.some((feature) => feature.raging)) continue

          featureCode(turn)

          if (oncePerTurn) break
        }
      }

      if (feature.nextAttacksHaveAdvantage) {
        for (let i = 1; i < turnActions.length; i++) {
          const turn = turnActions[i]
          if (turn.isAttackRoll()) {
            turn.advantage = elvenAccuracy ? 2 : 1
          }
        }
      }

      if (feature.damageVulnerability) {
        addFeature((turn) => (turn.damageVulnerability = feature.damageVulnerability))
      }

      if (feature.additionalToHitDice) {
        addFeature((turn) => turn.bonusToHitDice.push(feature.additionalToHitDice!))
      }

      if (feature.critDiceDealMaxDamage) {
        addFeature((turn) => (turn.critDiceDealMaxDamage = feature.critDiceDealMaxDamage))
      }

      if (feature.isTrueStrike) {
        const spellCastingModifier = characterInfo.spellcastingAbilityModifier
        addFeature(function addWeaponStatModifier(turn: TurnAction): void {
          const attrs = turn.attackAttributes()
          const abilityIndex = attrs.attackStatIndex

          const score = scores[abilityIndex]
          const statModifier = Utility.modifierForScore(score)

          if (abilityIndex !== undefined) {
            turn.bonusDamageDiceCollection.modifier -= statModifier
            turn.bonusDamageDiceCollection.modifier += spellCastingModifier

            turn.bonusToHitDice.push(Dice.flatAmountDie(-statModifier))
            turn.bonusToHitDice.push(Dice.flatAmountDie(spellCastingModifier))
          } else {
            console.error('No attack stat index found for weapon')
          }
        })
      }

      if (feature.additionalDamageOnHitDice) {
        const diceCollection = new DiceCollection().addDice(feature.additionalDamageOnHitDice)

        if (feature.targetMoved && feature.additionalDamageOnMoveDice) {
          diceCollection.addDice(feature.additionalDamageOnMoveDice)
        }

        if (feature.onlyOneBeam) {
          addFeature((turn) => turn.singleEffectBonusDamageDiceCollection.addDiceCollection(diceCollection))
        } else {
          addFeature((turn) => turn.bonusDamageDiceCollection.addDiceCollection(diceCollection))
        }
      }

      if (feature.extraAttackFirstRoundDice) {
        const validTurnActions = [
          ...turnActions.filter(
            (action) => action.attackAction !== undefined && action.attackAction.activation.usesAction()
          )
        ]

        // TODO if it's this.extraAttackThisAction (dread ambusher, etc AND we have action surge), apply it twice
        if (validTurnActions.length > 0) {
          const lastTurnAttack = validTurnActions[validTurnActions.length - 1]
          lastTurnAttack.bonusDamageDiceCollection.addDice(feature.extraAttackFirstRoundDice)
        }
      }

      if (feature.forgoAdvantageNextAttack) {
        const firstTurnAttack = turnActions[0]
        firstTurnAttack.forgoAdvantage = true // for future features that may try to turn on advantage

        if (firstTurnAttack.advantage > 0) {
          firstTurnAttack.advantage = 0
        }
      }

      if (feature.additionalDamageDiceOnCrit > 0) {
        addFeature((turn) => (turn.additionalCritDice += feature.additionalDamageDiceOnCrit))
      }

      if (feature.addWeaponStatModifier) {
        addFeature(function addWeaponStatModifier(turn: TurnAction): void {
          const abilityIndex = turn.attackAttributes().attackStatIndex

          const score = scores[abilityIndex]
          const statModifier = Utility.modifierForScore(score)
          if (abilityIndex !== undefined) {
            turn.bonusDamageDiceCollection.modifier += statModifier
          } else {
            console.error('No attack stat index found for weapon')
          }
        })
      }

      if (feature.additionalDamageDiceOnHit > 0) {
        addFeature((turn) => (turn.additionalDamageDice += feature.additionalDamageDiceOnHit))
      }

      if (feature.rerollDamageDiceThreshold > 0) {
        addFeature((turn) => (turn.rerollDamageDiceThreshold = feature.rerollDamageDiceThreshold))
      }

      if (feature.minimumDamageDieRoll > 0) {
        addFeature((turn) => (turn.minimumDieRoll = feature.minimumDamageDieRoll))
      }

      if (feature.rerollToHit) {
        addFeature((turn) => (turn.rerollToHit = feature.rerollToHit))
      }

      if (feature.rerollAllDamageDiceOnHit) {
        addFeature((turn) => (turn.rerollAllDamageDiceOnHit = feature.rerollAllDamageDiceOnHit))
      }

      if (feature.maxDamage) {
        addFeature((turn) => (turn.maxDamage = feature.maxDamage))
      }

      if (feature.disadvantage) {
        addFeature((turn) => (turn.disadvantage = feature.disadvantage))
      }

      if (feature.damageMultiplier > 1) {
        addFeature((turn) => (turn.damageMultiplier = feature.damageMultiplier))
      }

      if (feature.convertActionToBonusAction) {
        addFeature((turn) => (turn.activation = Activation.BonusAction()))
      }

      if (feature.minimumD20Roll) {
        addFeature((turn) => (turn.minimumD20Roll = feature.minimumD20Roll))
      }

      if (feature.additionalEffectCount > 0) {
        addFeature(function addEffectCount(turn: TurnAction): void {
          const attrs = turn.attributes()
          attrs.effectCount += feature.additionalEffectCount
        })
      }

      //   if (feature.additionalMeleeRange > 0) {
      //     addFeature(function addMeleeRange(turn: TurnAction): void {
      //       // TODO - this mutates the original attributes, which stacks. It also applies twic?
      //       //   const attrs = turn.attributes()
      //       //   attrs.range.range = attrs.range.range + feature.additionalMeleeRange
      //       //   attrs.range.longRange = attrs.range.longRange + feature.additionalMeleeRange
      //     })
      //   }

      if (feature.advantage) {
        addFeature(function setAdvantage(turn: TurnAction): void {
          if (turn instanceof Spell) {
            // TODO - is this ever possible?
            return
          }

          if (!turn.forgoAdvantage) {
            turn.advantage = turn.isCompanion() ? 1 : advantageValue
          }
        })
      }

      if (feature.expandedCrit) {
        addFeature((turn) => (turn.critThreshold -= 1))
      }

      if (feature.replacementDamageDice) {
        addFeature((turn) => (turn.replacementDamageDice = feature.replacementDamageDice!.copy()))
      }

      if (feature.autoCrit) {
        addFeature((turn) => (turn.autoCrit = feature.autoCrit))
      }

      if (feature.autoHit) {
        addFeature((turn) => (turn.autoHit = feature.autoHit))
      }

      if (feature.potentCantrip) {
        addFeature((turn) => (turn.missForHalfDamage = feature.potentCantrip))
        addFeature((turn) => (turn.saveForHalfDamage = feature.potentCantrip))
      }

      if (feature.spellSaveDCIncrease > 0) {
        addFeature((turn) => (turn.spellSaveDCIncrease = feature.spellSaveDCIncrease))
      }
    }
  }

  applyAdvantageToNextAttacks(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    // This is a tricky case - if we have grappler AND the first attack is unarmed, then we apply advantage on subsequent melee attacks
    const nextAttacksHaveAdvantage: Feature | undefined = features.find((feature) => feature.nextAttacksHaveAdvantage)
    if (nextAttacksHaveAdvantage) {
      if (nextAttacksHaveAdvantage.psychicBladeOnly === false) {
        const grappler = features.find((feature) => feature.id === 1789147)
        if (grappler) {
          let foundUnarmed = false

          for (let i = 0; i < turnActions.length; i++) {
            if (!foundUnarmed && turnActions[i].isUnarmed()) {
              foundUnarmed = true
              continue
            }

            if (foundUnarmed) {
              const turn = turnActions[i]
              if (turn.isMeleeAttack()) {
                turn.advantage = advantage
              }
            }
          }
        }
      } else {
        // for (let i = 0; i < turnActions.length - 1; i++) {
        //   if (turnActions[i].isPsychicBlades()) {
        //     const nextTurn = turnActions[i + 1]
        //     nextTurn.advantage = elvenAccuracy ? 2 : 1
        //   }
        // }
      }
    }
  }

  applyPsyshicBladesVex(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    if (features.some((feature) => feature.nextPsychicBladeAttackHasAdvantage)) {
      for (let i = 0; i < turnActions.length - 1; i++) {
        if (turnActions[i].isPsychicBlades()) {
          const nextTurn = turnActions[i + 1]
          nextTurn.advantage = advantage
        }
      }
    }
  }

  applyPsychicBladeRendMind(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    if (features.some((feature) => feature.nextPsychicBladeAttacksHaveAdvantage)) {
      let foundPsychicBlades = false
      for (const turn of turnActions) {
        if (turn.isPsychicBlades()) {
          foundPsychicBlades = true
          continue
        }

        if (foundPsychicBlades) {
          turn.advantage = advantage
        }
      }
    }
  }

  modifySneakAttack(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    // TODO - change these to be constant numbers
    const sneakAttackFeature = features.find((feature) => feature.name === 'Sneak Attack')
    const cunningStrikePoisonFeature = features.find((feature) => feature.name === 'Cunning Strike: Poison (Cost: 1d6)')

    if (cunningStrikePoisonFeature !== undefined) {
      cunningStrikePoisonFeature.additionalDamageOnHitDice = cunningStrikePoisonFeature.cunningStrikeDice?.copy() // Reset it to the original value
      const cunningStrikePoisonOnlyFeatures: Feature[] = features.filter((feature) => feature.cunningStrikePoisonOnly)
      for (const cunningStrikeModFeature of cunningStrikePoisonOnlyFeatures) {
        // TODO this is duplicated with sneak attack below
        if (cunningStrikeModFeature.additionalDamageOnHitDice) {
          if (cunningStrikePoisonFeature.additionalDamageOnHitDice) {
            cunningStrikePoisonFeature.additionalDamageOnHitDice.diceCount +=
              cunningStrikeModFeature.additionalDamageOnHitDice.diceCount
            cunningStrikePoisonFeature.additionalDamageOnHitDice.fixedValue +=
              cunningStrikeModFeature.additionalDamageOnHitDice.fixedValue
          } else {
            cunningStrikePoisonFeature.additionalDamageOnHitDice = cunningStrikeModFeature.additionalDamageOnHitDice
          }
        }
      }
    }

    if (sneakAttackFeature !== undefined) {
      sneakAttackFeature.damageMultiplier = 1 // Reset this in case it was set on a previous run

      const sneakAttackModFeatures: Feature[] = features.filter((feature) => feature.sneakAttackOnly)
      // This approach handles if we have multiple cunning/devious stikes active at once, and properly resets the Sneak Attack feat when they are toggled off
      sneakAttackFeature.nextAttacksHaveAdvantage = sneakAttackModFeatures.some(
        (feature) => feature.nextAttacksHaveAdvantage
      )

      sneakAttackFeature.additionalDamageOnHitDice = sneakAttackFeature.sneakAttackDice?.copy() // Reset it to the original value
      sneakAttackFeature.attackAfterSneakAttackAutoCrits = sneakAttackModFeatures.some(
        (feature) => feature.attackAfterSneakAttackAutoCrits
      )

      sneakAttackFeature.attackAfterSneakAttackHasAdvantage = sneakAttackModFeatures.some(
        (feature) => feature.attackAfterSneakAttackHasAdvantage
      )

      for (const sneakAttackModFeature of sneakAttackModFeatures) {
        if (sneakAttackModFeature.additionalDamageOnHitDice && sneakAttackFeature.additionalDamageOnHitDice) {
          sneakAttackFeature.additionalDamageOnHitDice.diceCount +=
            sneakAttackModFeature.additionalDamageOnHitDice.diceCount

          sneakAttackFeature.additionalDamageOnHitDice.fixedValue +=
            sneakAttackModFeature.additionalDamageOnHitDice.fixedValue

          if (sneakAttackFeature.additionalDamageOnHitDice.diceCount <= 0) {
            // Don't let it go negative and crash
            sneakAttackFeature.additionalDamageOnHitDice = undefined
          }
        }

        if (sneakAttackModFeature.damageMultiplier !== 1) {
          sneakAttackFeature.damageMultiplier = sneakAttackModFeature.damageMultiplier
        }
      }

      if (sneakAttackFeature.attackAfterSneakAttackAutoCrits) {
        // Walk through and manually find the sneak attack turn, then find the turn after that and mark it as autocrit
        const foundSneakAttackTurnIndex = turnActions.findIndex(
          (turn) => turn.isWeapon() && (turn.isFinesseWeapon() || turn.isRangedWeapon())
        )

        const turn = turnActions.slice(foundSneakAttackTurnIndex + 1).find((turn) => turn.isAttackRoll())
        if (turn) {
          turn.autoCrit = true

          // NOTE: For now these are coupled…
          if (sneakAttackFeature.attackAfterSneakAttackHasAdvantage) {
            turn.advantage = advantage
          }
        }
      }
    }
  }

  configureBoomingBlade(features: Feature[]): void {
    const bb = features.find((feature) => feature.isBoomingBlade)
    if (bb) {
      bb.targetMoved = features.some((feature) => feature.isBoomingBladeTargetMoved)
    }
  }
}
