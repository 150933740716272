import { Dice } from './Dice'
import { Activation } from '../DDB/Activation'
import { Character } from './Character'
import { Dictionary } from '../Common/Types'
import { Class, NON_BUFF_RACIAL_TRAITS } from '../Common/Constants'
import { NON_BUFF_ACTIONS } from '../Common/Constants'
import { Utility } from '../Common/Utility'

export enum FeatureSource {
  None,
  Class,
  Feat,
  Effect,
  Spell,
  RacialTrait,
  FightingStyle,
  ClassOption,
  Item
}

const featureSourceNames: Record<FeatureSource, string> = {
  [FeatureSource.None]: 'none',
  [FeatureSource.Class]: 'class feature',
  [FeatureSource.Feat]: 'feat',
  [FeatureSource.Effect]: 'effect',
  [FeatureSource.Spell]: 'spell',
  [FeatureSource.RacialTrait]: 'racial trait',
  [FeatureSource.FightingStyle]: 'fighting style',
  [FeatureSource.ClassOption]: 'class option',
  [FeatureSource.Item]: 'item'
}

export class Feature {
  // General
  name: string
  featureSource: FeatureSource
  id: number
  isBuff: boolean = false
  activation?: Activation
  requiresConcentration: boolean = false
  oncePerTurn: boolean = false // favored foe
  usesLimitedResource: boolean = false // superiority dice, ki, etc (can't use in sustained round, only nova round). Actions have "limitedUse" object on them?
  critDiceDealMaxDamage: boolean = false
  notes: string = ''
  defaultEnabled: boolean = false
  snippet: string

  // Attacks
  extraAttackThisTurn: boolean = false
  extraAttackThisAction: boolean = false
  extraBonusActionAttackThisAction: boolean = false
  damageMultiplier: number = 1
  attackRollOnly: boolean = false // weapon and spell attacks

  // Weapons
  additionalMeleeRange: number = 0
  weaponOnly: boolean = false
  rangedWeaponOnly: boolean = false
  bowOnly: boolean = false // Arcane Shot
  thrownWeaponOnly: boolean = false
  meleeWeaponOnly: boolean = false
  lightWeaponOnly: boolean = false
  meleeAttackOnly: boolean = false // melee weapon OR unarmed strikes
  heavyWeaponOnly: boolean = false
  finesseWeaponOnly: boolean = false
  finessOrRangedeWeaponOnly: boolean = false // TODO FIX TYPO
  twoHandedOrVersatileOnly: boolean = false // GWF
  singleWieldingOnly: boolean = false // Dueling
  offHandOnly: boolean = false
  addWeaponStatModifier: boolean = false
  hornsOnly: boolean = false
  psychicBladeOnly: boolean = false
  piercingDamageOnly: boolean = false
  wildshapeAttackOnly: boolean = false

  // Spells
  spellOnly: boolean = false
  fireDamageOnly: boolean = false
  lightningOrThunderDamageOnly: boolean = false
  fireOrRadiantDamageOnly: boolean = false
  singleTargetSpellOnly: boolean = false
  tollTheDeadOnly: boolean = false

  // TODO this is silly, but it's a quick fix for now
  isBoomingBlade: boolean = false
  isBoomingBladeTargetMoved: boolean = false
  targetMoved: boolean = false // for BB
  isTrueStrike: boolean = false

  // Feats
  rerollOnAdvantage: boolean = false // Elven accuracy

  // Companions
  alsoAppliesToCompanionAttacks: boolean = false // applies to your attacks and companion attacks
  onlyAppliesToCompanionAttacks: boolean = false // affects that only bolster companion attacks

  // Gloomstalker
  extraAttackFirstRoundDice?: Dice

  // Warlock
  expandedCrit: boolean = false
  pactWeaponOnly: boolean = false
  eldritchBlastOnly: boolean = false
  onlyOneBeam: boolean = false // Eldritch Smite

  // Fighter
  actionSurge: boolean = false
  extraTurn: boolean = false // Strength before death
  advantage: boolean = false
  forgoAdvantageNextAttack: boolean = false

  // Rogue
  autoCrit: boolean = false // Assassinate on surprise
  autoHit: boolean = false // Stroke of Luck capstone
  doubleDamage: boolean = false // Thief's Reflexes

  // TODO these are hackss, we should generalize these
  nextPsychicBladeAttackHasAdvantage: boolean = false // Psychic Blade (Vex) - and Vex Weapon Mastery
  nextPsychicBladeAttacksHaveAdvantage: boolean = false // Psychic Blade (Rend Mind)

  // TODO - these can turn into some sort of "modifiesFeatureID" int field… but since it's just 2, this is fine
  sneakAttackOnly: boolean = false // Only affects sneak attack
  cunningStrikePoisonOnly: boolean = false // Only affects cunning strike poison

  sneakAttackDice?: Dice // Used to retain original dice when it is modified by cunning/devious strikes
  cunningStrikeDice?: Dice // Used to retain original dice when it is modified

  attackAfterSneakAttackAutoCrits: boolean = false // Knock Out
  attackAfterSneakAttackHasAdvantage: boolean = false // Knock Out

  // Barbarian
  additionalDamageDiceOnCrit: number = 0 // brutal critical
  recklessAttackOnly: boolean = false // 2024 brutal strike
  raging: boolean = false // 2024 frenzy
  ragingOnly: boolean = false // 2024 frenzy

  // Cleric
  cantripsOrWeaponsOnly: boolean = false
  damageVulnerability: boolean = false
  cantripOnly: boolean = false
  maxDamage: boolean = false

  // Sorc
  disadvantage: boolean = false // Heightened Spell
  convertActionToBonusAction: boolean = false // Quickened Spell
  spellAttackOnly: boolean = false // Seeking Spell
  minimumD20Roll: number = 0 // Trance of Order
  spellSaveDCIncrease: number = 0 // Innate Sorcery

  // Monk
  unarmedOnly: boolean = false
  flurryOfBlowsOnly: boolean = false
  radiantSunBoltOnly: boolean = false
  astralArmsOnly: boolean = false
  additionalEffectCount: number = 0

  // Wizard
  evocationSpellsOnly: boolean = false
  potentCantrip: boolean = false

  // Feats
  nextAttacksHaveAdvantage: boolean = false // Grappler 2024

  // Other
  replacementDamageDice?: Dice // Toll the Dead
  additionalToHitDice?: Dice // precision attack
  additionalDamageOnHitDice?: Dice // booming blade, hex, favored foe, sneak attack, smite
  additionalDamageOnMoveDice?: Dice // booming blade
  additionalDamageToFiendsAndUndeadDice?: Dice // smite // TODO
  additionalDamageDiceOnHit: number = 0 // Undead form of dread
  rerollDamageDiceThreshold: number = 0 // Great Weapon Fighting 2014
  minimumDamageDieRoll: number = 0 // Great Weapon Fighting 2024 (and Elemental Adept)
  elementalAdeptDamageType?: string
  rerollAllDamageDiceOnHit: boolean = false // Savage Attacker
  rerollToHit: boolean = false

  // Wizard
  maxSpellLevel: number = 0

  generatedNotes(): string {
    return this.generateNotes()
  }

  weaponStringForNotes(): string | undefined {
    let weaponString = undefined
    if (this.finesseWeaponOnly) {
      weaponString = 'finesse weapon'
    } else if (this.meleeWeaponOnly) {
      weaponString = `${this.heavyWeaponOnly ? 'heavy ' : ''} melee weapon`
    } else if (this.lightWeaponOnly) {
      weaponString = 'light weapon'
    } else if (this.meleeAttackOnly) {
      weaponString = 'melee attack'
    } else if (this.rangedWeaponOnly) {
      weaponString = 'ranged weapon'
    } else if (this.bowOnly) {
      weaponString = 'bow'
    } else if (this.thrownWeaponOnly) {
      weaponString = 'thrown weapon'
    } else if (this.spellOnly) {
      weaponString = 'spell'
    } else if (this.spellAttackOnly) {
      weaponString = 'spell attack'
    } else if (this.unarmedOnly) {
      weaponString = 'unarmed strike'
    } else if (this.pactWeaponOnly) {
      weaponString = 'pact weapon'
    } else if (this.psychicBladeOnly) {
      return 'psychic blades'
    } else if (this.wildshapeAttackOnly) {
      return 'wildshape attack'
    }
    return weaponString
  }

  generateNotes(): string {
    if (this.notes) {
      return this.notes
    }

    if (this.actionSurge) {
      return 'Additional action'
    }

    if (this.advantage) {
      return this.oncePerTurn ? 'Next attack is made with advantage' : 'Attacks this turn are made with advantage'
    }

    if (this.disadvantage) {
      return this.oncePerTurn ? 'Next attack is made with disadvantage' : 'Attacks this turn are made with disadvantage'
    }

    if (this.additionalDamageOnHitDice) {
      const freqString = this.oncePerTurn ? 'next hit' : 'per hit'
      const weaponString = this.weaponStringForNotes()
      return `+${this.additionalDamageOnHitDice!.diceString()} dmg ${freqString}${weaponString ? ' with ' + weaponString : ''}${this.ragingOnly ? ' while raging' : ''}`
    }

    if (this.additionalToHitDice) {
      const weaponString = this.weaponStringForNotes()
      return `+${this.additionalToHitDice.diceString()} to hit${weaponString ? ' with ' + weaponString : ''}${this.ragingOnly ? ' while raging' : ''}`
    }

    if (this.extraAttackThisTurn) {
      return 'Extra attack this turn'
    }

    if (this.extraAttackThisAction) {
      return 'Extra attack this action'
    }

    if (this.extraBonusActionAttackThisAction) {
      return 'Extra bonus action attack this turn'
    }

    if (this.additionalDamageDiceOnCrit > 0) {
      const count = this.additionalDamageDiceOnCrit
      const dieString = count === 1 ? 'die' : 'dice'
      return `+${count} weapon ${dieString} on crit`
    }

    if (this.rerollToHit) {
      return 'Reroll 1s on attack rolls'
    }

    if (this.rerollAllDamageDiceOnHit) {
      if (this.oncePerTurn) {
        return 'Reroll weapon damage dice once per turn'
      }
      return 'Reroll weapon damage dice'
    }

    if (this.elementalAdeptDamageType) {
      return `Minimum ${this.elementalAdeptDamageType} dmg roll is 2`
    }

    return ''
  }

  displayName() {
    if (this.additionalDamageOnHitDice) {
      return this.name + ': ' + this.additionalDamageOnHitDice.diceString()
    } else if (this.additionalToHitDice) {
      return this.name + ': ' + this.additionalToHitDice.diceString()
    }

    return this.name
  }

  parseExternalEffects(action: Dictionary, character: Character): boolean {
    if (this.name === 'Bardic Inspiration die') {
      this.oncePerTurn = true

      // Assume the bardic inspiration dice was granted at your level.
      // Later make it configurable
      const characterLevel = character.totalLevel
      const dieValue = character.bardicInspirationDieSize(characterLevel)
      this.additionalToHitDice = Dice.Create(1, dieValue)
      return true
    }

    if (this.name === 'Bless') {
      this.additionalToHitDice = Dice.Create(1, 4)
      this.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (this.name === 'Advantage on next attack' || this.name === 'Heroic Inspiration') {
      this.advantage = true
      this.oncePerTurn = true
      this.attackRollOnly = true
      this.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (this.name === 'Advantage this turn') {
      this.advantage = true
      this.attackRollOnly = true
      this.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (this.name === 'Haste') {
      // TODO: Extra Attack this TURN, not this ACTION
      this.extraAttackThisTurn = true
      return true
    }

    if (this.name === 'Perkins crit') {
      this.critDiceDealMaxDamage = true
      this.alsoAppliesToCompanionAttacks = true
      this.notes = 'Crit dice deal maximum damage'
      return true
    }

    this.logUnknownAction(action)
    return false
  }

  parseItemEffect(item: Dictionary): boolean {
    const name = item.name
    if (name === 'Gloves of Soul Catching') {
      this.unarmedOnly = true
      this.additionalDamageOnHitDice = Dice.Create(2, 10)
      return true
    }
    return false
  }

  parseSpellEffect(action: Dictionary, character: Character): boolean {
    this.activation = action.activation

    if (this.name === 'Absorb Elements') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.meleeAttackOnly = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (this.name === 'Zephyr Strike') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (this.name === 'Flame Arrows') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      this.usesLimitedResource = true
      this.rangedWeaponOnly = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (this.name === 'Hex') {
      this.isBuff = true
      this.additionalDamageOnHitDice = new Dice(action.dice!)
      return true
    }

    if (this.name === "Hunter's Mark") {
      this.isBuff = true
      this.weaponOnly = true
      this.additionalDamageOnHitDice = new Dice(action.dice!)
      return true
    }

    if (this.name.includes('Smite')) {
      const higherLevelDice = action.higherLevelDice ? new Dice(action.higherLevelDice) : undefined
      const spellLevel = action.level
      const upcastLevel = action.upcastLevel ?? spellLevel

      this.additionalDamageOnHitDice = new Dice(action.dice!)

      if (higherLevelDice !== undefined) {
        this.name = `${this.name} (Level ${upcastLevel})`
        this.additionalDamageOnHitDice.diceCount += upcastLevel - spellLevel
      }

      this.isBuff = true
      this.requiresConcentration = true
      this.oncePerTurn = true
      this.weaponOnly = true
      return true
    }

    if (this.name === 'Spirit Shroud') {
      const dice = new Dice(action.dice)
      const highestSlot = character.highestLevelSpellSlot
      dice.diceCount = Math.floor((highestSlot - 1) / 2)
      this.additionalDamageOnHitDice = dice
      this.usesLimitedResource = true
      this.attackRollOnly = true
      return true
    }

    if (this.name === 'Booming Blade') {
      this.oncePerTurn = true
      this.meleeWeaponOnly = true
      this.isBoomingBlade = true
      // TODO - this is a cantrip, so it should prevent other melee attacks this turn
      const bbDiceCount = character.cantripDieCount() - 1
      this.additionalDamageOnHitDice = Dice.Create(bbDiceCount, 8)

      return true
    }

    if (this.name === 'Booming Blade: Target Moves') {
      this.isBoomingBladeTargetMoved = true
      const bbDiceCount = character.cantripDieCount()
      const moveDice = Dice.Create(bbDiceCount, 8)
      this.notes = `+${moveDice.diceString()} dmg if target moves`
      return true
    }

    if (this.name === 'True Strike') {
      this.oncePerTurn = true
      this.weaponOnly = true
      this.isTrueStrike = true

      const bbDiceCount = character.cantripDieCount() - 1

      if (bbDiceCount > 0) {
        this.additionalDamageOnHitDice = Dice.Create(bbDiceCount, 6)
        this.notes = `Use spellcasting mod, +${this.additionalDamageOnHitDice.diceString()} dmg next weapon attack`
      } else {
        this.notes = `Use spellcasting modifier for next weapon attack`
      }
      return true
    }

    if (this.name === 'Green-Flame Blade') {
      this.oncePerTurn = true
      this.weaponOnly = true
      // TODO - this is a cantrip, so it should prevent other melee attacks this turn
      const gfbDiceCount = character.cantripDieCount() - 1
      const spellcastingMod = character.spellcastingAbilityModifier
      this.additionalDamageOnHitDice = Dice.Create(2 * gfbDiceCount, 8, spellcastingMod)
      return true
    }

    if (this.name === 'Toll the Dead: Injured') {
      this.tollTheDeadOnly = true
      const cantripDieCount = character.cantripDieCount()
      this.replacementDamageDice = Dice.Create(cantripDieCount, 12)
      this.notes = `Toll the Dead deals ${cantripDieCount}d12 to injured targets`
      return true
    }
    // For now don't log unknown spells, as most of them aren't handled
    //this.logUnknownAction(action)

    return false
  }

  parseArcaneArcherArrowFeatures(action: Dictionary, character: Character): boolean {
    this.bowOnly = true
    this.oncePerTurn = true
    const name = this.name

    if (name === 'Banishing Arrow') {
      const fighterLevel = character.classLevel(Class.FIGHTER)
      if (fighterLevel < 18) {
        return false
      }

      this.additionalDamageOnHitDice = Dice.Create(2, 6)
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (['Piercing Arrow', 'Seeking Arrow'].includes(name)) {
      const fighterLevel = character.classLevel(Class.FIGHTER)
      this.additionalDamageOnHitDice = Dice.Create(fighterLevel >= 18 ? 2 : 1, 6)
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (['Bursting Arrow', 'Beguiling Arrow', 'Enfeebling Arrow', 'Grasping Arrow', 'Shadow Arrow'].includes(name)) {
      const fighterLevel = character.classLevel(Class.FIGHTER)
      this.additionalDamageOnHitDice = Dice.Create(fighterLevel >= 18 ? 4 : 2, 6)
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (name === 'Curving Shot') {
      this.advantage = true // Same math as "can attack a second target if you miss"
      // TODO… but this requires a bonus action to use

      this.notes = 'Reroll attack against new target if you miss'
      return true
    }

    return false
  }

  parseManeuverFeature(action: Dictionary, character: Character): boolean {
    const superiorityDice = character.superiorityDice()
    const name = this.name

    if (
      [
        'Maneuvers: Trip Attack',
        'Maneuvers: Brace',
        'Maneuvers: Sweeping Attack',
        'Maneuvers: Menacing Attack',
        'Maneuvers: Goading Attack',
        'Maneuvers: Maneuvering Attack',
        'Maneuvers: Pushing Attack',
        'Maneuvers: Lunging Attack',
        'Maneuvers: Disarming Attack'
      ].includes(name)
    ) {
      this.weaponOnly = true
      this.additionalDamageOnHitDice = superiorityDice
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Maneuvers: Quick Toss') {
      this.thrownWeaponOnly = true
      this.additionalDamageOnHitDice = superiorityDice
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.convertActionToBonusAction = true
      return true
    }

    if (name === 'Maneuvers: Riposte') {
      this.extraAttackFirstRoundDice = superiorityDice
      if (!this.extraAttackFirstRoundDice) {
        return false
      }

      this.meleeWeaponOnly = true
      this.extraAttackThisTurn = true
      this.oncePerTurn = true // TODO: It's a reaction
      this.usesLimitedResource = true

      this.notes = `Extra attack to enemy that missed, +${this.extraAttackFirstRoundDice.diceString()} dmg`
      return true
    }

    if (name === 'Maneuvers: Precision Attack') {
      this.usesLimitedResource = true
      this.additionalToHitDice = superiorityDice
      return true
    }
    return false
  }

  parseChannelDivinityFeature(action: Dictionary, character: Character): boolean {
    this.usesLimitedResource = true
    const name = this.name

    if (name === 'Channel Divinity: Path to the Grave') {
      this.damageVulnerability = true
      this.oncePerTurn = true
      this.notes = 'Next attack has vulnerability to all damage'
      return true
    }

    if (name === 'Channel Divinity: Sacred Weapon') {
      const chaMod = character.modifierForAbility('charisma')
      this.additionalToHitDice = Dice.flatAmountDie(chaMod)
      this.weaponOnly = true
      this.notes = `Add ${chaMod} (charisma modifier) to attack rolls`
      return true
    }

    if (name === 'Channel Divinity: Guided Strike' || name === 'Channel Divinity: Guided Strike (Self)') {
      this.name = 'Channel Divinity: Guided Strike'
      this.oncePerTurn = true
      this.additionalToHitDice = Dice.flatAmountDie(10)
      return true
    }

    if (name === 'Channel Divinity: Destructive Wrath') {
      this.name = 'Channel Divinity: Destructive Wrath (TODO)'
      this.lightningOrThunderDamageOnly = true
      this.maxDamage = true // TODO
      this.oncePerTurn = true
      this.notes = 'Next lightning or thunder attack does max damage'
      return true
    }

    if (name === 'Channel Divinity: Touch of Death') {
      this.meleeAttackOnly = true
      const extraDamage = 5 + 2 * character.classLevel(Class.CLERIC)
      this.additionalDamageOnHitDice = Dice.flatAmountDie(extraDamage)
      this.oncePerTurn = true
      this.notes = `+${extraDamage} dmg to next melee attack`
      return true
    }

    if (name === 'Channel Divinity: Vow of Enmity' || name === "Channel Divinity: Nature's Wrath") {
      this.advantage = true
      this.weaponOnly = true
      return true
    }

    return false
  }

  parseMetamagicFeature(): boolean {
    this.spellOnly = true
    this.usesLimitedResource = true
    const name = this.name

    if (name.includes('Heightened Spell')) {
      this.disadvantage = true
      this.oncePerTurn = true
      this.notes = 'Disadvantage on saves against next spell'
      return true
    }

    if (name.includes('Quickened Spell')) {
      this.oncePerTurn = true
      this.convertActionToBonusAction = true
      this.notes = 'Next 1 action spell cost 1 bonus action'
      return true
    }

    if (name.includes('Seeking Spell')) {
      this.oncePerTurn = true
      this.advantage = true
      this.spellAttackOnly = true
      this.notes = 'Next spell attack has advantage'
      return true
    }

    if (name.includes('Twinned Spell')) {
      this.damageMultiplier = 2
      this.singleTargetSpellOnly = true
      this.oncePerTurn = true
      this.notes = 'Next single target spell has second target'
      return true
    }

    return false
  }

  parseRemainingClassFeatures(action: Dictionary, character: Character): boolean {
    const requiredLevel = parseInt(action.requiredLevel)
    const name = this.name
    const id = action.id

    /// 2024 Class Features
    if (name === 'Lifedrinker' && id === '9414094') {
      this.additionalDamageOnHitDice = new Dice(action.dice)

      this.defaultEnabled = true
      this.pactWeaponOnly = true
      return true
    }

    if (name === `Thief’s Reflexes`) {
      if (requiredLevel) return false

      // "9414617"
      this.doubleDamage = true
      this.notes = 'Two turns during first round of combat (this just doubles dpr)'
      return true
    }

    if (name === 'Stroke of Luck') {
      if (requiredLevel) return false
      this.attackRollOnly = true
      this.autoHit = true
      this.oncePerTurn = true
      this.notes = 'Next attack roll automatically hits'
      return true
    }

    if (name === 'Psychic Blades: Rend Mind') {
      this.notes = 'Psychic Blades stun on failed Wis save'
      this.psychicBladeOnly = true
      this.oncePerTurn = true
      this.nextPsychicBladeAttacksHaveAdvantage = true
      return true
    }

    if (name === 'Vex (Psychic Blades)') {
      this.psychicBladeOnly = true
      this.defaultEnabled = true
      this.nextPsychicBladeAttackHasAdvantage = true
      this.notes = 'Psychic Blades get the Vex weapon mastery'
      return true
    }

    if (name === 'Sneak Attack: Assassinate') {
      const rogueLevel = character.classLevel(Class.ROGUE)
      this.sneakAttackOnly = true
      this.advantage = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(rogueLevel)
      this.notes = `Advantage on attacks and +${rogueLevel} sneak attack damage`
      return true
    }

    if (name === 'Sneak Attack: Supreme Sneak (Cost: 1d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-1, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.notes = `Don't lose invisible condition after sneak attack`
      return true
    }
    if (name === 'Sneak Attack: Daze (Cost: 2d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-2, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.name = 'Devious Strike: Daze (Cost: 2d6)'
      this.notes = 'Daze enemy on failed Con save'
      return true
    }

    if (name === 'Sneak Attack: Knock Out (Cost: 6d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-6, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.name = 'Devious Strike: Knock Out (Cost: 6d6)'
      this.notes = 'Knock enemy unconscious on failed Con save'
      this.attackAfterSneakAttackAutoCrits = true
      this.attackAfterSneakAttackHasAdvantage = true

      return true
    }

    if (name === 'Sneak Attack: Obscure (Cost: 3d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-3, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.name = 'Devious Strike: Obscure (Cost: 3d6)'
      this.notes = 'Blind enemy on failed Dex save'
      this.nextAttacksHaveAdvantage = true
      return true
    }

    if (name === 'Sneak Attack: Poison (Cost: 1d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-1, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.name = 'Cunning Strike: Poison (Cost: 1d6)'
      this.notes = 'Poison enemy on failed Con save'

      return true
    }

    if (name === 'Sneak Attack: Trip (Cost: 1d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-1, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.nextAttacksHaveAdvantage = true
      this.name = 'Cunning Strike: Trip (Cost: 1d6)'
      this.notes = 'Knock enemy prone on failed Dex save'

      return true
    }

    if (name === 'Sneak Attack: Withdraw (Cost: 1d6)') {
      this.sneakAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(-1, 6)
      this.cunningStrikeDice = this.additionalDamageOnHitDice.copy()
      this.name = 'Cunning Strike: Withdraw (Cost: 1d6)'
      this.notes = 'Move half speed after attack without opportunity attacks'
      return true
    }

    if (name === 'Corona of Light') {
      if (requiredLevel) return false

      this.disadvantage = true
      this.fireOrRadiantDamageOnly = true
      this.spellOnly = true
      this.notes = 'Enemies have disadvantage on saves against fire or radiant spells'
      return true
    }

    if (name === 'Moonlight Step' && (id === 9414273 || id === '9414273')) {
      if (requiredLevel) return false

      this.advantage = true
      this.attackRollOnly = true
      this.oncePerTurn = true
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Wild Shape: Improved Lunar Radiance') {
      this.wildshapeAttackOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(2, 10)

      this.name = 'Improved Lunar Radiance'

      return true
    }

    if (name === 'Battle Magic') {
      if (requiredLevel) return false
      // This is technically a no-op, the UI already lets us do this.

      this.weaponOnly = true
      this.oncePerTurn = true
      this.convertActionToBonusAction = true
      this.notes = 'After casting action spell, make a bonus action weapon attack'
      return true
    }

    if (name === 'Bardic Inspiration: Agile Strikes') {
      this.unarmedOnly = true
      this.extraAttackThisAction = true
      this.usesLimitedResource = true
      this.notes = 'Make an extra unarmed strike this action'
      return true
    }

    if (name === 'War Priest: Bonus Attack') {
      this.weaponOnly = true
      this.convertActionToBonusAction = true
      this.oncePerTurn = true
      this.usesLimitedResource = true
      this.notes = 'Make an extra bonus action attack'

      return true
    }

    if (name === 'Invoke Duplicity: Distract' && (action.id === 9414177 || action.id === '9414177')) {
      // Strangely there are 2 different actions with the same name, just grab one.
      this.attackRollOnly = true
      this.advantage = true
      return true
    }

    if (name === 'Invoke Duplicity: Shared Distraction') {
      this.attackRollOnly = true
      this.advantage = true
      return true
    }

    if (name === 'Rage (Enter)') {
      this.usesLimitedResource = true
      this.weaponOnly = true // TODO: technically strength based attack rolls only
      this.attackRollOnly = true

      const damageBonus: number = character.rageBonusDamage()
      this.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      this.raging = true
      this.name = 'Rage'

      return true
    }

    if (name.startsWith('Brutal Strike:')) {
      const barbarianLevel = character.classLevel(Class.BARBARIAN)

      this.recklessAttackOnly = true
      this.forgoAdvantageNextAttack = true
      this.oncePerTurn = true
      this.meleeAttackOnly = true // TODO: Technically any strength based attack rolls
      this.additionalDamageOnHitDice = Dice.Create(barbarianLevel >= 17 ? 2 : 1, 10)
      this.notes = `Forgo adv while reckless for +${this.additionalDamageOnHitDice.diceString()} dmg next attack`
      return true
    }

    if (name === 'Frenzy') {
      this.meleeAttackOnly = true
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.ragingOnly = true // TODO implement
      const rageBonus: number = character.rageBonusDamage()

      this.additionalDamageOnHitDice = Dice.Create(rageBonus, 6)
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next melee attack while raging`

      return true
    }

    if (name === 'Innate Sorcery' && requiredLevel && (action.id === 10292362 || action.id === '10292362')) {
      this.spellSaveDCIncrease = 1
      this.spellOnly = true
      this.notes = '+1 to Sorcerer Spell Save DC'
      this.name = 'Innate Sorcery (Spell Save DC)'
      return true
    }

    if (name === 'Innate Sorcery' && !requiredLevel) {
      this.advantage = true
      this.spellAttackOnly = true

      this.notes = 'Advantage on Sorcerer spell attack rolls'
      this.name = 'Innate Sorcery (Spell Attack)'

      return true
    }

    if (name === 'Ki-Fueled Attack') {
      /// 2014
      this.extraBonusActionAttackThisAction = true
      this.notes = 'Extra bonus action monk attack this turn'
      return true
    }

    if (name === 'Empowered Evocation') {
      const intMod = character.modifierForAbility('intelligence')
      this.spellOnly = true
      this.evocationSpellsOnly = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      this.oncePerTurn = true
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg to next evocation spell`
      return true
    }

    if (name === 'Potent Cantrip') {
      this.cantripOnly = true
      this.potentCantrip = true
      this.notes = `Cantrips deal half damage on save and (TODO) miss`
      return true
    }

    if (name === 'Song of Victory') {
      const intMod = character.modifierForAbility('intelligence')
      this.meleeWeaponOnly = true
      this.usesLimitedResource = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      return true
    }

    if (name === 'Nature’s Veil') {
      if (character.classLevel(Class.RANGER) < requiredLevel) return false

      this.advantage = true
      this.oncePerTurn = true
      return true
    }

    if (name === 'Giant’s Havoc: Crushing Throw') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false

      this.name = 'Crushing Throw'
      this.weaponOnly = true
      this.thrownWeaponOnly = true
      const damageBonus: number = character.rageBonusDamage()
      this.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      return true
    }

    if (name === 'Fire Rune') {
      if (character.classLevel(Class.FIGHTER) < requiredLevel) return false

      this.additionalDamageOnHitDice = Dice.Create(2, 6)
      this.usesLimitedResource = true
      this.weaponOnly = true
      this.oncePerTurn = true
      return true
    }

    if (name === 'Eye for Weakness') {
      if (character.classLevel(Class.ROGUE) < requiredLevel) return false

      this.additionalDamageOnHitDice = Dice.Create(3, 6)
      this.notes = 'Additional 3d6 dmg on sneak attack'
      // TODO… only on sneak attacks
      return true
    }

    if (name === 'Ambush Master') {
      if (character.classLevel(Class.ROGUE) < requiredLevel) return false
      this.advantage = true
      return true
    }

    if (name === 'Psychic Blades: Homing Strikes') {
      // 2024 Soulknife

      if (character.classLevel(Class.ROGUE) < requiredLevel) return false
      const energieDie = character.soulknifeEnergyDieSize(Class.ROGUE)
      this.additionalToHitDice = Dice.Create(1, energieDie)
      this.usesLimitedResource = true
      this.psychicBladeOnly = true
      return true
    }

    if (name === 'Soul Blades: Homing Strikes') {
      if (character.classLevel(Class.ROGUE) < requiredLevel) return false

      const energieDie = character.psionicEnergyDieSize(Class.ROGUE)
      this.additionalToHitDice = Dice.Create(1, energieDie)
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Experimental Elixir') {
      if (character.classLevel(Class.ARTIFICER) < requiredLevel) return false

      this.name = 'Experimental Elixir (Boldness)'
      this.additionalToHitDice = Dice.Create(1, 4)
      return true
    }

    if (name === 'Death Strike') {
      if (character.classLevel(Class.ROGUE) < requiredLevel) return false
      // TODO: CON save or deal DOUBLE DAMAGE
      return false
    }

    if (name === 'Giant’s Havoc: Giant Stature') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false
      this.name = 'Giant Stature'

      this.damageMultiplier = 2
      this.additionalMeleeRange = 10
      this.notes = 'Grow Large and (todo) increase melee range by 5ft'
      return true
    }

    if (name === 'Form of the Beast: Claws') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false

      this.extraAttackThisAction = true
      this.oncePerTurn = true
      this.weaponOnly = true
      this.notes = 'Gain 1 additional claw attack if you attack with a claw'
      return true
    }

    if (name === 'Psionic Power: Psionic Strike') {
      if (character.classLevel(Class.FIGHTER) < requiredLevel) return false

      const dieSize = character.psionicEnergyDieSize(Class.FIGHTER)
      const intModifier = character.modifierForAbility('intelligence')
      this.additionalDamageOnHitDice = Dice.Create(1, dieSize, intModifier)
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Bolstering Magic') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false
      this.additionalDamageOnHitDice = Dice.Create(1, 3)
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Blade Flourish') {
      if (character.classLevel(Class.BARD) < requiredLevel) return false
      this.oncePerTurn = true
      this.meleeWeaponOnly = true
      const damage = character.bardicInspirationDieSize()
      this.additionalDamageOnHitDice = Dice.Create(1, damage)
      return true
    }

    if (name === 'Reckless Attack') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false
      this.advantage = true
      this.meleeAttackOnly = true
      return true
    }

    if (name === 'Brutal Critical') {
      const barbarianLevel = character.classLevel(Class.BARBARIAN)
      if (barbarianLevel < requiredLevel) {
        return false
      }

      const levelDamageMap: Record<number, number> = { 9: 1, 13: 2, 17: 3 }
      for (const level in levelDamageMap) {
        const lvlInt = parseInt(level)
        if (barbarianLevel >= lvlInt && barbarianLevel < lvlInt + 4 && requiredLevel === lvlInt) {
          this.defaultEnabled = true
          this.additionalDamageDiceOnCrit = levelDamageMap[lvlInt]
          return true
        }
      }

      return false
    }

    if (name === 'Improved Critical' || name === 'Superior Critical') {
      if (character.classLevel(Class.FIGHTER) < requiredLevel) return false

      this.defaultEnabled = true
      this.expandedCrit = true
      return true
    }

    if (name === 'Totemic Attunement - Tiger') {
      if (character.classLevel(Class.BARBARIAN) < requiredLevel) return false

      this.extraAttackThisTurn = true
      this.notes = 'Extra bonus action melee attack'
      return true
    }

    if (name === 'Favored Foe' && action.dice) {
      if (character.classLevel(Class.RANGER) < requiredLevel) return false

      this.usesLimitedResource = true
      this.requiresConcentration = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.weaponOnly = true
      return true
    }

    if (name === 'Drake Companion: Summon') {
      if (character.classLevel(Class.RANGER) < requiredLevel) return false

      this.name = 'Drake Companion: Infused Strike' // TODO: Seems hacky, why not use the infused strike?
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(1, 6) // TODO… scale this with level
      return true
    }

    if (name === 'Grave Touched') {
      if (character.classLevel(Class.WARLOCK) < requiredLevel) return false

      this.name = 'Grave Touched (Form of Dread)'
      this.oncePerTurn = true
      this.additionalDamageDiceOnHit = 1
      this.notes = '1 additional necrotic damage die on hit'
      return true
    }

    if (name === 'Stalker’s Flurry') {
      if (character.classLevel(Class.RANGER) < requiredLevel) return false

      this.extraAttackThisTurn = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Potent Spellcasting') {
      if (character.classLevel(Class.CLERIC) < requiredLevel) return false
      const wisdomMod = character.modifierForAbility('wisdom')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      this.defaultEnabled = true
      this.cantripOnly = true
      this.notes = `+${wisdomMod} dmg to next cantrip`
      return true
    }

    if (name === 'Blessed Strikes' && action.dice !== undefined) {
      if (character.classLevel(Class.CLERIC) < requiredLevel) return false

      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.cantripsOrWeaponsOnly = true
      this.oncePerTurn = true
      this.defaultEnabled = true
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit for weapons or cantrips`
      return true
    }

    if (name === 'Sneak Attack: Poison (Envenom)') {
      this.notes = 'Add 2d6 poison damage to `Cunning Strike: Poison`'
      this.cunningStrikePoisonOnly = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.name = 'Envenom Weapons'
      return true
    }

    if (name === 'Sneak Attack: Death Strike') {
      this.sneakAttackOnly = true
      this.damageMultiplier = 2
      this.notes = 'Double damage Sneak Attack on first round'
      // TODO - later, this and other similar features should do the math based on CON saves or whatever.
      return true
    }

    if (name === 'Assassinate: Surprised') {
      // http://localhost:3000/character/127674273?features=1%2C42%2C89%2C133%2C360%2C1021%2C1118%2C1131%2C53484%2C71185%2C1306490%2C2438252&actions=1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0&overrides=%7B%7D&ac=23
      this.weaponOnly = true
      this.autoCrit = true
      this.notes = 'Attacks against surprised creatures are crits'
      return true
    }

    // 2014 Assassinate
    if (name === 'Assassinate' && id !== 10292916) {
      // 10292916 is the 2024 Assassinate feature

      if (character.classLevel(Class.ROGUE) < action.requiredLevel) return false

      this.advantage = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Focused Aim') {
      this.weaponOnly = true
      this.usesLimitedResource = true
      const kiPoints = action.kiPoints ?? 1
      this.additionalToHitDice = Dice.flatAmountDie(2 * kiPoints)
      this.name = `Focused Aim (${Utility.kiPointsString(kiPoints)})`
      return true
    }

    ////// Below here, look at non-subclass features. Later, make this less redundant.
    const isSubclassFeature = action.entityTypeId === undefined
    if (name === 'Steady Aim') {
      if (character.classLevel(Class.ROGUE) < requiredLevel) return false
      if (isSubclassFeature) return false
      this.advantage = true
      this.oncePerTurn = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Unerring Accuracy') {
      if (isSubclassFeature) return false
      this.weaponOnly = true
      this.oncePerTurn = true
      this.advantage = true
      return true
    }

    if (name === 'Quivering Palm') {
      if (isSubclassFeature) return false
      this.unarmedOnly = true
      this.oncePerTurn = true
      this.usesLimitedResource = true
      this.additionalDamageOnHitDice = Dice.Create(10, 10)
      return true
    }

    if (name === 'Fangs of the Fire Snake') {
      if (isSubclassFeature) return false
      this.unarmedOnly = true
      this.additionalMeleeRange = 10
      this.additionalDamageOnHitDice = Dice.Create(1, 10)
      return true
    }

    if (name === 'Radiant Sun Bolt') {
      if (isSubclassFeature) return false
      this.radiantSunBoltOnly = true
      this.additionalEffectCount = 2
      this.notes = '2 additional radiant sun bolts'
      return true
    }

    if (name === 'Sharpen the Blade') {
      if (isSubclassFeature) return false
      this.weaponOnly = true
      this.usesLimitedResource = true
      const kiPoints = action.kiPoints ?? 1
      this.additionalToHitDice = Dice.flatAmountDie(kiPoints)
      this.additionalDamageOnHitDice = Dice.flatAmountDie(kiPoints)
      this.notes = `+${kiPoints} to attack and dmg`
      this.name = `Sharpen the Blade (${Utility.kiPointsString(kiPoints)})`
      return true
    }

    if (name === 'Deft Strike') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      const monkDieSize = character.monkDieSize()
      this.additionalDamageOnHitDice = Dice.Create(1, monkDieSize)
      return true
    }

    if (name === "Kensei's Shot") {
      if (isSubclassFeature) return false
      this.rangedWeaponOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1, 4)
      return true
    }

    if (name === 'Intoxicated Frenzy') {
      if (isSubclassFeature) return false
      this.flurryOfBlowsOnly = true
      this.additionalEffectCount = 3
      this.usesLimitedResource = true
      this.notes = 'Flurry of Blows gains 3 additional blows'
      return true
    }

    if (name === 'Awakened Astral Self') {
      if (isSubclassFeature) return false
      this.extraAttackThisAction = true
      this.name = 'Astral Barrage'
      this.notes = 'Extra attack if all attacks are astral arms'
      return true
    }

    if (name === 'Body of the Astral Self') {
      if (isSubclassFeature) return false

      this.oncePerTurn = true
      const monkDieSize = character.monkDieSize()
      this.additionalDamageOnHitDice = Dice.Create(1, monkDieSize)
      this.astralArmsOnly = true
      return true
    }

    if (name === 'Hand of Harm') {
      if (isSubclassFeature) return false

      this.oncePerTurn = true
      this.unarmedOnly = true
      this.usesLimitedResource = true
      const wisMod = character.modifierForAbility('wisdom')
      const monkDieSize = character.monkDieSize()
      this.additionalDamageOnHitDice = Dice.Create(1, monkDieSize, wisMod)
      return true
    }

    if (name === 'Cloak of Shadows') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.weaponOnly = true
      this.advantage = true
      return true
    }

    if (name === 'Shadow Step') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.meleeAttackOnly = true
      this.advantage = true
      return true
    }

    if (name === 'Flurry of Healing and Harm') {
      if (isSubclassFeature) return false

      this.oncePerTurn = true
      this.unarmedOnly = true
      this.usesLimitedResource = true
      this.flurryOfBlowsOnly = true
      const wisMod = character.modifierForAbility('wisdom')
      const monkDieSize = character.monkDieSize()
      this.additionalDamageOnHitDice = Dice.Create(1, monkDieSize, wisMod)
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg to flurry of blows`
      return true
    }
    if (name === 'Power Surge') {
      if (isSubclassFeature) return false
      this.spellOnly = true
      this.usesLimitedResource = true
      const wizardLevel = character.classLevel(Class.WIZARD)
      this.additionalDamageOnHitDice = Dice.flatAmountDie(Math.floor(wizardLevel / 2))
      return true
    }

    if (name === 'Undead Thralls') {
      if (isSubclassFeature) return false

      this.onlyAppliesToCompanionAttacks = true

      this.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus)
      this.notes = `+${this.additionalDamageOnHitDice!.diceString()} dmg to creature attacks`
      return true
    }

    if (name === 'Arcane Jolt') {
      if (isSubclassFeature) return false
      const artifcerLevel = character.classLevel(Class.ARTIFICER)
      if (artifcerLevel < requiredLevel) return false

      this.additionalDamageOnHitDice = Dice.Create(artifcerLevel >= 15 ? 4 : 2, 6)
      this.oncePerTurn = true
      return true
    }

    if (this.name === 'Divine Fury' && (action.id === 10292412 || action.id === '10292412')) {
      // 2024 Divine Fury
      const barbarianLevel = character.classLevel(Class.BARBARIAN)
      this.ragingOnly = true
      this.oncePerTurn = true
      this.meleeAttackOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1, 6, Math.floor(barbarianLevel / 2))

      return true
    }

    if (this.name === 'Divine Fury') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.weaponOnly = true
      return true
    }

    if (name === 'Overchannel') {
      if (isSubclassFeature) return false

      this.spellOnly = true
      this.usesLimitedResource = true
      this.maxDamage = true
      this.maxSpellLevel = 5

      this.notes = 'Maximum spell damage for level 1-5 spells'

      return true
    }

    if (this.name.startsWith('Channel Divinity')) {
      if (isSubclassFeature) return false
      if (this.parseChannelDivinityFeature(action, character)) {
        return true
      }
    }

    if (this.name.endsWith('Arrow') || this.name === 'Curving Shot') {
      if (isSubclassFeature) return false
      if (this.parseArcaneArcherArrowFeatures(action, character)) {
        return true
      }
    }

    if (name === 'Entropic Ward') {
      if (isSubclassFeature) return false
      this.advantage = true
      this.attackRollOnly = true
      this.oncePerTurn = true
      return true
    }

    if (name === 'Hurl Through Hell') {
      if (isSubclassFeature) return false
      this.attackRollOnly = true
      this.usesLimitedResource = true
      this.oncePerTurn = true
      if (id === '1039') {
        this.additionalDamageOnHitDice = Dice.Create(10, 10)
        this.name = `${name} (2014)`
      } else {
        this.additionalDamageOnHitDice = new Dice(action.dice)
      }

      return true
    }

    if (name === 'Tides of Chaos') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.usesLimitedResource = true
      this.advantage = true
      this.attackRollOnly = true
      return true
    }

    if (name === 'Spell Bombardment') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.usesLimitedResource = true
      this.spellOnly = true
      this.additionalDamageDiceOnHit = 1
      this.notes = 'Add 1 damage die to spell' // Close enough
      return true
    }

    if (name === 'Heart of the Storm') {
      if (isSubclassFeature) return false
      const sorcererLevel = character.classLevel(Class.SORCERER)
      this.lightningOrThunderDamageOnly = true
      this.spellOnly = true
      this.oncePerTurn = true
      this.usesLimitedResource = true
      const damage = Math.ceil(sorcererLevel / 2)
      this.additionalDamageOnHitDice = Dice.flatAmountDie(damage)
      this.notes = `+${damage} dmg to lightning or thunder spells`
      // TODO: Notes is inaccurate, later this will scale with # of enemies
      return true
    }

    if (name === 'Favored by the Gods') {
      if (isSubclassFeature) return false
      this.additionalToHitDice = Dice.Create(2, 4)
      this.attackRollOnly = true
      this.oncePerTurn = true
      this.usesLimitedResource = true
      return true
    }

    if (name === 'Trance of Order') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.minimumD20Roll = 10
      this.attackRollOnly = true
      this.notes = 'Treat rolls below 9 on d20 as a 10'
      return true
    }

    if (name === 'Divine Strike') {
      if (isSubclassFeature) return false
      const clericLevel = character.classLevel(Class.CLERIC)
      this.additionalDamageOnHitDice = Dice.Create(clericLevel >= 14 ? 2 : 1, 8)
      this.cantripsOrWeaponsOnly = true
      this.oncePerTurn = true
      return true
    }

    if (name === 'Dreadful Strikes') {
      if (isSubclassFeature) return false
      const rangerLevel = character.classLevel(Class.RANGER)
      this.additionalDamageOnHitDice = Dice.Create(1, rangerLevel >= 11 ? 6 : 4)
      this.oncePerTurn = true
      this.defaultEnabled = true
      return true
    }

    if (name === 'Foe Slayer') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.weaponOnly = true
      const wisdomMod = character.modifierForAbility('wisdom')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      // TODO: This is actually TWO features (the other lets you add to hit), we are only doing one half of it.
      return true
    }

    if (name === 'Rage') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.meleeAttackOnly = true

      const damageBonus: number = character.rageBonusDamage()
      this.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      return true
    }

    if (name === 'Bestial Fury') {
      if (isSubclassFeature) return false
      this.extraAttackThisTurn = true
      this.notes = 'Extra beast attack this turn'
      return true
    }

    if (name === 'Elemental Cleaver') {
      if (isSubclassFeature) return false
      const barbarianLevel = character.classLevel(Class.BARBARIAN)

      // TODO: Melee or thrown… but now a bow or crossbow, similar to twoHandedOrVersatileOnly
      this.weaponOnly = true
      this.additionalDamageOnHitDice = Dice.Create(barbarianLevel >= 14 ? 2 : 1, 6)
      return true
    }

    if (name === 'Strength before Death') {
      if (isSubclassFeature) return false
      this.notes = 'Extra turn if hp drops to 0'
      this.extraTurn = true
      return true
    }

    if (name === 'Sudden Strike') {
      if (isSubclassFeature) return false
      this.extraBonusActionAttackThisAction = true
      this.name = 'Sudden Strike (TODO)'
      // Todo: Also can sneak ttack on one bonus action attack
      return true
    }

    if (name === 'Wails from the Grave' || name === 'Tokens of the Departed: Sneak Attack') {
      if (isSubclassFeature) return false
      if (name === 'Tokens of the Departed: Sneak Attack') {
        this.name = 'Tokens of the Departed'
      }
      this.finesseWeaponOnly = true

      // Death’s Friend
      const rogueLevel = character.classLevel(Class.ROGUE)
      const sneakAttackDiceCount = Math.ceil(rogueLevel / 2)
      const diceCount = rogueLevel >= 17 ? sneakAttackDiceCount : Math.ceil(sneakAttackDiceCount / 2)
      this.additionalDamageOnHitDice = Dice.Create(diceCount, 6)
      if (rogueLevel >= 17) {
        this.name = `${this.name} (Death's Friend)`
        const halfDice = Dice.Create(Math.ceil(sneakAttackDiceCount / 2), 6)
        this.notes = `+${halfDice.diceString()} dmg to first and second target`
      } else {
        this.notes = `+${this.additionalDamageOnHitDice!.diceString()} necrotic dmg to second target`
      }

      return true
    }

    if (name === 'Alchemical Savant') {
      if (isSubclassFeature) return false
      // TODO: only acid, fire, necrotic, or poison
      this.spellOnly = true
      const intMod = character.modifierForAbility('intelligence')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      this.notes = `+${intMod} to acid, fire, necrotic, or poison spell damage`
      return true
    }

    if (name === 'Giant’s Might') {
      if (isSubclassFeature) return false
      const fighterLevel = character.classLevel(Class.FIGHTER)
      let damageDie = 6

      if (fighterLevel >= 18) {
        damageDie = 10
        this.additionalMeleeRange = 5
        this.name = 'Giant’s Might (Runic Juggernaut)'
      } else if (fighterLevel >= 10) {
        damageDie = 8
        this.name = 'Giant’s Might (Great Stature)'
      }

      this.additionalDamageOnHitDice = Dice.Create(1, damageDie)
      this.defaultEnabled = true
      this.weaponOnly = true
      this.oncePerTurn = true

      return true
    }

    if (name === 'Emboldening Bond') {
      if (isSubclassFeature) return false
      this.additionalToHitDice = Dice.Create(1, 4)
      this.alsoAppliesToCompanionAttacks = true
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.notes = '+1d4 to hit for next attack'
      return true
    }

    if (name === 'War Priest') {
      if (isSubclassFeature) return false
      this.name = 'War Priest (TODO)'
      return true
    }

    if (name === 'Improved Divine Smite') {
      if (isSubclassFeature) return false
      this.additionalDamageOnHitDice = Dice.Create(1, 8)
      this.meleeWeaponOnly = true
      this.notes = '+1d8 dmg to smites'
      return true
    }

    if (name === 'Divine Smite (Undead or Fiend)') {
      this.additionalDamageOnHitDice = Dice.Create(1, 8)
      this.meleeWeaponOnly = true
      this.notes = '+1d8 dmg to smites'
      return true
    }

    if (name === 'Rapid Strike') {
      if (isSubclassFeature) return false
      this.extraAttackThisTurn = true
      this.forgoAdvantageNextAttack = true
      this.oncePerTurn = true
      this.weaponOnly = true
      this.notes = 'Forgo advantage next attack to gain extra attack'
      return true
    }

    if (name === 'Master Duelist') {
      if (isSubclassFeature) return false
      this.advantage = true
      this.weaponOnly = true
      this.usesLimitedResource = true
      this.notes = 'If you miss an attack, roll again with advantage'
      return true
    }

    if (name === 'Infectious Fury') {
      if (isSubclassFeature) return false

      this.usesLimitedResource = true
      this.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (name === 'Fighting Spirit') {
      if (isSubclassFeature) return false
      this.advantage = true
      this.usesLimitedResource = true
      this.notes = 'Gain advantage on attacks this turn'
      this.weaponOnly = true // TODO -assumption. Does this work for spell attacks?
      return true
    }

    if (name === 'Telekinetic Master') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.extraAttackThisTurn = true
      this.oncePerTurn = true
      this.spellOnly = true
      // TODO… add "concentrationSpellOnly"
      this.notes = 'Bonus action attack while concentrating on spell'
      return true
    }

    if (name === 'War Magic') {
      if (isSubclassFeature) return false
      this.cantripOnly = true
      this.notes = 'TODO'
      return true
    }

    if (name === 'Improved War Magic') {
      if (isSubclassFeature) return false
      // this.spellsOnly = true // TODO
      this.notes = 'TODO'
      return true
    }

    if (name === 'Action Surge') {
      if (isSubclassFeature) return false
      this.actionSurge = true
      return true
    }

    if (name === 'Arcane Firearm') {
      if (isSubclassFeature) return false
      this.spellOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1, 8)
      this.oncePerTurn = true
      this.notes = '+1d8 dmg to Artificer spells once per turn'
      return true
    }

    if (name === 'Sneak Attack') {
      if (isSubclassFeature) return false
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.sneakAttackDice = this.additionalDamageOnHitDice.copy()
      this.oncePerTurn = true
      this.weaponOnly = true
      this.finessOrRangedeWeaponOnly = true
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit with finesse or ranged weapon`
      return true
    }

    if (name === 'Hexblade’s Curse') {
      if (isSubclassFeature) return false
      this.expandedCrit = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus)
      this.notes = '+' + character.proficiencyBonus.toString() + ' (PB) dmg per hit, expanded crit range'
      this.usesLimitedResource = true
      this.attackRollOnly = true
      return true
    }

    if (name === 'Divine Smite') {
      if (isSubclassFeature) return false
      const level = action.upcastLevel ?? 1
      this.name = `Divine Smite (Level ${level})`
      this.usesLimitedResource = true
      this.additionalDamageOnHitDice = Dice.Create(2 + level - 1, 8)
      this.additionalDamageToFiendsAndUndeadDice = Dice.Create(1, 8) // TODO - create a sub-option for this
      this.meleeWeaponOnly = true
      return true
    }

    if (name === 'Dread Ambusher') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.extraAttackThisAction = true
      this.extraAttackFirstRoundDice = new Dice(action.dice)
      this.weaponOnly = true
      this.notes = 'Extra attack first round with +' + this.extraAttackFirstRoundDice!.diceString() + ' dmg'
      return true
    }

    if (name === 'Slayer’s Counter') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.extraAttackThisTurn = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Slayer’s Prey') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.weaponOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1, 6)
      return true
    }

    if (name === 'Gathered Swarm') {
      if (isSubclassFeature) return false
      const rangerLevel = character.classLevel(Class.RANGER)
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.Create(1, rangerLevel >= 11 ? 8 : 6)
      return true
    }

    if (name === 'Giant Killer' || name === 'Hoard Breaker') {
      if (isSubclassFeature) return false
      this.extraAttackThisTurn = true // TODO Later it's technically an extra reaction for giant killer
      this.oncePerTurn = true
      return true
    }

    if (name === 'Colossus Slayer') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.weaponOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1, 8)
      return true
    }

    if (name === 'Distant Strike') {
      if (isSubclassFeature) return false
      this.weaponOnly = true
      this.extraAttackThisAction = true
      return true
    }

    if (name === 'Planar Warrior') {
      if (isSubclassFeature) return false
      this.oncePerTurn = true
      this.weaponOnly = true
      const rangerLevel = character.classLevel(Class.RANGER)
      this.additionalDamageOnHitDice = Dice.Create(rangerLevel >= 11 ? 2 : 1, 8)
      return true
    }

    if (name === 'Psychic Blades') {
      // 2014 College of Whispers Bard, not the same as 2024 Soulknife Rogue skill
      const psychicBlades2024Id: number = 10292922
      const psychicBlades2024ClassId: number = 9414605
      const psychicBladess2024ClassId2: number = 9842722 // They added this later when they added a BA

      const actionId: number = Number(action.id)

      if (isSubclassFeature) return false
      if (actionId === psychicBlades2024Id) return false
      if (actionId === psychicBlades2024ClassId) return false
      if (actionId === psychicBladess2024ClassId2) return false

      this.weaponOnly = true
      this.oncePerTurn = true

      const bardLevel = character.classLevel(Class.BARD)
      let dieCount = 2
      if (bardLevel >= 15) {
        dieCount = 8
      } else if (bardLevel >= 10) {
        dieCount = 5
      } else if (bardLevel >= 5) {
        dieCount = 3
      }

      this.additionalDamageOnHitDice = Dice.Create(dieCount, 6)
      return true
    }

    if (name === 'Avenging Angel') {
      if (isSubclassFeature) return false
      this.advantage = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Invincible Conqueror') {
      if (isSubclassFeature) return false
      this.expandedCrit = true
      this.usesLimitedResource = true
      this.extraAttackThisAction = true
      this.notes = 'Expanded crit range, extra attack this action'
      return true
    }

    if (name === 'Symbiotic Entity') {
      if (isSubclassFeature) return false
      this.additionalDamageOnHitDice = Dice.Create(1, 6)
      this.usesLimitedResource = true
      this.meleeAttackOnly = true
      return true
    }

    if (name === 'Versatile Trickster') {
      if (isSubclassFeature) return false
      this.advantage = true
      this.weaponOnly = true
      // TODO - uses a bonus action
      return true
    }

    if (name === 'Mortal Bulwark') {
      if (isSubclassFeature) return false
      this.usesLimitedResource = true
      this.advantage = true
      this.notes = 'Advantage on attacks against aberrations, celestials, elementals, fey, and fiends'

      return true
    }

    if (name === 'Frenzy Attack') {
      if (isSubclassFeature) return false
      this.extraBonusActionAttackThisAction = true
      this.weaponOnly = true
      return true
    }

    this.logUnknownAction(action)
    return false
  }

  parseClassOption(action: Dictionary, character: Character): boolean {
    const name = this.name
    const id = action.id

    if (name === 'Primal Strike' && (action.id === 4496851 || action.id === '4496851')) {
      const druidLevel = character.classLevel(Class.DRUID)

      this.oncePerTurn = true
      this.attackRollOnly = true

      this.additionalDamageOnHitDice = Dice.Create(druidLevel >= 15 ? 2 : 1, 8) // feature ID 44968553 increases to 2d8
      this.notes = `+${this.additionalDamageOnHitDice.diceString()} dmg next hit with weapon or wildshape attack`
      return true
    }

    if (name === 'Potent Spellcasting' && (action.id === 4496847 || action.id === '4496847')) {
      // 2024 Cleric version
      const wisdomMod = character.modifierForAbility('wisdom')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      this.defaultEnabled = true
      this.cantripOnly = true
      this.notes = `+${wisdomMod} dmg to any Cleric cantrip`
      return true
    }

    if (name === 'Potent Spellcasting' && (action.id === 4496850 || action.id === '4496850')) {
      // 2024 Druid Version
      const wisdomMod = character.modifierForAbility('wisdom')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      this.defaultEnabled = true
      this.cantripOnly = true
      this.notes = `+${wisdomMod} dmg to next Druid cantrip`
      return true
    }

    if (this.name.startsWith("Genie's Wrath")) {
      this.attackRollOnly = true
      this.oncePerTurn = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus)
      this.onlyOneBeam = true
      return true
    }

    if (name === 'Agonizing Blast' && id === 133) {
      this.eldritchBlastOnly = true
      const chaMod = character.modifierForAbility('charisma')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      this.defaultEnabled = true
      this.notes = `+${chaMod} dmg to Eldritch Blast`
      this.name = `${this.name} (2014)`
      return true
    }

    if (name.startsWith('Agonizing Blast (')) {
      this.cantripOnly = true
      const chaMod = character.modifierForAbility('charisma')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      this.defaultEnabled = true
      this.notes = `+${chaMod} dmg to cantrip attacks`
      this.name = 'Agonizing Blast'
      return true
    }

    if (name === 'Lifedrinker' && id === 148) {
      const chaMod = character.modifierForAbility('charisma')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      this.defaultEnabled = true
      this.pactWeaponOnly = true
      this.notes = `+${chaMod} dmg to pact weapon attacks`
      this.name = `${this.name} (2014)`
      return true
    }

    if (this.name === 'Eldritch Smite') {
      this.attackRollOnly = true
      this.usesLimitedResource = true
      this.oncePerTurn = true
      this.pactWeaponOnly = true
      this.additionalDamageOnHitDice = Dice.Create(1 + character.warlockSpellLevel(), 8)
      return true
    }

    this.logUnknownAction(action)

    return false
  }

  parseClassFeature(action: Dictionary, character: Character): boolean {
    if (
      this.name.startsWith('Metamagic -') || // 2014
      this.name.startsWith('Metamagic:') // 2024
    ) {
      if (this.parseMetamagicFeature()) {
        return true
      }

      this.logUnknownAction(action)
      return false
    }

    if (this.name.startsWith('Maneuvers:')) {
      if (this.parseManeuverFeature(action, character)) {
        return true
      }
      this.logUnknownAction(action)
      return false
    }

    return this.parseRemainingClassFeatures(action, character)
  }

  parseFightingStyle(action: Dictionary, character: Character): boolean {
    const name = this.name
    this.weaponOnly = true
    this.defaultEnabled = true

    if (name === 'Great Weapon Fighting') {
      this.meleeWeaponOnly = true
      this.twoHandedOrVersatileOnly = true

      if (action.id === 1789148) {
        // 2024 version
        this.minimumDamageDieRoll = 3
        this.notes = 'Treat 1 or 2 as 3 for 2H/versatile weapons'
      } else {
        this.rerollDamageDiceThreshold = 2

        this.notes = 'Reroll 1 or 2 dmg for 2H/versatile weapons'
        this.name = `${this.name} (2014)`
      }
      return true
    }

    if (name === 'Unarmed Fighting') {
      this.unarmedOnly = true
      this.defaultEnabled = true
      const hasShieldEquipped = character.hasShieldEquipped
      const hasWeaponsEquipped = character.weapons.length > 0
      const strMod = character.modifierForAbility('strength')
      const dieSize = hasShieldEquipped || hasWeaponsEquipped ? 6 : 8
      this.additionalDamageOnHitDice = Dice.Create(1, dieSize, -1)
      this.notes = `Unarmed strikes deal 1d${dieSize} + ${strMod}`

      return true
    }

    if (name === 'Archery') {
      this.rangedWeaponOnly = true
      this.additionalToHitDice = Dice.flatAmountDie(2)
      return true
    }

    if (name === 'Dueling') {
      this.meleeWeaponOnly = true
      this.singleWieldingOnly = true
      this.additionalDamageOnHitDice = Dice.flatAmountDie(2)
      this.notes = '+2 dmg per hit for single one-handed weapon'
      return true
    }

    if (name === 'Thrown Weapon Fighting') {
      this.additionalDamageOnHitDice = Dice.flatAmountDie(2)
      this.thrownWeaponOnly = true
      if (action.id !== 1789205 && action.id !== '1789205') {
        this.name = `${this.name} (2014)`
      }
      return true
    }

    if (name === 'Two-Weapon Fighting') {
      this.offHandOnly = true
      this.addWeaponStatModifier = true
      this.lightWeaponOnly = true
      this.notes = 'Add ability modifier to off-hand attack'
      return true
    }

    this.logUnknownAction(action)
    return false
  }

  parseRacialTrait(action: Dictionary, character: Character): boolean {
    const name = this.name
    this.defaultEnabled = true

    // 2024 Racial Actions & Traits

    if (name === 'Frost’s Chill (Frost Giant)') {
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.oncePerTurn = true
      this.attackRollOnly = true
      return true
    }

    if (name === 'Fire’s Burn (Fire Giant)') {
      this.additionalDamageOnHitDice = new Dice(action.dice)
      this.oncePerTurn = true
      this.attackRollOnly = true
      return true
    }

    if (name === 'Hill’s Tumble (Hill Giant)') {
      this.attackRollOnly = true
      this.nextAttacksHaveAdvantage = true
      this.notes = 'Give target prone condition on attack roll'

      return true
    }

    if (name === 'Luck') {
      this.rerollToHit = true
      this.defaultEnabled = true
      return true
    }

    // 2014 Racial Traits
    if (name === 'Surprise Attack' && this.id === 8429380) {
      this.attackRollOnly = true
      this.additionalDamageOnHitDice = Dice.Create(2, 6)
      this.usesLimitedResource = true
      this.notes = '+2d6 dmg to next attack on surprised enemy'
      return true
    }

    if (name === 'Celestial Revelation' && (action.id === 8429359 || action.id === '8429359')) {
      // 8429359 is the 2014 version, which does Radiant Soul

      this.name = 'Radiant Soul'
      this.fireOrRadiantDamageOnly = true
      this.spellOnly = true
      const chaMod = character.modifierForAbility('charisma')
      this.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)

      this.notes = `+${chaMod} dmg to fire or radiant spells`
      return true
    }

    if (name === 'Fury of the Small') {
      this.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus)
      this.oncePerTurn = true
      this.weaponOnly = true
      return true
    }

    if (name === 'Savage Attacks') {
      this.additionalDamageDiceOnCrit = 1
      this.meleeWeaponOnly = true
      return true
    }

    if (name === 'Sunlight Sensitivity') {
      this.disadvantage = true
      this.weaponOnly = true
      this.defaultEnabled = false
      return true
    }

    if (name === 'Draconic Cry') {
      this.advantage = true
      this.attackRollOnly = true
      this.usesLimitedResource = true
      this.defaultEnabled = false
      return true
    }

    if (name === 'Goring Rush') {
      this.convertActionToBonusAction = true
      this.hornsOnly = true
      this.weaponOnly = true
      this.notes = 'Bonus action Horns attack after Dash action'
      return true
    }

    if (!NON_BUFF_RACIAL_TRAITS.includes(this.name)) {
      this.logUnknownAction(action)
    }
    return false
  }

  parseFeat(action: Dictionary, character: Character): boolean {
    const name = this.name

    // 2024
    if ((name === 'Grappler' && action.id) === 1789147) {
      this.meleeAttackOnly = true
      this.nextAttacksHaveAdvantage = true
      this.notes = 'Unarmed attack can both damage and grapple'
      return true
    }

    if (name === 'Mounted Combatant') {
      this.weaponOnly = true
      this.advantage = true
      this.notes = 'Advantage on attack rolls against unmounted creatures'
      this.name = 'Mounted Strike'
      return true
    }

    if (name === 'Mounted Strike') {
      return true
    }

    if (name === 'Tavern Brawler' && (action.id === 1789202 || action.id === '1789202')) {
      // Check the ID to make sure we don't apply this to 2014 Tavern Brawler
      this.unarmedOnly = true
      this.rerollDamageDiceThreshold = 1
      this.defaultEnabled = true
      this.notes = 'Reroll unarmed strike damage dice'
      return true
    }

    // 2014

    if (name === 'Piercer') {
      this.rerollAllDamageDiceOnHit = true
      this.oncePerTurn = true
      this.weaponOnly = true
      this.defaultEnabled = true

      if (action.id !== 1789175 && action.id !== '1789175') {
        this.name = `${this.name} (2014)`
      }
      this.piercingDamageOnly = true

      return true
    }

    if (name === 'Elemental Adept') {
      if (action.option === undefined) {
        return false
      }
      const elementalType = action.option.name
      if (elementalType === undefined) {
        // If they chose the feat but not the element yet
        return false
      }
      this.elementalAdeptDamageType = elementalType.toLowerCase()
      this.spellOnly = true
      this.defaultEnabled = true
      this.name = `${this.name} (${elementalType})`
      this.minimumDamageDieRoll = 2

      return true
    }

    if (name.startsWith('Elemental Adept (')) {
      const match = name.match(/\((\w+)\)/)
      if (match) {
        this.elementalAdeptDamageType = match[1].toLowerCase()
        this.spellOnly = true
        this.defaultEnabled = true
        this.minimumDamageDieRoll = 2
        this.name = `${this.name} (2014)`
        return true
      }
    }

    if (name === 'Savage Attacker') {
      this.defaultEnabled = true
      this.rerollAllDamageDiceOnHit = true
      this.oncePerTurn = true
      this.meleeWeaponOnly = true
      if (action.id !== 1789183 && action.id !== '1789183') {
        this.name = `${this.name} (2014)`
      }
      return true
    }

    if (name === 'Flames of Phlegethos') {
      this.rerollDamageDiceThreshold = 1
      this.spellOnly = true
      this.fireDamageOnly = true
      this.defaultEnabled = true
      this.notes = 'Reroll 1s on fire spell damage'
      this.name = `${this.name} (2014)`
      return true
    }

    if (name === 'Great Weapon Master') {
      this.meleeWeaponOnly = true
      this.heavyWeaponOnly = true
      if (action.id === 1789149 || action.id === '1789149') {
        // 2024 version
        this.defaultEnabled = true
        this.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus)
      } else {
        this.additionalToHitDice = Dice.flatAmountDie(-5)
        this.additionalDamageOnHitDice = Dice.flatAmountDie(10)

        this.notes = '-5 to hit, +10 dmg with heavy weapon'
        this.name = `${this.name} (2014)`
      }
      return true
    }

    if (name === 'Sharpshooter' && (action.id === 42 || action.id === '42')) {
      this.additionalToHitDice = Dice.flatAmountDie(-5)
      this.additionalDamageOnHitDice = Dice.flatAmountDie(10)
      this.rangedWeaponOnly = true
      this.notes = '-5 to hit, +10 dmg with ranged weapon'
      this.name = `${this.name} (2014)`
      return true
    }

    if (name === 'Elven Accuracy') {
      this.attackRollOnly = true
      this.rerollOnAdvantage = true
      this.defaultEnabled = true
      this.notes = 'When you have advantage, you can reroll one die'
      this.name = `${this.name} (2014)`
      return true
    }

    if (name === 'Lucky') {
      // TODO can only be used 3 times a day
      this.advantage = true
      this.usesLimitedResource = true
      if (action.id !== 1789160 && action.id !== '1789160') {
        this.name = `${this.name} (2014)`
      }
      return true
    }

    if (name === 'Squire of Solamnia') {
      this.name = 'Squire of Solamnia: Precise Strike'
      this.oncePerTurn = true
      this.weaponOnly = true
      this.advantage = true
      this.usesLimitedResource = true
      this.additionalToHitDice = Dice.Create(1, 8)
      this.notes = 'Next weapon attack is with advantage and +1d8 dmg'
      this.name = `${this.name} (2014)`
      return true
    }

    if (name === 'Charger') {
      this.meleeAttackOnly = true
      this.oncePerTurn = true

      if (action.id === 1789121 || action.id === '1789121') {
        // 2024 version
        this.additionalDamageOnHitDice = Dice.Create(1, 8)
      } else {
        this.additionalDamageOnHitDice = Dice.flatAmountDie(5)
        this.name = `${this.name} (2014)`
      }

      return true
    }
    return false
  }

  parseAction(action: Dictionary, character: Character): boolean {
    switch (this.featureSource) {
      case FeatureSource.Effect:
        return this.parseExternalEffects(action, character)
      case FeatureSource.Spell:
        return this.parseSpellEffect(action, character)
      case FeatureSource.Feat:
        return this.parseFeat(action, character)
      case FeatureSource.RacialTrait:
        return this.parseRacialTrait(action, character)
      case FeatureSource.FightingStyle:
        return this.parseFightingStyle(action, character)
      case FeatureSource.Class:
        return this.parseClassFeature(action, character)
      case FeatureSource.Item:
        return this.parseItemEffect(action)
      case FeatureSource.ClassOption:
        return this.parseClassOption(action, character)
      default:
        console.error('Unknown feature source: ' + this.featureSource)
        this.logUnknownAction(action)
    }

    return false
  }

  logUnknownAction(action: Dictionary) {
    if (Utility.isDevelopment) {
      if (!NON_BUFF_ACTIONS.includes(this.name)) {
        console.log(
          `Unknown possible ${featureSourceNames[this.featureSource]}: **${this.name}**. ${action.description}`
        )
        console.log(action)
      }
    }
  }

  constructor(action: Dictionary, character: Character, featureSource: FeatureSource) {
    this.name = action.name
    this.activation = action.activation
    this.id = action.id
    this.requiresConcentration = action.requiresConcentration
    this.snippet = action.snippet
    this.featureSource = featureSource

    this.isBuff = this.parseAction(action, character)
  }
}
