import { Dictionary } from '../Common/Types'

export enum ActivationType {
  ACTION = 1,
  BONUS_ACTION = 3,
  REACTION = 4
}

export class Activation {
  activationType: ActivationType

  constructor(activation: Dictionary) {
    this.activationType = activation.activationType
  }

  static Action() {
    return new Activation({ activationType: ActivationType.ACTION })
  }

  static BonusAction() {
    return new Activation({ activationType: ActivationType.BONUS_ACTION })
  }

  static Reaction() {
    return new Activation({ activationType: ActivationType.REACTION })
  }

  static None() {
    return new Activation({ activationType: 0 })
  }

  usesAction(): boolean {
    return this.activationType === ActivationType.ACTION
  }

  usesBonusAction(): boolean {
    return this.activationType === ActivationType.BONUS_ACTION
  }

  usesReaction(): boolean {
    return this.activationType === ActivationType.REACTION
  }

  activationTypeString(): string {
    if (this.usesAction()) {
      return 'Action'
    }
    if (this.usesBonusAction()) {
      return 'Bonus Action'
    }
    if (this.usesReaction()) {
      return 'Reaction'
    }
    return 'None'
  }
}
