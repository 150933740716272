import { CharacterInfo } from '../DDB/CharacterInfo'
import { Feature } from '../DDB/Feature'
import { TurnAction } from '../DDB/TurnAction'
import { ActionLevelMap, AttackActionInterface, NumberMap, CheckMap } from '../Common/Interfaces'

import { calculateAverageDamageForTurns } from '../Data/ChartData'
import { URLUtility } from '../Common/Utility'
import { TurnActionEngine } from '../DDB/TurnActionEngine'
import { ActivationType } from '../DDB/Activation'

export class TurnEngine {
  cid: number
  checkedFeatures: CheckMap
  targetAC: number
  actionIdList: ActionLevelMap[]
  advantageOverrides: NumberMap
  turnActions: TurnAction[]
  bonusTurnActions: TurnAction[]
  reactionTurnActions: TurnAction[]
  allTurnActions: TurnAction[]
  elvenAccuracy: boolean
  actionRequiresSave: boolean
  bonusActionRequiresSave: boolean
  reactionRequireSave: boolean
  anyActionRequiresSave: boolean

  totalAttackCount: number
  totalBonusActionAttackCount: number
  averageDamageMaps: NumberMap[] = [new NumberMap(), new NumberMap(), new NumberMap(), new NumberMap()]

  constructor(
    character: CharacterInfo,
    checkedFeatures: CheckMap,
    actionIdList: ActionLevelMap[],
    advantageOverrides: NumberMap,
    acs: number[],
    targetAC: number
  ) {
    this.targetAC = targetAC
    this.checkedFeatures = checkedFeatures
    this.actionIdList = actionIdList
    this.advantageOverrides = advantageOverrides
    this.cid = character.id

    const features = character.allFeatures.filter((feature: Feature) => checkedFeatures[feature.id])
    const allActions = this.getCharacterActionsFromIDs(character, actionIdList)

    const actions = allActions.filter((action) => action.activation.activationType === ActivationType.ACTION)
    const bonusActions = allActions.filter((action) => action.activation.activationType === ActivationType.BONUS_ACTION)
    const reactions = allActions.filter((action) => action.activation.activationType === ActivationType.REACTION)

    this.totalAttackCount = this.totalMeleeActionCount(character, features)
    this.totalBonusActionAttackCount = 1 + features.filter((feature) => feature.extraBonusActionAttackThisAction).length
    this.actionRequiresSave = actions.length > 0 && actions[0].attributes.requiresSavingThrow
    this.bonusActionRequiresSave = bonusActions.length > 0 && bonusActions[0].attributes.requiresSavingThrow
    this.reactionRequireSave = reactions.length > 0 && reactions[0].attributes.requiresSavingThrow
    this.anyActionRequiresSave = this.actionRequiresSave || this.bonusActionRequiresSave || this.reactionRequireSave
    this.turnActions = this.createTurnsFromActions(actions)
    this.bonusTurnActions = this.createTurnsFromActions(bonusActions)
    this.reactionTurnActions = this.createTurnsFromActions(reactions)
    this.allTurnActions = [this.turnActions, this.bonusTurnActions, this.reactionTurnActions].flat()

    const turnActionEngine: TurnActionEngine = new TurnActionEngine()
    turnActionEngine.applyFeaturesToTurnActions(features, this.allTurnActions, character)
    this.elvenAccuracy = features.some((thisFeature) => thisFeature.rerollOnAdvantage)

    // Once this is done, some actions may have converted to bonus actions (Heightened Spell for example)
    const newBonusTurnActions = this.allTurnActions.filter(
      (turnAction) => turnAction.activation?.activationType === ActivationType.BONUS_ACTION
    )

    if (newBonusTurnActions.length > 0) {
      for (const turn of newBonusTurnActions) {
        const index = this.turnActions.findIndex((action) => action.id === turn.id)
        if (index !== -1) {
          this.turnActions.splice(index, 1)
          this.bonusTurnActions.push(turn)
        }
      }

      // We also have to renumber all of the actions and bonus actions. This is clumsy.
      let attackNumber = 1
      for (const turn of this.turnActions) {
        turn.attackNumber = attackNumber++
      }

      attackNumber = 1
      for (const turn of this.bonusTurnActions) {
        turn.attackNumber = attackNumber++
      }
    }

    const start = performance.now()
    for (const turn of this.allTurnActions) {
      let { advantage, disadvantage } = turn
      const overriddenAdvantage = this.advantageOverrides[turn.id]
      if (overriddenAdvantage !== undefined) {
        if (overriddenAdvantage >= 0) {
          advantage = overriddenAdvantage
        } else if (overriddenAdvantage < 0) {
          disadvantage = true
        }
      }
      turn.asSpecifiedDamageData = turn.calculateAverageDamageArray(acs, advantage, disadvantage)
      turn.extendedNormalDamageData = turn.calculateAverageDamageArray(acs, 0, false)
      turn.extendedDisadvantageDamageData = turn.calculateAverageDamageArray(acs, 0, true)
      turn.extendedAdvantageDamageData = turn.calculateAverageDamageArray(acs, this.elvenAccuracy ? 2 : 1, false)

      turn.extendedDamageDataACs = acs
    }

    this.averageDamageMaps = calculateAverageDamageForTurns(this.allTurnActions, acs)

    const end = performance.now()
    if (end - start > 25) {
      console.warn(`Damage data calculations took too long: ${end - start}ms`)
    }

    if (this.turnActions.length === 0 || this.turnActions[0].isWeapon() || this.turnActions[0].isUnarmed()) {
      while (this.turnActions.length < this.totalAttackCount) {
        this.turnActions.push(new TurnAction(this.turnActions.length + 1))
      }
    }

    if (this.bonusTurnActions.length > 0) {
      while (this.bonusTurnActions.length < this.totalBonusActionAttackCount) {
        this.bonusTurnActions.push(new TurnAction(this.bonusTurnActions.length + 1))
      }
    }
  }

  shareableURL(): string {
    return URLUtility.shareURLForParams(this.cid, this.urlSearchParams())
  }

  private urlSearchParams(): URLSearchParams {
    const featureNumbers = Object.keys(this.checkedFeatures).filter(
      (key: string) => this.checkedFeatures[Number(key)] === true
    )
    const actionIdListObject = this.actionIdList.map((map) => [map.actionId, map.level])

    const jsonDictionary = {
      features: featureNumbers.join(','),
      actions: actionIdListObject.toString(),
      overrides: JSON.stringify(this.advantageOverrides),
      ac: String(this.targetAC)
    }
    return new URLSearchParams(jsonDictionary)
  }

  getCharacterActionsFromIDs(character: CharacterInfo, actionIdList: ActionLevelMap[]): AttackActionInterface[] {
    const actions: AttackActionInterface[] = []
    const fullList = character.attacks.concat(character.bonusAttacks).concat(character.spells) // TODO… reactions

    for (const actionIdMap of actionIdList) {
      for (const attackAction of fullList) {
        const attrId = Number(attackAction.attributes.id)
        const actionId = Number(actionIdMap.actionId)

        if (attrId === actionId) {
          const newAttackAction = attackAction.copy()
          newAttackAction.turnLevel = actionIdMap.level
          actions.push(newAttackAction)
        }
      }
    }
    return actions
  }

  totalMeleeActionCount(character: CharacterInfo, features: Feature[]) {
    const attackCount = character.attackCount
    const actionSurge: boolean = features.some((feature) => feature.actionSurge)
    const extraAttacksThisTurn: number = features.filter((feature) => feature.extraAttackThisTurn).length
    const extraAttacksThisAction: number = features.filter((feature) => feature.extraAttackThisAction).length

    const extraTurn: boolean = features.some((feature) => feature.extraTurn)

    let totalAttackCount = attackCount + extraAttacksThisAction
    if (actionSurge) {
      totalAttackCount *= 2
    }
    totalAttackCount += extraAttacksThisTurn

    if (extraTurn) {
      totalAttackCount *= 2
    }
    return totalAttackCount
  }

  createTurnsFromActions(actions: AttackActionInterface[]): TurnAction[] {
    const turnActions: TurnAction[] = []
    for (let i = 0; i < actions.length; i++) {
      const turnAction = new TurnAction(i + 1, actions[i])
      turnActions.push(turnAction)
    }

    if (turnActions.length) {
      return turnActions
    }

    return []
  }
}
