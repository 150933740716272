import { AttackAction } from '../DDB/AttackAction'
import { Activation } from '../DDB/Activation'
import { Dice } from './Dice'
import { Range } from '../DDB/Range'
import { AttackType } from '../Common/Constants'
import { Dictionary } from '../Common/Types'
import { Character } from './Character'

export enum CreatureType {
  WildShape = 1,
  Familiar = 2,
  BeastCompanion = 3,
  Mount = 4,
  Pet = 5,
  Summon = 6,
  Misc = 7,
  Unknown1 = 8,
  Unknown2 = 9,
  SteelDefender = 10,
  Sidekick = 11,
  Infusion = 12
}

export class Creature {
  id: number // id
  name: string // name
  race: string // definition.name
  avatarURL: string
  largeAvatarURL: string
  creatureType: CreatureType
  attacks: AttackAction[] = []
  owner: Character

  constructor(data: Dictionary, character: Character) {
    const { definition } = data
    this.id = data.id
    this.name = data.name
    this.creatureType = parseInt(data.groupId)
    this.owner = character
    this.race = definition.name
    if (!this.name) {
      this.name = this.race
    }

    this.avatarURL = definition.avatarURL
    this.largeAvatarURL = definition.largeAvatarURL
    const actionsDescription = definition.actionsDescription

    const attackChunks: string[] = actionsDescription.split('<p>')
    for (const chunk of attackChunks) {
      const creatureAttack = this.lookForMatches(chunk)

      if (creatureAttack) {
        const { name, toHit, range, longRange, diceCount, diceValue, fixedValue } = creatureAttack
        const dice = Dice.Create(diceCount, diceValue, fixedValue)
        const attributes: Dictionary = {
          range: Range.makeWeaponRange(range, longRange),
          type: AttackType.COMPANION,
          creatureType: this.creatureType
        }
        const activation = this.creatureType === CreatureType.WildShape ? Activation.Action() : Activation.BonusAction()

        const attackAction = new AttackAction(
          this.id + data.definition.id + this.attacks.length,
          `${this.name}: ${name}`,
          toHit,
          dice,
          attributes,
          activation
        )

        this.attacks.push(attackAction)
      }
    }
  }

  lookForMatches(chunk: string): CreatureAttack | null {
    const text = chunk
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .trim()
    if (text.length === 0) {
      return null
    }

    const patterns = [
      {
        regex:
          /(\b.+?)\.[^+]+(\+\d+)( to hit)?, \w+ (\d+\/\d+|\d+) ft.+?(?:(\d+)?d?(\d+)?(?: \+ (\d+))?)?\) (\w+) damage/,
        indices: [1, 2, 4, 5, 6, 7]
        // name, toHit, range, diceCount, diceValue, fixedValue
      },
      {
        // One target is from some legacy creatures (tressym)
        regex: /(\b.+?)\.[^+]+(\+\d+)( to hit)?, \w+ (\d+\/\d+|\d+) ft.(, one target.)? Hit: (\d+) (\w+)/,
        indices: [1, 2, 4, -1, -1, 6]
      }
    ]

    for (const pattern of patterns) {
      const matches = text.match(pattern.regex)
      if (matches) {
        return this.smartMatch(matches, pattern.indices)
      }
    }

    if (!text.includes('Multiattack')) {
      console.warn(`Could not match ${this.name}'s attack [${text}] for ${this.owner.name}`)
    }

    return null
  }

  smartMatch(matches: RegExpMatchArray, indices: number[]): CreatureAttack {
    const rawRange = matches[indices[2]]
    let range: number = Number(rawRange)
    let longRange = 0

    const matchedRange: string[] = String(rawRange).split('/')
    if (matchedRange.length === 2) {
      range = Number(matchedRange[0])
      longRange = Number(matchedRange[1])
    }

    return {
      name: matches[indices[0]],
      toHit: Number(matches[indices[1]]),
      range: Number(range),
      longRange: Number(longRange),
      diceCount: indices[3] === -1 ? 0 : Number(matches[indices[3]]) || 0,
      diceValue: indices[4] === -1 ? 0 : Number(matches[indices[4]]) || 0,
      fixedValue: Number(matches[indices[5]]) || 0
    }
  }
}

interface CreatureAttack {
  name: string
  toHit: number
  range: number
  longRange?: number
  diceCount: number
  diceValue: number
  fixedValue: number
}
