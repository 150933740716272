import React from 'react'
import { Avatar, Box, ButtonGroup, Card, CardBody, CardHeader, Flex, Heading, HStack } from '@chakra-ui/react'
import { Image, Text, VStack, Spacer, useBreakpointValue, Divider } from '@chakra-ui/react'
import { CharacterInfo } from '../DDB/CharacterInfo'
import { CopyDataButton, LinkButton, ReloadButton, ShareButton } from '../Common/UIComponents'
import { Dictionary, Dispatcher } from '../Common/Types'
import { TurnEngine } from '../Data/TurnEngine'
import { sumDamageArrays } from '../Data/ChartData'
import { useEffect, useState } from 'react'
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react'
import { Utility } from '../Common/Utility'

export function CharacterHeader({
  character,
  reload,
  turnEngine,
  targetAC,
  setTargetAC,
  acs,
  setReload,
  testData
}: {
  character: CharacterInfo
  reload: boolean
  turnEngine: TurnEngine
  targetAC: number
  setTargetAC: Dispatcher<number>
  acs: number[]
  setReload: Dispatcher<boolean>
  testData: Dictionary
}) {
  const isSmall = useBreakpointValue({ base: 'sm', lg: 'md' }) === 'sm'
  const height = isSmall ? 175 : 250
  return (
    <Box alignSelf="center">
      <Card direction={{ base: 'row', sm: 'row' }} overflow="hidden" variant="unstyled" borderRadius={'xl'}>
        <CardHeader>
          {!isSmall && character.avatarUrl && (
            <VStack>
              <Image
                objectFit="cover"
                maxW="100%"
                maxH={`${height}px`}
                src={character.avatarUrl}
                alt={character.name}
              />
            </VStack>
          )}
        </CardHeader>

        <CardBody ps={4} pe={2} py={2}>
          <VStack align={'left'} height="100%">
            <HStack width={'100%'}>
              {isSmall && character.avatarUrl && <Avatar size="lg" src={character.avatarUrl} />}
              <VStack align={'left'} height="100%" width="100%">
                <NameRow
                  character={character}
                  reload={reload}
                  turnEngine={turnEngine}
                  testData={testData}
                  handleReloadButtonClick={() => {
                    setReload(true)
                  }}
                />
                <ClassRow character={character} />
                <StatsRow character={character} />
              </VStack>
            </HStack>
            <Divider pt={2} borderColor="brand" />

            {!turnEngine.anyActionRequiresSave && (
              <ACDPRRow
                character={character}
                turnEngine={turnEngine}
                acs={acs}
                targetAC={targetAC}
                setTargetAC={setTargetAC}
              />
            )}
            {turnEngine.anyActionRequiresSave && (
              <SaveDPRRow character={character} turnEngine={turnEngine} targetAC={targetAC} />
            )}
          </VStack>
        </CardBody>
      </Card>
    </Box>
  )
}

function ACDPRRow({
  character,
  turnEngine,
  acs,
  targetAC,
  setTargetAC
}: {
  character: CharacterInfo
  turnEngine: TurnEngine
  acs: number[]
  targetAC: number
  setTargetAC: Dispatcher<number>
}) {
  const [value, setValue] = useState(targetAC.toString())
  useEffect(() => {
    setValue(targetAC.toString())
  }, [targetAC])

  let allTurns = turnEngine.allTurnActions
  const damageTotals = sumDamageArrays(allTurns, [targetAC])

  const thiefsReflexesID = '9414617'
  const doubleDamage = turnEngine.checkedFeatures[thiefsReflexesID] === true

  if (doubleDamage) {
    allTurns = [...allTurns, ...allTurns]
  }

  const baseDiceTotal = character.totalDamageStringForTurns(allTurns)
  const critDiceTotal = character.totalCritDiceStringForTurns(allTurns)

  const minAC: number = acs[0]
  const maxAC: number = acs[acs.length - 1]

  const handleValueChange = (newValue: string) => {
    // You can add your validation or processing logic here
    if (newValue === NaN.toString()) {
      newValue = '10'
    }

    setValue(newValue)
    const newValueNumber = parseInt(newValue)
    if (minAC <= newValueNumber && newValueNumber <= maxAC) {
      setTargetAC(newValueNumber)
    }
  }

  const valueToACString = (value: string, delta: number): number => {
    const newValue = parseInt(value) + delta
    return Math.min(Math.max(newValue, minAC), maxAC)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // TODDO this can be simplifed (but test it)
    if (event.key === 'ArrowUp') {
      const newValue = valueToACString(value, 1)
      setValue(() => newValue.toString())
      setTargetAC(newValue)
      event.preventDefault()
    } else if (event.key === 'ArrowDown') {
      const newValue = valueToACString(value, -1)
      setValue(() => newValue.toString())
      setTargetAC(newValue)
      event.preventDefault()
    }
  }

  const damageTotalNumber = (doubleDamage ? 2 : 1) * damageTotals[0]

  return (
    <VStack textAlign={'center'} spacing={0} height="100%">
      <Spacer />
      <Text fontSize="md">
        Average DPR vs AC{' '}
        <Editable
          value={value}
          fontSize={'md'}
          as="span"
          isPreviewFocusable={true}
          selectAllOnFocus={false}
          onSubmit={(newValue) => {
            const newValueNumber = parseInt(newValue)
            const clippedNumber = Math.min(Math.max(newValueNumber, minAC), maxAC)
            handleValueChange(clippedNumber.toString())
          }}
          onChange={(newValue) => {
            handleValueChange(newValue)
          }}
        >
          <EditablePreview
            fontSize="md"
            background={'gray.700'}
            py={0}
            width={8}
            _hover={{
              background: 'gray.600'
            }}
          />

          <EditableInput py={0} width={8} onKeyDown={handleKeyDown} />
        </Editable>
      </Text>
      <Text fontWeight={'bold'} fontSize={'3xl'}>
        {damageTotalNumber.toFixed(2)}
      </Text>
      {baseDiceTotal !== '0' && <Text fontSize={'sm'}>{`Total dice: ${baseDiceTotal}`}</Text>}
      {critDiceTotal !== '0' && critDiceTotal !== baseDiceTotal && (
        <Text fontSize={'sm'}>{`Crit dice: ${critDiceTotal}`}</Text>
      )}
      <Spacer />
    </VStack>
  )
}

function SaveDPRRow({
  character,
  turnEngine,
  targetAC
}: {
  character: CharacterInfo
  turnEngine: TurnEngine
  targetAC: number
}) {
  const allTurns = turnEngine.allTurnActions
  const damageTotals = sumDamageArrays(allTurns, [targetAC])

  const baseDiceTotal = character.totalDamageStringForTurns(allTurns)
  const critDiceTotal = character.totalCritDiceStringForTurns(allTurns)

  const save = targetAC - 10
  const saveString = save < 0 ? `${save}` : `+${save}`
  return (
    <VStack textAlign={'center'} spacing={0}>
      <Text fontSize="md">{`Average DPR vs ${saveString} Save`}</Text>
      <Text fontWeight={'bold'} fontSize={'2xl'}>
        {damageTotals[0].toFixed(2)}
      </Text>
      {baseDiceTotal !== '0' && <Text fontSize={'sm'}>{`Total dice: ${baseDiceTotal}`}</Text>}
      {critDiceTotal !== '0' && critDiceTotal !== baseDiceTotal && (
        <Text fontSize={'sm'}>{`Crit dice: ${critDiceTotal}`}</Text>
      )}
    </VStack>
  )
}

function ClassRow({ character }: { character: CharacterInfo }) {
  const sortedClassNames = character.classNames.sort((a, b) => b[1] - a[1])
  const classNamesString = sortedClassNames
    .map((className: [string, number]) => `${className[0]} ${className[1]}`)
    .join(' / ')

  return (
    <Text fontSize="sm" mt={-2}>
      {`${character.race} ${classNamesString}`}
    </Text>
  )
}

function StatsRow({ character }: { character: CharacterInfo }) {
  const abilityScoresString = character.abilityScores.join(', ')
  return (
    <Text fontSize="sm" mt={-2}>
      Ability Scores: {abilityScoresString}
    </Text>
  )
}

function NameRow({
  character,
  reload,
  turnEngine,
  testData,
  handleReloadButtonClick
}: {
  character: CharacterInfo
  reload: boolean
  turnEngine: TurnEngine
  testData: Dictionary
  handleReloadButtonClick: () => void
}) {
  return (
    <Flex width="100%">
      <Heading size="xs">{character.name}</Heading>
      <Spacer />
      <HeaderButtonBar
        character={character}
        reload={reload}
        turnEngine={turnEngine}
        testData={testData}
        handleReloadButtonClick={handleReloadButtonClick}
      />
    </Flex>
  )
}

function HeaderButtonBar({
  character,
  reload,
  turnEngine,
  testData,
  handleReloadButtonClick
}: {
  character: CharacterInfo
  reload: boolean
  turnEngine: TurnEngine
  testData: Dictionary
  handleReloadButtonClick: () => void
}) {
  return (
    <ButtonGroup isAttached size="sm" variant="ghost" color={'white'}>
      <LinkButton url={character.url} />
      <ShareButton turnEngine={turnEngine} />
      <ReloadButton reloading={reload} onClick={handleReloadButtonClick} />
      {Utility.isDevelopment && <CopyDataButton data={testData} />}
    </ButtonGroup>
  )
}
