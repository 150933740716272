// React
import React from 'react'
import { useState } from 'react'

// Chakra-UI React Components
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, SimpleGrid } from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react'
import { VStack, HStack, Spacer, Box } from '@chakra-ui/react'
import { IconButton, Link } from '@chakra-ui/react'
import { useNumberInput } from '@chakra-ui/react'
import { Text, Checkbox, Flex, Badge, Tooltip, Tag } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

// Icons
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { IconHexagonLetterA, IconHexagonLetterD, IconHexagonLetterE } from '@tabler/icons-react'

// DPR
import { Feature, FeatureSource } from '../DDB/Feature'
import { sumDamageArrays } from '../Data/ChartData'
import { CreatureType } from '../DDB/Creature' // TOFDO Later abstract this out
import { WrappingTd, DamageCell, RangeText, TableActionButton, AccordionView } from '../Common/UIComponents'
import { TurnAction } from '../DDB/TurnAction'
import { CharacterHeader } from './CharacterHeader'
import { CharacterInfo } from '../DDB/CharacterInfo'
import { Dispatcher, Dictionary } from '../Common/Types'

import { AttackActionInterface, DamageData, ActionLevelMap, CheckMap, NumberMap } from '../Common/Interfaces'
import { DiamondIcon } from './CustomIcons'
import { TurnEngine } from '../Data/TurnEngine'
import { URLUtility, Utility } from '../Common/Utility'
import { DamageACChart } from './Charts'
import { DamagePercentilesChart } from './Charts'
import { calculateDamagesDataForAC } from '../Data/ChartData'
import { ActivationType } from '../DDB/Activation'

export const TABLE_HEADER_COLOR: string = 'gray.400'

// Character Sheet
export function CharacterSheet({
  character,
  reload,
  setReload,
  isPrimary
}: {
  character: CharacterInfo
  reload: boolean
  setReload: Dispatcher<boolean>
  isPrimary: boolean
}) {
  const logRenderPerformance = false
  let start = undefined
  let turnStart = undefined

  if (logRenderPerformance) {
    start = performance.now()
  }

  const featuresFromParams = isPrimary ? URLUtility.checkedFeaturesFromURLParams() : undefined
  const actionIdListFromParams = isPrimary ? URLUtility.actionIdListFromURLParams() : undefined
  const advantageOverridesFromParams = isPrimary ? URLUtility.advantageOverridesFromURLParams() : undefined

  let acFromParams = isPrimary ? URLUtility.acFromURLParams() : undefined

  const acs = Utility.range(5, 30)
  const minAC: number = acs[0]
  const maxAC: number = acs[acs.length - 1]

  if (acFromParams) {
    acFromParams = Math.min(Math.max(acFromParams, minAC), maxAC)
  }

  const [checkedFeatures, setCheckedFeatures] = useState<CheckMap>(
    featuresFromParams ? featuresFromParams : character.defaultFeatures
  )
  const [actionIdList, setActionIdList] = useState<ActionLevelMap[]>(
    actionIdListFromParams ? actionIdListFromParams : []
  )
  const [advantageOverrides, setaAvantageOverrides] = useState<NumberMap>(
    advantageOverridesFromParams ? advantageOverridesFromParams : new NumberMap()
  )

  const [targetAC, setTargetAC] = useState(acFromParams ? acFromParams : 10)

  if (logRenderPerformance) {
    turnStart = performance.now()
  }
  const turnEngine = new TurnEngine(character, checkedFeatures, actionIdList, advantageOverrides, acs, targetAC)

  if (logRenderPerformance) {
    console.log(`TurnEngine (${character.name}) creation time: ${performance.now() - turnStart!}ms`)
  }

  const { attacks, bonusAttacks, spells, allFeatures, fixedSpellLevel } = character

  const wildShapeAttacks = attacks.filter(
    (attack) => attack.attributes.type === 'Companion' && attack.attributes.creatureType === CreatureType.WildShape
  )
  const wildShapeIds = wildShapeAttacks.map((attack) => attack.id)
  const nonWildShapeAttacks = attacks.filter((attack) => !wildShapeIds.includes(attack.id))

  if (attacks.length !== wildShapeAttacks.length + nonWildShapeAttacks.length) {
    console.error('Attack count mismatch:', attacks.length, wildShapeAttacks.length, nonWildShapeAttacks.length)
  }

  const racialFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.RacialTrait)
  const classBuffFeatures = allFeatures.filter(
    (feature) => feature.featureSource === FeatureSource.Class && feature.isBuff
  )
  const spellFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Spell)
  const fightingStyleFeatures = allFeatures.filter(
    (feature) => feature.featureSource === FeatureSource.FightingStyle && feature.isBuff
  )

  const effectFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Effect)
  const featFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Feat)
  const itemFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Item)
  const classOptionFeatures = allFeatures.filter(
    (feature) => feature.featureSource === FeatureSource.ClassOption && feature.isBuff
  )
  const nonBuffFeatures = allFeatures.filter((feature) => !feature.isBuff)

  // Just a sanity check until this code is better
  const featureArrays = [
    classBuffFeatures,
    racialFeatures,
    fightingStyleFeatures,
    spellFeatures,
    effectFeatures,
    featFeatures,
    classOptionFeatures,
    itemFeatures,
    nonBuffFeatures
  ]

  const totalFeatureCount = featureArrays.reduce((total, features) => total + features.length, 0)
  if (totalFeatureCount !== allFeatures.length) {
    const featureNames = featureArrays.flat().map((feature) => feature.name)
    const misisngFeatures = allFeatures.filter((feature) => !featureNames.includes(feature.name))
    console.error(
      'Feature count mismatch. Missing: ',
      misisngFeatures.map((feature) => feature.name)
    )
  }
  // End sanity check

  // Attack table data
  const wildShapeTableTitle = 'Wildshape Attacks'
  const bonusActionTableTitle = 'Bonus Action Attacks'

  const attackTableTitle = character.attackCount > 1 ? `Attacks (${character.attackCount} per turn)` : 'Attacks'
  const attackMap = [
    { title: attackTableTitle, attackList: nonWildShapeAttacks, activationType: ActivationType.ACTION },
    { title: wildShapeTableTitle, attackList: wildShapeAttacks, activationType: ActivationType.ACTION },
    { title: bonusActionTableTitle, attackList: bonusAttacks, activationType: ActivationType.BONUS_ACTION }
  ]

  // Attack table data
  const featureSections = [
    { title: 'Class Features', features: classBuffFeatures },
    { title: 'Class Options', features: classOptionFeatures },
    { title: 'Feat Effects', features: featFeatures },
    { title: 'Item Effects', features: itemFeatures },
    { title: 'Spell Effects', features: spellFeatures },
    { title: 'Racial Traits', features: racialFeatures },
    { title: 'Fighting Styles', features: fightingStyleFeatures },
    { title: 'External Effects', features: effectFeatures }
  ]

  const padding = 2
  const showRange = true

  const allTurns = turnEngine.allTurnActions
  const elvenAccuracy = turnEngine.elvenAccuracy
  const damageData = calculateDamagesDataForAC(allTurns, acs, targetAC, elvenAccuracy)

  const testData = Utility.isDevelopment
    ? {
        cid: character.id,
        name: character.name,
        checkedFeatures,
        actionIdList,
        advantageOverrides,
        targetAC,
        elvenAccuracy,
        dpr: sumDamageArrays(allTurns, [targetAC])[0].toFixed(2)
      }
    : {}

  const accordionViewProps = { width: '100%', height: '250px' }
  const otherFeatureProps = { width: '100%' }
  const isSmall = false //useBreakpointValue({ base: 'sm', lg: 'md' }) == 'sm' // TODO this is too slow

  const statsAccordion = (
    <AccordionView title={'Additional Statistics'} {...accordionViewProps}>
      <DamagePercentilesChart
        damageData={damageData}
        chartAC={targetAC}
        requiresSave={turnEngine.anyActionRequiresSave}
        elvenAccuracy={elvenAccuracy}
      />
    </AccordionView>
  )

  const element = (
    <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={padding}>
      <Box>
        <CharacterHeader
          character={character}
          reload={reload}
          turnEngine={turnEngine}
          acs={acs}
          targetAC={targetAC}
          setTargetAC={setTargetAC}
          setReload={setReload}
          testData={testData}
        />
      </Box>
      <Box width="100%" maxH={250} bgColor={'bg.surface'}>
        <DamageACChart
          numberMaps={turnEngine.averageDamageMaps}
          elvenAccuracy={turnEngine.elvenAccuracy}
          chartAC={targetAC}
          setChartAC={setTargetAC}
          requiresSave={turnEngine.anyActionRequiresSave}
        />
      </Box>
      <Box>
        {isSmall && statsAccordion}
        <TableContainer width="100%">
          <Table variant="unstyled" size="sm">
            {attackMap.map(({ title, attackList, activationType }) => (
              <AttackTable
                key={title}
                title={title}
                attacks={attackList}
                actionIdList={actionIdList}
                setActionIdList={setActionIdList}
                activationType={activationType}
                showRange={showRange}
              />
            ))}
            <SpellTable
              spells={spells}
              fixedSpellLevel={fixedSpellLevel}
              actionIdList={actionIdList}
              setActionIdList={setActionIdList}
              highestLevelSlot={character.highestLevelSpellSlot}
              showRange={showRange}
            />
          </Table>
        </TableContainer>
      </Box>
      <Box p={0}>
        {!isSmall && statsAccordion}

        <TurnTable
          turnEngine={turnEngine}
          actionIdList={actionIdList}
          setActionIdList={setActionIdList}
          advantageOverrides={advantageOverrides}
          setAdvantageOverrides={setaAvantageOverrides}
          targetAC={targetAC}
        />
        <Table variant="unstyled" size="sm">
          {featureSections.map((section) => (
            <FeatureTableSection
              key={section.title}
              title={section.title}
              features={section.features}
              checkedFeatures={checkedFeatures}
              setCheckedFeatures={setCheckedFeatures}
            />
          ))}
        </Table>
        <AccordionView title={'Other Features'} {...otherFeatureProps}>
          <Table variant="unstyled" size="sm">
            <Tbody>
              {nonBuffFeatures.map((feature) => (
                <Tr key={feature.id}>
                  <Td colSpan={2}>
                    <FeatureBox feature={feature} ps={2} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccordionView>
      </Box>
    </SimpleGrid>
  )

  if (logRenderPerformance) {
    console.log(`CharacterSheet (${character.name}) render time: ${performance.now() - start!}ms`)
  }
  return element
}

function FeatureBox({ feature, ps }: { feature: Feature; ps: number }) {
  const name = feature.name

  const fontSize = 'sm'
  if (feature.requiresConcentration) {
    return (
      <Flex align="center">
        <Text fontSize={fontSize} pe={1}>
          {name}
        </Text>
        <DiamondIcon letter="C" />
      </Flex>
    )
  }

  return (
    <Text fontSize={fontSize} ps={ps}>
      {name}
    </Text>
  )
}

function ActionBox({
  name,
  attributes,
  level,
  showLevel,
  url
}: {
  name: string
  attributes: Dictionary
  level: number
  showLevel: boolean
  url?: string
}) {
  const { requiresConcentration, ritual, effectCountLabel, effectCountsForLevels } = attributes

  let effectCount = attributes.effectCount
  if (!attributes.isCantrip && effectCountsForLevels && effectCountsForLevels.length > 0) {
    effectCount = effectCountsForLevels[Math.max(0, level - 1)]
  }
  const isPactWeapon = attributes.isPactWeapon
  const weaponMastery = attributes.weaponMastery

  return (
    <HStack>
      <Box display="flex" alignItems="center">
        <VStack align="left">
          <Flex wrap="wrap" gap="1" alignItems="center">
            {url ? (
              <Link href={url} isExternal textDecoration="underline">
                {name}
              </Link>
            ) : (
              <Text textAlign="left">{name}</Text>
            )}

            {requiresConcentration && <DiamondIcon letter="C" />}
            {weaponMastery && <Text>★</Text>}

            {ritual && <DiamondIcon letter="R" />}
            {showLevel && (
              <Badge
                bgColor="brand"
                color="white"
                borderRadius="full"
                px={2}
                py={0}
                maxH={'20px'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                L{level}
              </Badge>
            )}
          </Flex>
          {effectCount > 1 && (
            <Text fontSize="xs" marginTop="-4px">
              {effectCountLabel}: {effectCount}
            </Text>
          )}
          {isPactWeapon && (
            <Text fontSize="xs" marginTop="-4px">
              Pact Weapon
            </Text>
          )}
        </VStack>
      </Box>
    </HStack>
  )
}

function TurnRow({
  turnAction,
  turnEngine,
  isValid,
  actionIdList,
  setActionIdList,
  advantageOverrides,
  setAdvantageOverrides,
  targetAC
}: {
  turnAction: TurnAction
  turnEngine: TurnEngine
  isValid: boolean
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  advantageOverrides: NumberMap
  setAdvantageOverrides: Dispatcher<NumberMap>
  targetAC: number
}) {
  const theme = useTheme()

  if (!turnAction) {
    return null
  }

  const level = turnAction.attackAction?.turnLevel

  const handleRemoveButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const [clickedId, level] = event.currentTarget.value.split(',')

    const index = actionIdList.findIndex(
      (item) => Number(item.actionId) === Number(clickedId) && Number(level) === Number(item.level)
    )
    const newList = [...actionIdList]
    if (index !== -1) {
      newList.splice(index, 1)
      setActionIdList(newList)
    } else {
      console.error(`Could not find clicked ID ${clickedId} level ${level}?`)
    }
  }

  const name = turnAction.name()
  const noOverrideValue = -100

  const toHit = turnAction.consolidatedToHitString()
  let advantage: number = turnAction.advantageType()

  let overridenAdvantage: boolean = false
  if (advantageOverrides[turnAction.id] !== undefined) {
    advantage = advantageOverrides[turnAction.id]
    overridenAdvantage = true
  }

  const elvenAccuracy = turnAction.isCompanion() ? false : turnEngine.elvenAccuracy
  const advIcon = <IconHexagonLetterA stroke={1} size={20} color={theme.colors.green[200]} />
  const disIcon = <IconHexagonLetterD stroke={1} size={20} color={theme.colors.red[200]} />
  const eadvIcon = <IconHexagonLetterE stroke={1} size={20} color={theme.colors.green[200]} />

  let advantageIcon = undefined
  if (advantage === 1) advantageIcon = advIcon
  else if (advantage === 2) advantageIcon = eadvIcon
  else if (advantage === -1) advantageIcon = disIcon

  const aTitle = `Advantage${elvenAccuracy ? ' (Elven Accuracy)' : ''}`
  const advValue = elvenAccuracy ? '2' : '1'

  const element = (
    <Flex alignItems={'center'}>
      {advantageIcon}
      <Text>
        {toHit}
        {overridenAdvantage ? '*' : ''}
      </Text>
      {advantageIcon && <Box width={1} />}
    </Flex>
  )

  let toHitElement = <Box />

  if (toHit) {
    if (!turnAction.isAttackRoll()) {
      toHitElement = <Box ps={2}>{element}</Box>
    } else {
      toHitElement = (
        <Menu isLazy>
          <MenuButton
            as={Button}
            variant="hoverButton"
            py={0}
            px={2}
            height={6}
            _hover={{
              bgColor: 'gray.600'
            }}
          >
            {element}
          </MenuButton>
          <MenuList minW="0">
            <MenuOptionGroup
              defaultValue={overridenAdvantage ? advantage.toString() : noOverrideValue.toString()}
              type="radio"
              onChange={(value) => {
                const newValue: number = Array.isArray(value) ? parseInt(value[0]) : parseInt(value)
                const newAdvantageOverrides = advantageOverrides.copy()
                if (newValue === noOverrideValue) {
                  delete newAdvantageOverrides[turnAction.id]
                  setAdvantageOverrides(newAdvantageOverrides)
                } else {
                  newAdvantageOverrides[turnAction.id] = newValue
                  setAdvantageOverrides(newAdvantageOverrides)
                }
              }}
            >
              <MenuItemOption value={noOverrideValue.toString()}>Default</MenuItemOption>
              <MenuItemOption value={advValue}>{aTitle}</MenuItemOption>
              <MenuItemOption value="0">Normal</MenuItemOption>
              <MenuItemOption value="-1">Disadvantage</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )
    }
  }

  const damage = turnAction.consolidatedDamageStringForLevel(Number(level) || 1, true)
  const rawDamage = turnAction.diceString()
  const attributes = turnAction.attributes()

  const damageData: DamageData[] = turnAction.asSpecifiedDamageDataForACs([targetAC])
  const id = attributes.id

  let button = <TableActionButton id={id} value={[id, level].join(',')} add={false} onClick={handleRemoveButtonClick} />

  let showLevel = false
  let turnLevel = 1
  if (name === '') {
    button = <Box width="24px" height="5px" />
  } else {
    if (turnAction.attackAction && turnAction.attackAction.turnLevel > 0) {
      turnLevel = turnAction.attackAction.turnLevel
      showLevel = true
    }
  }

  return (
    <Tr fontSize={'medium'}>
      <WrappingTd>
        <HStack>
          {turnAction.attackNumber && (
            <Tag size="sm" colorScheme={isValid ? 'white' : 'red'}>
              {turnAction.attackNumber}
            </Tag>
          )}

          {button}
          <ActionBox name={name} attributes={attributes} level={turnLevel} showLevel={showLevel} />
        </HStack>
      </WrappingTd>
      <Td>{toHitElement}</Td>
      <WrappingTd>
        <Tooltip label={rawDamage}>{damage}</Tooltip>
      </WrappingTd>
      <DamageCell damageData={damageData[0]} />
    </Tr>
  )
}

function AttackRow({
  attack,
  actionIdList,
  setActionIdList,
  level,
  showRange,
  url
}: {
  attack: AttackActionInterface
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  level: number
  showRange: boolean
  url?: string
}) {
  if (!attack) {
    return null
  }

  const { name, attributes } = attack
  const { range, id } = attributes
  const toHit = attack.attackModString()

  const toHitElement = toHit ? <Td>{toHit}</Td> : <Td />
  const damage = attack.diceStringForLevel(level)
  const spellLevel = attack.attributes.level
  const isCantrip = attack.attributes.isCantrip

  const handleAddButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const clickedId: number = parseInt(event.currentTarget.value)
    const newList: ActionLevelMap[] = [...actionIdList]
    const levelToAdd = isCantrip ? 0 : Math.max(level, spellLevel | 0)
    const newMap: ActionLevelMap = { actionId: clickedId, level: levelToAdd | 0 }
    newList.push(newMap)
    setActionIdList(newList)
  }

  return (
    <Tr fontSize={'medium'}>
      <Td whiteSpace="normal" wordBreak="break-word">
        <HStack>
          <TableActionButton id={id} value={id} add={true} onClick={handleAddButtonClick} />
          <ActionBox name={name} attributes={attributes} level={level} showLevel={false} url={url} />
        </HStack>
      </Td>
      {showRange && (
        <Td>
          <RangeText range={range} />
        </Td>
      )}
      {toHitElement}
      <Td>{damage}</Td>
    </Tr>
  )
}

function LevelStepper({
  level,
  levelControls,
  highestLevelSlot,
  onLevelChange
}: {
  level: number
  levelControls: boolean
  highestLevelSlot: number
  onLevelChange: Dispatcher<number>
}) {
  const { value, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    value: level.toString(),
    min: 1,
    max: highestLevelSlot,
    precision: 0,
    onChange: (valueAsString, valueAsNumber) => {
      onLevelChange(valueAsNumber)
    }
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()

  return (
    <HStack>
      <Badge
        color="white"
        bgColor="brand"
        borderRadius="full"
        px={0}
        py={0}
        maxH="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {levelControls && (
          <IconButton
            {...dec}
            color="white"
            aria-label="Decrement"
            size="xs"
            bg="transparent"
            icon={<ChevronLeftIcon fontSize={'large'} />}
          />
        )}
        <Text px={levelControls ? 0 : 4}>Level {value}</Text>
        {levelControls && (
          <IconButton
            {...inc}
            color="white"
            aria-label="Increment"
            size="xs"
            bg="transparent"
            icon={<ChevronRightIcon fontSize={'large'} />}
          />
        )}
      </Badge>
    </HStack>
  )
}

function SpellTable({
  spells,
  fixedSpellLevel,
  highestLevelSlot,
  actionIdList,
  setActionIdList,
  showRange
}: {
  spells: AttackActionInterface[]
  fixedSpellLevel: number
  highestLevelSlot: number
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  showRange: boolean
}) {
  const [level, setLevel] = useState(fixedSpellLevel || 1)

  const spellRows = spells
    .sort((a, b) => a.attributes.level - b.attributes.level)
    .map((spell: AttackActionInterface) => (
      <AttackRow
        key={spell.attributes.id}
        attack={spell}
        actionIdList={actionIdList}
        setActionIdList={setActionIdList}
        level={level}
        showRange={showRange}
        url={spell.attributes.ddbURL}
      />
    ))

  if (spells.length === 0) {
    return null
  }

  return (
    <Thead>
      <Tr textColor={TABLE_HEADER_COLOR}>
        <Th fontSize={'medium'} pt={4}>
          <HStack justifyContent="space-between">
            <Text>Spells</Text>
            {highestLevelSlot > 1 && (
              <LevelStepper
                level={level}
                onLevelChange={setLevel}
                highestLevelSlot={highestLevelSlot}
                levelControls={fixedSpellLevel === 0}
              />
            )}
            <Spacer />
          </HStack>
        </Th>
        {showRange && <Th>Range</Th>}
        <Th>To Hit / DC</Th>
        <Th>Damage</Th>
      </Tr>
      {spellRows}
    </Thead>
  )
}

function TurnHeaderRow({ title, requiresSave, targetAC }: { title: string; requiresSave: boolean; targetAC: number }) {
  const save = targetAC - 10
  // ABC
  const saveHeaderTitle = requiresSave ? (save < 0 ? `${save} save` : `+${save} save`) : `AC ${targetAC}`

  return (
    <Tr textColor={TABLE_HEADER_COLOR} width="100%">
      <Th fontSize="medium">{title}</Th>
      <Th>
        <Text ps={2}>To Hit / DC</Text>
      </Th>
      <Th>Damage</Th>
      <Th width="15%">{saveHeaderTitle}</Th>
    </Tr>
  )
}

function TurnTable({
  turnEngine,
  actionIdList,
  setActionIdList,
  advantageOverrides,
  setAdvantageOverrides,
  targetAC
}: {
  turnEngine: TurnEngine
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  advantageOverrides: NumberMap
  setAdvantageOverrides: Dispatcher<NumberMap>
  targetAC: number
}) {
  const generateRows = (actions: TurnAction[], isValid: (action: TurnAction) => boolean) => {
    return actions.map((action) => {
      return (
        <TurnRow
          key={action.id}
          turnAction={action}
          turnEngine={turnEngine}
          isValid={isValid(action)}
          actionIdList={actionIdList}
          setActionIdList={setActionIdList}
          advantageOverrides={advantageOverrides}
          setAdvantageOverrides={setAdvantageOverrides}
          targetAC={targetAC}
        />
      )
    })
  }

  const totalAttackCount = turnEngine.totalAttackCount
  const actionRows = generateRows(turnEngine.turnActions, (action) => action.attackNumber <= totalAttackCount)
  const bonusActionRows = generateRows(turnEngine.bonusTurnActions, (action) => action.attackNumber <= totalAttackCount)
  const reactionRows = generateRows(turnEngine.reactionTurnActions, (action) => action.attackNumber === 1)
  const actionsRequireSave = turnEngine.actionRequiresSave
  const baRequiresSave = turnEngine.bonusActionRequiresSave

  return (
    <TableContainer width="100%" pb={2}>
      <Table variant="unstyled" size="sm">
        <Tbody>
          <TurnHeaderRow title="Actions" requiresSave={actionsRequireSave} targetAC={targetAC} />
          {actionRows}
          {bonusActionRows.length > 0 && (
            <TurnHeaderRow title="Bonus Actions" requiresSave={baRequiresSave} targetAC={targetAC} />
          )}
          {bonusActionRows}
          {turnEngine.reactionTurnActions.length > 0 && (
            <TurnHeaderRow title="Reactions" requiresSave={turnEngine.reactionRequireSave} targetAC={targetAC} />
          )}
          {turnEngine.reactionTurnActions.length > 0 && reactionRows}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

function FeatureTable({
  title,
  features,
  checkedFeatures,
  setCheckedFeatures
}: {
  title: string
  features: Feature[]
  checkedFeatures: CheckMap
  setCheckedFeatures: Dispatcher<CheckMap>
}) {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedId: number = parseInt(event.target.value)
    const newFeatures: CheckMap = {
      ...checkedFeatures,
      [checkedId]: !checkedFeatures[checkedId]
    }

    setCheckedFeatures(newFeatures)
  }

  if (features.length === 0) {
    return null
  }

  const buffRows = features.map((feature: Feature) => {
    if (feature.isBuff) {
      return (
        <Tr key={feature.id}>
          <Td>
            <form>
              <Checkbox
                colorScheme={'blue'}
                id={feature.id.toString()}
                value={feature.id}
                isChecked={checkedFeatures[feature.id]}
                onChange={handleCheckboxChange}
              >
                <FeatureBox feature={feature} ps={0} />
              </Checkbox>
            </form>
          </Td>
          <WrappingTd>{feature.generatedNotes()}</WrappingTd>
        </Tr>
      )
    } else {
      return (
        <Tr key={feature.id}>
          <Td colSpan={2}>
            <FeatureBox feature={feature} ps={0} />
          </Td>
        </Tr>
      )
    }
  })

  return (
    <Tbody>
      <Tr textColor={TABLE_HEADER_COLOR}>
        <Th fontSize={'medium'} colSpan={2} pt={4}>
          {title}
        </Th>
      </Tr>
      {buffRows}
    </Tbody>
  )
}

function AttackTable({
  title,
  attacks,
  actionIdList,
  setActionIdList,
  activationType,
  showRange
}: {
  title: string
  attacks: AttackActionInterface[]
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  activationType: number
  showRange: boolean
}) {
  if (attacks.length === 0) {
    return null
  }
  // This is redundant since we pass in the right list, but good to filter out stuff just in case
  const filteredAttacks = attacks.filter(
    (attack: { activation: { activationType: number } }) => attack.activation.activationType === activationType
  )

  return (
    <Thead>
      <Tr textColor={TABLE_HEADER_COLOR} whiteSpace="normal" wordBreak="break-word">
        <Th fontSize={'medium'} pt={4}>
          {title}
        </Th>
        {showRange && <Th>Range</Th>}
        <Th>To Hit</Th>
        <Th>Damage</Th>
      </Tr>
      {filteredAttacks.map((attack: AttackActionInterface) => (
        <AttackRow
          key={`${attack.attributes.id}-${attack.name}`}
          attack={attack}
          actionIdList={actionIdList}
          setActionIdList={setActionIdList}
          level={0}
          showRange={showRange}
        />
      ))}
    </Thead>
  )
}

function FeatureTableSection({
  title,
  features,
  checkedFeatures,
  setCheckedFeatures
}: {
  title: string
  features: Feature[]
  checkedFeatures: CheckMap
  setCheckedFeatures: Dispatcher<CheckMap>
}) {
  if (features.length === 0) {
    return null
  }
  return (
    <FeatureTable
      title={title}
      features={features}
      checkedFeatures={checkedFeatures}
      setCheckedFeatures={setCheckedFeatures}
    />
  )
}
