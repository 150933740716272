import { ABILITY_NAMES, AttackType, Class } from '../Common/Constants'
import { Dice, DiceCollection } from './Dice'
import { Character } from './Character'
import { Range } from '../DDB/Range'
import { Dictionary } from '../Common/Types'

// TODO: This still takes character as a parameter, it would be nice to decouple that out somehow or use some interface

export class Weapon {
  id: number
  name: string
  type: string
  customizedName: string | null = null
  dealsDamage: boolean = true
  isFinesse: boolean = false
  isReach: boolean = false
  isTwoHanded: boolean = false
  isPolearm: boolean = false
  is2024Polearm: boolean = false // Quarterstaff, a Spear, or a weapon that has the Heavy and Reach properties
  isBow: boolean = false
  isRanged: boolean = false
  isOffHand: boolean = false
  isHeavy: boolean = false
  isLight: boolean = false
  isThrown: boolean = false
  isVersatile: boolean = false
  isAttuned: boolean = false
  isHex: boolean = false
  isPact: boolean = false
  damageType: string | null = null

  diceCount: number | null = null
  diceValue: number | null = null
  baseDice: Dice
  bonusDamageDice: DiceCollection

  versatileDice: string | null = null
  magic: boolean = false

  magicBonus: number = 0
  attackMod: number = 0
  damageMod: number = 0
  critDamageDice: Dice | null = null
  attackStatIndex: number = 0
  usesGreatWeaponFighting: boolean = false
  hasTwoWeaponFighting: boolean = false
  range: Range

  constructor(
    itemData: Dictionary,
    inventoryTitleMap: Dictionary,
    inventoryValueMap: Dictionary,
    character: Character
  ) {
    const data = itemData.definition

    this.name = data.name
    this.type = data.type

    this.id = itemData.id
    const id = itemData.id.toString()
    if (id in inventoryTitleMap) {
      this.customizedName = `${inventoryTitleMap[id]} (${this.name})`
    }

    const weaponBehaviorsList: Dictionary[] = data.weaponBehaviors
    const foundWeaponBehaviors = weaponBehaviorsList.find((behavior) => 'damage' in behavior)

    const weaponBehaviors = foundWeaponBehaviors ? foundWeaponBehaviors : data // Staff of power stores it somewhere different for some reason

    const damageData = weaponBehaviors.damage
    this.damageType = weaponBehaviors.damageType

    this.baseDice = Dice.flatAmountDie(0)
    if (damageData) {
      this.baseDice = new Dice(damageData)
    }

    if (this.name === 'Net') {
      this.dealsDamage = false
    }

    this.magic = data.magic
    this.isAttuned = itemData.isAttuned

    this.range = {
      origin: 'Weapon',
      range: weaponBehaviors.range,
      longRange: weaponBehaviors.longRange
    }

    if (this.isAttuned) {
      // Hack - assume the artificer made this for themself, and any weapon infusion is +1.
      // TODO - haven't dealt with enhanced weapon infusion yet, but this works for repeating shot
      const artificerLevel = character.classLevel(Class.ARTIFICER)
      if (artificerLevel >= 2) {
        this.magicBonus += 1
      }
    }

    const type = data.type

    const propertiesData = weaponBehaviors.properties

    if (data.attackType === 2) {
      // 2024 Ranged is no longer a weapon behavior property, it's just the attack type
      this.isRanged = true
    }

    for (const propData of propertiesData) {
      const propName = propData.name
      if (propName === 'Two-Handed') {
        this.isTwoHanded = true
      } else if (propName === 'Heavy') {
        this.isHeavy = true
      } else if (propName === 'Range') {
        this.isRanged = true
      } else if (propName === 'Finesse') {
        this.isFinesse = true
      } else if (propName === 'Versatile') {
        this.isVersatile = true
        this.versatileDice = propData.notes
      } else if (propName === 'Light') {
        this.isLight = true
      } else if (propName === 'Thrown') {
        this.isThrown = true
      } else if (propName === 'Reach') {
        this.isReach = true
      }
    }

    this.isPolearm = ['Glaive', 'Halberd', 'Quarterstaff', 'Spear'].includes(type)
    this.is2024Polearm = ['Quarterstaff', 'Spear'].includes(type) || (this.isHeavy && this.isReach)

    this.isBow = ['Longbow', 'Shortbow'].includes(type)

    const bonusDiceCollection = new DiceCollection()
    const grantedMods = data.grantedModifiers
    for (const grantedMod of grantedMods) {
      const type = grantedMod.type
      const subtype = grantedMod.subType
      if (type === 'bonus' && subtype === 'magic') {
        this.magicBonus += parseInt(grantedMod.value)
      }

      if (type === 'damage') {
        let bonusDice: Dice = Dice.flatAmountDie(0)

        if (grantedMod.dice) {
          bonusDice = new Dice(grantedMod.dice)
        } else if (grantedMod.fixedValue) {
          bonusDice = Dice.flatAmountDie(grantedMod.fixedValue)
        } else {
          console.warn('Could not find crit damage dice for weapon ' + this.name)
        }

        if (grantedMod.restriction === '20 on the Attack Roll') {
          if (this.name.includes('Vicious') && bonusDice.diceString() === '2d6') {
            // json hands down 2d6, description says 7
            bonusDice = Dice.flatAmountDie(7)
          }
          this.critDamageDice = bonusDice ?? null
        } else if (grantedMod.restriction === '') {
          bonusDiceCollection.addDice(bonusDice)
        } else {
          console.warn(`${this.name} has unknown restriction for granted damage modifier: ${grantedMod.restriction}`)
        }
      }
    }

    // TODO I think this is a bad assumption, attackType: 2 seems to be ranged.
    // It just so happens to map to STR and DEX?
    const attackStat = data.attackType
    if (attackStat !== null) {
      this.attackStatIndex = attackStat - 1
    }

    if (this.isFinesse && character.dexterity() > character.strength()) {
      this.attackStatIndex = ABILITY_NAMES.indexOf('dexterity')
    }

    if (
      character.classLevel(Class.ARTIFICER) > 0 &&
      character.classes.some((cls) => cls.subclassName === 'Battle Smith') &&
      (this.magic === true || this.isAttuned)
    ) {
      this.attackStatIndex = ABILITY_NAMES.indexOf('intelligence')
    }

    if (id in inventoryValueMap) {
      const valueMap = inventoryValueMap[id]
      for (const [typeID, value] of valueMap) {
        if (typeID === 18 && value === true) {
          this.isOffHand = true
        }
      }
    }

    if (character.classLevel(Class.WARLOCK) > 0) {
      if (id in inventoryValueMap) {
        const valueMap = inventoryValueMap[id]
        for (const [typeID, value] of valueMap) {
          if (typeID === 28 && value === true) {
            this.isPact = true
          }
          if (typeID === 29 && value === true) {
            this.isHex = true
          }
        }
      }

      if (this.magicBonus === 0 && this.isPact && character.classOptionNames.includes('Improved Pact Weapon')) {
        this.magicBonus += 1
      }

      if (this.isHex) {
        const chaIndex = ABILITY_NAMES.indexOf('charisma')
        const weaponAttr = character.abilityScoreForIndex(this.attackStatIndex)
        const hexAttr = character.abilityScoreForIndex(chaIndex)
        if (hexAttr > weaponAttr) {
          this.attackStatIndex = chaIndex
        }
      }
    }

    if (
      this.isVersatile &&
      !(character.hasOffHand || character.hasShieldEquipped) &&
      this.versatileDice !== undefined
    ) {
      if (this.versatileDice === '1d8') {
        this.baseDice = Dice.Create(1, 8)
      } else if (this.versatileDice === '1d10') {
        this.baseDice = Dice.Create(1, 10)
      } else if (this.versatileDice === '1d12') {
        this.baseDice = Dice.Create(1, 12)
      } else {
        console.error('Unknown versatile dice: ' + this.versatileDice)
      }
    }

    const monkLevel = character.classLevel(Class.MONK)
    if (data.isMonkWeapon && monkLevel > 1) {
      const monkDieSize = Math.floor((monkLevel - 1) / 4) * 2 + 4
      const weaponDamageAverage = this.baseDice.avg()
      const monkDieAverage = (monkDieSize + 1) / 2
      if (monkDieAverage > weaponDamageAverage) {
        this.baseDice = Dice.Create(1, monkDieSize)
      }

      if (character.dexterity() > character.strength()) {
        this.attackStatIndex = ABILITY_NAMES.indexOf('dexterity')
      }
    }

    this.bonusDamageDice = bonusDiceCollection.copy()
  }

  // TODO - later just have a structure with attributes, then return the json blob
  weaponAttributes() {
    return {
      id: this.id,
      type: AttackType.WEAPON,
      weaponType: this.type,
      damageType: this.damageType,
      dealsDamage: this.dealsDamage, // Nets don't deal damage
      damageTypes: [this.damageType],
      critDamageDice: this.critDamageDice,
      range: this.range,
      requiresAttackRoll: true,
      isHexWeapon: this.isHex,
      isPactWeapon: this.isPact,
      isRanged: this.isRanged,
      isBow: this.isBow,
      isFinesse: this.isFinesse,
      isHeavy: this.isHeavy,
      isThrown: this.isThrown,
      isOffHand: this.isOffHand,
      isLight: this.isLight,
      isVersatile: this.isVersatile,
      isTwoHanded: this.isTwoHanded,
      bonusDamageDice: this.bonusDamageDice,
      attackStatIndex: this.attackStatIndex
    }
  }
}
