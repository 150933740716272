export const DISCORD_URL: string = 'http://discord.gg/Vphu9Gr8V8'
export enum AttackType {
  WEAPON = 'Weapon',
  SPELL = 'Spell',
  SPELL_ATTACK = 'Spell Attack', // Made up
  COMPANION = 'Companion' // Made up
}
export const ABILITY_NAMES = ['strength', 'dexterity', 'constitution', 'intelligence', 'wisdom', 'charisma']
export enum Class {
  ARTIFICER = 'Artificer',
  BARBARIAN = 'Barbarian',
  BARD = 'Bard',
  CLERIC = 'Cleric',
  DRUID = 'Druid',
  FIGHTER = 'Fighter',
  MONK = 'Monk',
  PALADIN = 'Paladin',
  RANGER = 'Ranger',
  ROGUE = 'Rogue',
  SORCERER = 'Sorcerer',
  WARLOCK = 'Warlock',
  WIZARD = 'Wizard'
}

// Non-buff actions is just for development purposes, to see which stuff is sneaking through unhandled. Though maybe it's a slight performance boost?
export const NON_BUFF_ACTIONS = [
  'Fiend Spells',
  'Celestial Spells',
  'Healing Light: Expend Healing',
  'Whispers of the Grave',
  'Lifedrinker',
  'Eldritch Spear',
  'Devouring Blade',
  'Steps of the Fey',
  'Archfey Spells',
  'Pact of the Chain: Attack',
  'Bewitching Magic',
  'Misty Step: Refreshing Step',
  'Misty Step: Taunting Step',
  'Misty Step: Disappearing Step',
  'Misty Step: Dreadful Step',
  'Assassinate',
  'Envenom Weapons',
  'Steady Aim: Roving Aim',
  'Masterful Mimicry',
  'Assassin’s Tools',
  'Use Magic Device: Charges',
  'Use Magic Device: Scroll',
  'Fast Hands: Use Magic Item',
  'Fast Hands: Utilize',
  'Fast Hands: Sleight of Hand',
  'Improved Cunning Strike',
  'Devious Strikes',
  'Cunning Strike',
  '6: Expertise',
  '10: Ability Score Improvement',
  'Psychic Teleportation',
  'Core Rogue Traits',
  'Fighting Style feat',
  'Relentless Hunter',
  'Roving',
  'Tireless',
  'Core Ranger Traits',
  'Hunter’s Mark',
  'Hunter’s Lore',
  'Circle of the Moon Spells',
  'Lunar Form',
  'Moonlight Step',
  'Moonlight Step: Regain Uses',
  'Wild Shape: Circle Forms',
  'Improved Circle Forms',
  'Manifest Wrath of the Sea',
  'Oceanic Gift',
  'Primal Strike',
  'Elemental Fury',
  'Wrath of the Sea',
  'Improved Elemental Fury',
  'Warden',
  'Core Druid Traits',
  'Wild Resurgence',
  'Primal Order',
  'Aquatic Affinity',
  'Nature Magician',
  'Grant Wrath of the Sea',
  'Leave Druidic Message',
  'Circle of the Sea Spells',
  'Wild Resurgence: Regain Wild Shape',
  'Wild Resurgence: Regain Spell Slot',
  'Evergreen Wildshape',
  'Tactical Mind',
  'Psionic Power: Psionic Energy Dice',
  'Psionic Power: Psi-Powered Leap',
  'Core Fighter Traits',
  'Tactical Shift',
  'Second Wind: Tactical Shift',
  'Psionic Power: Telekinetic Thrust',
  'Tactical Master: Push',
  'Tactical Master',
  'Tactical Master: Sap',
  'Tactical Master: Slow',
  'Beguiling Magic',
  'Beguiling Magic: Regain Use',
  'Mantle of Inspiration',
  'Unbreakable Majesty',
  'Martial Training',
  'Mantle of Majesty',
  'Assume Unbreakable Majesty',
  'Combat Inspiration: Defense',
  'Combat Inspiration: Offense',
  'Bardic Damage',
  'Words of Creation',
  'Inspiring Movement',
  'Regain Bardic Inspiration',
  'Invoke Duplicity',
  'Invoke Duplicity: Cast Spells',
  'Invoke Duplicity: Move Illusion',
  'Invoke Duplicity: Distract',
  'Improved Warding Flare',
  'Life Domain Spells',
  'Light Domain Spells',
  'Trickery Domain Spells',
  'Rage: Teleport',
  'Vitality of the Tree',
  'Rage of the Wilds',
  'Nature Speaker',
  'Power of the Wilds',
  'Animal Speaker',
  'Brutal Strike',
  'Aspect of the Wilds',
  'Owl',
  'Branches of the Tree',
  'Battering Roots',
  'Travel along the Tree',
  'Warrior of the Gods: Expend Dice',
  'Rage of the Gods',
  'Panther',
  'Salmon',
  'Improved Brutal Strike',
  'Rage: Relentless Rage',
  'Rage (Instinctive Pounce)',
  'Rage: Regain Expended Uses',
  'Retaliation',
  'Weapon Mastery',
  'Primal Knowledge',
  '4: Weapon Mastery',
  '10: Weapon Mastery',
  'Core Barbarian Traits',
  'Tandem Footwork',
  'Core Bard Traits',
  'Dazzling Footwork',
  'Leading Evasion',
  'Font of Magic: Sorcery Points',
  'Font of Magic: Convert Spell Slots',
  'Font of Magic: Create Spell Slot Level 1',
  'Font of Magic: Create Spell Slot Level 2',
  'Font of Magic: Create Spell Slot Level 3',
  'Font of Magic: Create Spell Slot Level 4',
  'Font of Magic: Create Spell Slot Level 5',
  'Mystic Arcanum (Level 6 Spell)',
  'Mystic Arcanum (Level 7 Spell)',
  'Mystic Arcanum (Level 8 Spell)',
  'Mystic Arcanum (Level 9 Spell)',
  'Sear Undead',
  'Contact Patron',
  'Warlock Subclass',
  'Cleric Subclass',
  'Wizard Subclass',
  'Bard Subclass',
  'Fighter Subclass',
  'Artificer Subclass',
  'Ranger Subclass',
  'Rogue Subclass',
  'Paladin Subclass',
  'Druid Subclass',
  'Sorcerer Subclass',
  'Monk Subclass',
  'Barbarian Subclass',
  'Clockwork Spells',
  'See Invisibility',
  'Otherworldly Presence',
  'Memorize Spell',
  'Lessons of the First Ones',
  'Elven Lineage Spells',
  'Scholar',
  'Elven Lineage',
  'Two Extra Attacks',
  'Three Extra Attacks',
  '9: Expertise',
  '4: Ability Score Improvement',
  '6: Ability Score Improvement',
  '8: Ability Score Increase',
  '8: Ability Score Improvement',
  '12: Ability Score Improvement',
  '14: Ability Score Improvement',
  '16: Ability Score Improvement',
  'Epic Boon',
  'Magical Cunning',
  'Eldritch Invocation Options',
  'Core Wizard Traits',
  'Core Sorcerer Traits',
  'Ritual Adept',
  'Font of Magic',
  'Core Warlock Traits',
  'Fiendish Legacy Spells',
  'Fiendish Legacy',
  'Fighting Style: Thrown Weapon Fighting',
  'Fighting Style: Thrown Weapon Fighting ',
  'Cunning Intuition',
  'Poison Immunity',
  'Shape Shadows',
  'Riposte',
  'Innate Spellcasting',
  'Cat’s Claws',
  'Horns',
  'Hammering Horns',
  'Elemental Magic',
  'Silent Feathers',
  'Vigilant Guardian',
  'Sentinel’s Intuition',
  'Guardian’s Shield',
  'Natural Athlete',
  'Equipment',
  'Tactical Assessment',
  'Lunging Attack',
  'Bait and Switch',
  'Parry',
  'Rabbit Hop',
  'Lucky Footwork',
  'Lucky',
  'Leporine Senses',
  'Hare-Trigger',
  'Elemental Wild Shape',
  'Blessed Warrior',
  'Naturally Stealthy',
  'Mimicry',
  'Kenku Training',
  'Expert Forgery',
  'Blind Fighting',
  'Sneaky',
  'Changeling Instincts',
  'Nimble Escape',
  'Merge with Stone',
  'Earth Walk',
  'Perfected Armor',
  'Stout Resilience',
  'Healing Machine',
  'Mechanical Nature',
  'Quick Toss',
  'Goading Attack',
  'Disarming Attack',
  'Maneuvering Attack',
  'Armored Casing',
  'Pushing Attack',
  'Trip Attack',
  'Embodiment of the Law',
  'Voice of Authority',
  'Gem Ancestry',
  'Thri-kreen Telepathy',
  'Sleepless',
  'Secondary Arms',
  'Chameleon Carapace',
  'Draconic Resistance',
  'Dwarven Armor Training',
  'Gem Flight',
  'Psionic Mind',
  'Magic Resistance',
  'Reveler',
  'Mirthful Leaps',
  'Good',
  'Glide',
  'Hadozee Dodge',
  'Dexterous Feet',
  'Metamagic - Extended Spell',
  'Metamagic - Subtle Spell',
  'Metamagic - Careful Spell',
  'Metamagic: Transmuted Spell',
  'Transmuted Spell',
  'Core Cleric Traits',
  'Sorcery Incarnate',
  'Arcane Apotheosis',
  'Metamagic Options',
  'Grim Psychometry',
  'Resilient Hide',
  'Hardened Soul',
  'Life Domain Spells',
  'Preserve Life',
  'Tomb of Levistus',
  'Repelling Blast',
  'Fey Gift',
  'Kobold Legacy',
  'Deathless Nature',
  'Variant Tiefling',
  'Spider Climb',
  'Blue Dragon',
  'Large Form',
  'Extra Language',
  'Ancestral Legacy',
  'Cat’s Talent',
  'Aereni Elf',
  'Cantrip',
  'Feline Agility',
  'Cold Resistance',
  'Drake Companion (Fire)',
  'Drake Companion (Cold)',
  'Drake Companion (Acid)',
  'Totemic Attunement - Elk',
  'Variable Trait',
  'Little Giant',
  'Stone’s Endurance',
  'Mountain Born',
  'Giants',
  'Mountain',
  'Astral Fire',
  'Starlight Step',
  'One with Shadows',
  'Astral Trance',
  'Devil’s Sight',
  'Voice of the Chain Master',
  'Tiger',
  'Bear',
  'Peerless Skill',
  'Cutting Words',
  'Reach to the Blaze',
  'Fire Resistance',
  'Additional Magical Secrets',
  'Fist of Unbroken Air',
  'Disciple of the Elements',
  'Searing Arc Strike',
  'Sun Shield',
  'Searing Sunburst',
  'Open Hand Technique',
  'Wholeness of Body',
  'Tranquility',
  'Touch of the Long Death',
  'Defense',
  'Mastery of Death',
  'Hour of Reaping',
  'Touch of Death',
  'Kensei Weapon',
  'Path of the Kensei',
  'One with the Blade',
  'Magic Kensei Weapons',
  'Agile Parry',
  'Drunkard’s Luck',
  'Drunken Technique',
  'Tipsy Sway',
  'Arms of the Astral Self',
  'Visage of the Astral Self',
  'Arms of the Astral Self (WIS)',
  'Arms of the Astral Self (DEX/STR)',
  'Arms of the Astral Self: Summon',
  'Explosive Fury',
  'Draconic Disciple',
  'Ascendant Aspect',
  'Augment Breath',
  'Aspect of the Wyrm: Frightful Presence',
  'Aspect of the Wyrm',
  'Wings Unfurled',
  'Breath of the Dragon',
  'Draconic Strike',
  'Shadow Arts',
  'Unarmored Movement Improvement',
  'Monastic Tradition',
  'Implements of Mercy',
  'Hand of Ultimate Mercy',
  'Physician’s Touch',
  'Quickened Healing',
  'Ki Points',
  'Hand of Healing',
  'Deflect Missiles',
  'Unarmed Strike',
  'Deflecting Shroud',
  'Arcane Deflection',
  'Tactical Wit',
  'Master Transmuter',
  'Shapechanger',
  'Transmuter’s Stone',
  'Minor Alchemy',
  'Undead Thralls',
  'Durable Magic',
  'Grim Harvest',
  'Command Undead',
  'Inured to Undeath',
  'Necromancy Savant',
  'Illusory Reality',
  'Illusory Self',
  'Malleable Illusions',
  'Pact of the Blade: Bond',
  'Pact of the Blade: Conjure',
  'Protector',
  'Divine Intervention',
  'Divine Order',
  'Greater Divine Intervention',
  'Improved Minor Illusion',
  'Illusion Savant',
  'Arcane Jolt',
  'Dread Ambusher',
  'Divine Fury',
  'Evocation Savant',
  'Overchannel',
  'Sculpt Spells',
  'Instinctive Charm',
  'Split Enchantment',
  'Alter Memories',
  'Enchantment Savant',
  'Hypnotic Gaze',
  'Instinctive Charm',
  'Divination Savant',
  'The Third Eye',
  'Benign Transposition',
  'Minor Conjuration',
  'Benign Transposition',
  'Conjuration Savant',
  'Durable Summons',
  'Focused Conjuration',
  'Arcane Ward',
  'Arcane Ward - Hit Points',
  'Abjuration Savant',
  'Spell Resistance',
  'Manifest Mind',
  'One with the Word',
  'Awakened Spellbook',
  'Awakened Spellbook: Replace Damage',
  'Projected Ward',
  'Improved Abjuration',
  'Awakened Spellbook: Ritual Spells',
  'Master Scrivener',
  'Master Scrivener: Create Scroll',
  'Master Scrivener: Use Scroll',
  'Wizardly Quill',
  'Manifest Mind: Move',
  'Manifest Mind: Summon',
  'Manifest Mind: Cast Spell',
  'Bladesong',
  'Song of Defense',
  'Training in War and Song',
  'Necrotic Resistance',
  'Blessing of the Raven Queen',
  'Guardian',
  'Perfected Armor - Guardian',
  'Bestow Water Breathing',
  'Ambush',
  'Menacing Attack',
  'Precision Attack',
  'Pact of the Tome',
  'Far Scribe',
  'Pact Boon: Pact of the Talisman',
  'Pact of the Talisman',
  'Ascendant Step',
  'Sign of Ill Omen',
  'Pact of the Chain',
  'Undying Nature',
  'Defy Death',
  'Indestructible Life',
  'Among the Dead',
  'Spirit Projection',
  'Spirit Projection: Project Spirit',
  'Necrotic Husk',
  'Necrotic Husk: Revival',
  'Grave Touched: Replace Damage',
  'Master of Hexes',
  'Labyrinthine Recall',
  'Armor of Hexes',
  'Hexblade’s Curse',
  'Create Thrall',
  'Shifting',
  'Thought Shield',
  'Awakened Mind',
  'Merfolk Weapon Training',
  'Genie’s Vessel',
  'Shifting Feature',
  'Subrace',
  'Amphibious',
  'Dao',
  'Djinni',
  'Marid',
  'Efreeti',
  'Elemental Gift (Dao)',
  'Elemental Gift (Djinni)',
  'Elemental Gift (Marid)',
  'Elemental Gift (Efreeti)',
  'Genie Kind',
  'Sanctuary Vessel',
  'Elemental Gift',
  'Limited Wish',
  'Elemental Gift - Flight',
  "Genie's Vessel: Bottled Respite",
  'Grasping Tentacles',
  'Guardian Coil',
  'Oceanic Soul',
  'Gift of the Sea',
  'Fathomless Plunge',
  'Tentacle of the Deeps',
  'Tentacle of the Deeps: Move',
  'Tentacle of the Deeps: Summon',
  'Tentacle of the Deeps: Attack',
  'Tentacle of the Deeps: Guardian Coil',
  'Celestial Resilience',
  'Bonus Cantrips',
  'Wolf',
  'Searing Vengeance',
  ' Radiant Soul', // Typo in DDB
  'Radiant Soul',
  'Radiant',
  'Healing Light',
  'Armor of Shadows',
  'Witch Sight',
  'Dark Delirium',
  'Beguiling Defenses',
  'Fey Presence',
  'Misty Escape',
  'Improved Pact Weapon',
  'Eldritch Smite',
  'Hurl Through Hell',
  'Maddening Hex',
  'Thirsting Blade',
  'Grasp of Hadar',
  'Lance of Lethargy',
  'Pact of the Blade',
  'Infernal Legacy',
  'Hellish Resistance',
  'Pact Magic',
  'Pact Boon',
  'Otherworldly Patron',
  'Fiendish Resilience',
  'Dark One’s Own Luck',
  'Dark One’s Blessing',
  'Eldritch Master',
  'Tides of Chaos',
  'Controlled Chaos',
  'Bend Luck',
  'Wild Magic Surge',
  'Wind Speaker',
  'Storm’s Fury',
  'Storm Guide',
  'Tempestuous Magic',
  'Wind Soul',
  'Strength of the Grave',
  'Eyes of the Dark',
  'Shadow Walk',
  'Umbral Form',
  'Hound of Ill Omen',
  'Draconic Resilience',
  'Dragon Ancestor',
  'Draconic Presence',
  'Elemental Affinity',
  'Dragon Wings',
  'Unearthly Recovery',
  'Divine Magic',
  'Otherworldly Wings',
  'Empowered Healing',
  'Clockwork Magic',
  'Clockwork Cavalcade',
  'Bastion of Law',
  'Restore Balance',
  'Talons',
  'Wind Caller',
  'Flight',
  'Sorcerous Restoration',
  'Metamagic',
  'Psychic Defenses',
  'Font of Magic',
  'Sorcerous Origin',
  'Psionic Spells',
  'Warping Implosion',
  'Revelation in Flesh',
  'Psionic Sorcery',
  'Telepathic Speech',
  'Magical Guidance',
  'Convert Sorcery Points',
  'Sorcery Points',
  'Mighty Swarm',
  'Swarmkeeper Magic',
  'Swarming Dispersal',
  'Writhing Tide',
  'Monster Slayer Magic',
  'Magic-User’s Nemesis',
  'Supernatural Defense',
  'Hunter’s Sense',
  'Defensive Tactics',
  'Superior Hunter’s Defense',
  'Multiattack',
  'Hunter’s Prey',
  'Spectral Defense',
  'Horizon Walker Magic',
  'Ethereal Step',
  'Detect Portal',
  'Otherworldly Glamour',
  'Fey Wanderer Magic',
  'Misty Wanderer',
  'Fey Reinforcements',
  'Beguiling Twist',
  'Bond of Fang and Scale',
  'Perfected Bond',
  'Reflexive Resistance',
  'Drake’s Breath',
  'Draconic Gift',
  'Deft Explorer: Tireless',
  'Mask of the Wild',
  'Fleet of Foot',
  'Elf Weapon Training',
  'Feral Senses',
  'Deft Explorer: Tireless (exhaustion)',
  'Tireless (10th Level)',
  'Deft Explorer',
  'Share Spells',
  'Exceptional Training',
  'Wood Elf Descent',
  'Vanish',
  'Armor Modifications',
  'Perfected Armor: Guardian',
  'Guardian Armor: Thunder Gauntlets',
  'Guardian Armor: Thunder Gauntlets (STR)',
  'Guardian Armor: Defensive Field',
  'Armor Model',
  'Armorer Spells',
  'Arcane Armor',
  'Arcane Armor - Create Armor',
  'Tools of the Trade',
  'Improved Defender',
  'Battle Ready',
  'Battle Smith Spells',
  'Chemical Mastery',
  'Restorative Reagents',
  'Alchemist Spells',
  'Tinker',
  'Artificer’s Lore',
  'Gnome Cunning',
  'Explosive Cannon',
  'Detonate Eldritch Cannon',
  'Use Magic Device',
  'Supreme Sneak',
  'Second-Story Work',
  'Fast Hands',
  'Soul Blades',
  'Psychic Veil',
  'Rend Mind',
  'Psychic Blades: Attack (DEX)',
  'Psychic Blades: Bonus Attack (DEX)',
  'Psychic Blades: Attack (STR)',
  'Psychic Blades: Bonus Attack (STR)',
  'Psionic Power: Psychic Whispers',
  'Psionic Power: Psi-Bolstered Knack',
  'Slippery Mind',
  'Soul Blades: Psychic Teleportation',
  'Skirmisher',
  'Superior Mobility',
  'Survivalist',
  'Tokens of the Departed',
  'Whispers of the Dead',
  'Death’s Friend',
  'Ghost Walk',
  'Tokens of the Departed: Question Spirit',
  'Tokens of the Departed: Create Soul Trinket',
  'Soul of Deceit',
  'Master of Intrigue',
  'Misdirection',
  'Insightful Manipulator',
  'Master of Tactics',
  'Unerring Eye',
  'Steady Eye',
  'Ear for Deceit',
  'Insightful Fighting',
  'Eye for Detail',
  'Eladrin Season',
  'Trance',
  'Keen Senses',
  'Fey Step',
  'Magical Ambush',
  'Mage Hand Legerdemain',
  'Spell Thief',
  'Blessed Strikes',
  'War Domain Spells',
  'War God’s Blessing',
  'Guided Strike',
  'Improved Blessed Strikes',
  'Channel Divinity: Sear Undead',
  'Channel Divinity: Guided Strike (Benefit Ally)',
  'Channel Divinity: Control Undead',
  'Channel Divinity: Dreadful Aspect',
  'Channel Divinity: Divine Spark',
  'Channel Divinity: Abjure the Extraplanar',
  'Channel Divinity: Watcher’s Will',
  'Channel Divinity: Champion Challenge',
  'Channel Divinity: Turn the Tide',
  'Channel Divinity: Turn the Faithless',
  'Channel Divinity: Abjure Enemy',
  'Channel Divinity: Emissary of Peace',
  'Channel Divinity: Conquering Presence',
  'Aura of the Sentinel',
  'Unyielding Spirit',
  'Exalted Champion',
  'Divine Allegiance',
  'Aura of Warding',
  'Undying Sentinel',
  'Relentless Avenger',
  'Aura of the Guardian',
  'Emissary of Redemption',
  'Protective Spirit',
  'Aura of Conquest',
  'Heavy Armor Master',
  'Healing Hands',
  'Light Bearer',
  'Celestial Resistance',
  'Combat Inspiration',
  'Unfailing Inspiration',
  'Silver Tongue',
  'Infectious Inspiration',
  'Universal Speech',
  'Unsettling Words',
  'Words of Terror',
  'Mantle of Whispers',
  'Shadow Lore',
  'Thousand Forms',
  'Circle Forms',
  'Combat Wild Shape',
  'Fungal Body',
  'Halo of Spores',
  'Spreading Spores',
  'Fungal Infestation',
  'Faithful Summons',
  'Guardian Spirit',
  'Mighty Summoner',
  'Spirit Totem',
  'Speech of the Woods',
  'Nature’s Sanctuary',
  'Natural Recovery',
  'Nature’s Ward',
  'Circle Spells',
  'Blazing Revival',
  'Cauterizing Flames',
  'Enhanced Bond',
  'Summon Wildfire Spirit: Command',
  'Summon Wildfire Spirit',
  'Cosmic Omen',
  'Cosmic Omen ', // DDB returns an extra space in the name
  'Star Map',
  'Full of Stars',
  'Twinkling Constellations',
  'Aberrant Dragonmark',
  'Beast Spells',
  'Archdruid',
  'Druid Circle',
  'Timeless Body',
  'Wild Shape Improvement',
  'Druidic',
  'Walker in Dreams',
  'Hidden Paths',
  'Hearth of Moonlight and Shadow',
  'Balm of the Summer Court',
  'Animating Performance',
  'Creative Crescendo',
  'Animating Performance: Animate',
  'Animating Performance: Dancing Item',
  'Font of Inspiration',
  'Performance of Creation',
  'Mote of Potential',
  'Brave',
  'Halfling Nimbleness',
  'Spells of the Mark',
  'Healing Touch',
  'Medical Intuition',
  'Superior Inspiration',
  'Magical Secrets',
  'Bard College',
  'Bardic Inspiration',
  'Song of Rest',
  'Jack of All Trades',
  'Magical Inspiration',
  'Countercharm',
  'Rage beyond Death',
  'Zealous Presence',
  'Warrior of the Gods',
  'Fanatical Focus Reroll',
  'Fanatical Focus',
  'Giant’s Havoc',
  'Giant’s Power',
  'Demiurgic Colossus',
  'Frenzy',
  'Mighty Impel',
  'Elemental Cleaver: Change Damage Type',
  'Mindless Rage',
  'Intimidating Presence',
  'Sentinel', // TODO - add it once we have Reactions
  'Totem Spirit',
  'Elegant Courtier',
  'Master of Runes',
  'Runic Juggernaut',
  'Telekinetic Adept',
  'Great Stature',
  'Restriction: Knighthood',
  'Cloud Rune',
  'Frost Rune',
  'Storm Rune',
  'Hill Rune',
  'Rune Carver',
  'Runic Shield',
  'Royal Envoy',
  'Bulwark',
  'Rallying Cry',
  'Inspiring Surge',
  'Maneuvers: Bait and Switch',
  'Psionic Power: Protective Field',
  'Psionic Power',
  'Guarded Mind',
  'Psionic Power: Recovery',
  'Psionic Power: Psionic Energy',
  'Psionic Power: Telekinetic Movement',
  'Eldritch Strike',
  'Additional Fighting Style',
  'Telekinetic Adept: Psi-Powered Leap',
  'Telekinetic Adept: Telekinetic Thrust',
  'Weapon Bond',
  'Bulwark of Force',
  'Arcane Charge',
  'Survivor',
  'Remarkable Athlete',
  'Maneuvers: Commander’s Strike',
  'Maneuvers: Commanding Presence',
  'Maneuvers: Tactical Assessment',
  'Unwavering Mark',
  'Psychic Resilience',
  'Githyanki Psionics',
  'Ever-Ready Shot',
  'Warding Maneuver',
  'Ferocious Charger',
  'Hold the Line',
  'Born to the Saddle',
  'Arcane Archer Lore',
  'Polearm Master',
  'Arcane Shot',
  'Magic Arrow',
  'Vigilant Defender',
  'Fighting Style: Unarmed Fighting',
  'Fighting Style: Unarmed Fighting (grapple)',
  'Fighting Style: Unarmed Fighting (armed)',
  'Eyes of Night',
  'Vigilant Blessing',
  'Thunderbolt Strike',
  'Steps of Night',
  'Stormborn',
  'Avatar of Battle',
  'Channel Divinity: Knowledge of the Ages',
  'Channel Divinity: War God’s Blessing',
  'Channel Divinity: Twilight Sanctuary',
  'Channel Divinity: Balm of Peace',
  'Channel Divinity: Charm Animals and Plants',
  'Channel Divinity: Radiance of the Dawn',
  'Radiance of the Dawn',
  'Expansive Bond',
  'Implement of Peace',
  'Master of Nature',
  'Twilight Shroud',
  'Dampen Elements',
  'Acolyte of Nature',
  'Improved Flare',
  'Supreme Healing',
  'Channel Divinity: Preserve Life',
  'Protective Bond',
  'Blessed Healer',
  'Disciple of Life',
  'Blessings of Knowledge',
  'Channel Divinity: Read Thoughts',
  'Visions of the Past',
  'Sentinel at Death’s Door',
  'Keeper of Souls',
  'Arcane Mastery',
  'Circle of Mortality',
  'Eyes of the Grave',
  'Arcane Initiate',
  'Resilient',
  "Channel Divinity: Artisan's Blessing",
  'Blessing of the Forge',
  'Soul of the Forge',
  'Artificer Initiate',
  'Magic Initiate (Druid)',
  'Magic Initiate (Sorcerer)',
  'Magic Initiate (Warlock)',
  'Magic Initiate (Wizard)',
  'Magic Initiate (Bard)',
  'Domain Spells',
  'Saint of Forge and Fire',
  'Magic Initiate (Cleric)',
  'Inescapable Destruction',
  'Bonus Proficiency',
  'Channel Divinity: Arcane Abjuration',
  'Spell Breaker',
  'Improved Duplicity',
  'Divine Domain',
  'Creature Type',
  'High Elf Descent',
  'Arcane Shot Options',
  'Astral Knowledge',
  'Fey Ancestry',
  'Blessing of the Trickster',
  'Invoke Duplicity - Move Illusion',
  'Channel Divinity: Cloak of Shadows',
  'Channel Divinity: Invoke Duplicity',
  'Divine Intervention',
  'Channel Divinity: Turn Undead',
  'Destroy Undead',
  'Spirit Seeker',
  'Aspect of the Beast',
  'Totemic Attunement',
  'Darkvision',
  'Relentless Endurance',
  'Menacing',
  'Ability Score Increase',
  'Ability Score Increases',
  'Languages',
  'Age',
  'Size',
  'Speed',
  'Spirit Walker',
  'Signature Spells',
  'Arcane Tradition',
  'Greater Portent',
  'Bonus Cantrip',
  'Warding Flare',
  'Storm Soul',
  'Storm Aura',
  'Shielding Storm',
  'Storm Aura: Tundra',
  'Expanded Spell List',
  'Form of Dread: Transform',
  'Form of Dread',
  'Maneuvers',
  'Martial Archetype',
  'Maneuvers: Rally',
  'Mystic Arcanum (6th level)',
  'Mystic Arcanum (7th level)',
  'Mystic Arcanum (8th level)',
  'Mystic Arcanum (9th level)',
  'Eldritch Invocations',
  'Relentless',
  'Student of War',
  'Combat Superiority',
  'Maneuvers: Parry',
  'Flash of Genius',
  'Fortified Position',
  'Artificer Infusions',
  'Magic Item Adept',
  'Magic Item Savant',
  'Spell-Storing Item',
  'Soul of Artifice',
  'Magic Item Master',
  'Artificer Specialist',
  'Eldritch Cannon',
  'Artillerist Spells',
  'Tool Proficiency',
  'Create Eldritch Cannon',
  'Eldritch Cannon: Flamethrower',
  'Eldritch Cannon: Force Ballista',
  'Eldritch Cannon: Protector',
  'Land’s Stride',
  'Hide in Plain Sight',
  'Ranger Archetype',
  'Natural Explorer',
  'Favored Enemy',
  'Shadowy Dodge',
  'Umbral Sight',
  'Iron Mind',
  'Impostor',
  'Gloom Stalker Magic',
  'Infiltration Expertise',
  'Bonus Proficiencies',
  'Know Your Enemy',
  'Thieves’ Cant',
  'Rakish Audacity',
  'Blindsense',
  'Slippery Mind',
  'Beasts',
  'Elusive',
  'Coast',
  'Evasion',
  'Roguish Archetype',
  'Fancy Footwork',
  'Expertise',
  'Elegant Maneuver',
  'Panache',
  'Reliable Talent',
  'Drake Companion (Lightning)',
  'Canny (1st Level)',
  'Uncanny Dodge',
  'Spellcasting',
  'Aura Improvements',
  'Divine Health',
  'Aura of Courage',
  'Sacred Oath',
  'Aura of Protection',
  'Fighting Style',
  'Aura of Devotion',
  'Holy Nimbus',
  'Purity of Spirit',
  'Harness Divine Power',
  'Channel Divinity: Turn the Unholy',
  'Oath Spells',
  'Lay on Hands',
  'Cleansing Touch',
  'Channel Divinity',
  'Reckless Abandon',
  'Armor Spike Grapple',
  'Restriction: Dwarves Only',
  'Battlerager Armor',
  'Armor Spike Attack',
  'Battlerager Charge',
  'Spiked Retribution',
  'Controlled Surge',
  'Unstable Backlash',
  'Wild Surge',
  'Magic Awareness',
  'Spirit Shield',
  'Ancestral Protectors',
  'Consult the Spirits',
  'Vengeful Ancestors',
  'Feral Instinct',
  'Danger Sense',
  'Call the Hunt',
  'Instinctive Pounce',
  'Bestial Soul',
  'Call of the Hunt',
  'Proficiencies',
  'Call of the Hunt',
  'Hex Warrior',
  'Create Pact Weapon',
  'Infuse Item',
  'The Right Tool for the Job',
  'Tool Expertise',
  'Magical Tinkering',
  'Accursed Specter',
  'Second Wind',
  'Drake Companion',
  'Drake Companion: Command',
  'Arcane Recovery',
  'Cantrip Formulas',
  'Portent',
  'Expert Divination',
  'Steel Defender',
  'Divine Sense',
  'Lay on Hands Pool',
  'Cunning Action',
  'Starry Form: Archer',
  'Wild Shape',
  'Wild Companion',
  'Starry Form',
  'Starry Form: Chalice',
  'Starry Form: Dragon',
  'Maneuvers: Ambush',
  'Superiority Dice',
  'Indomitable',
  'Tireless Spirit',
  'Extra Attack',
  'Primeval Awareness',
  'Ability Score Improvement',
  'Form of the Beast',
  'Form of the Beast: Bite',
  'Form of the Beast: Tail',
  'Form of the Beast: Tail (reaction)',
  'Primal Path',
  'Fast Movement',
  'Unarmored Defense',
  'Relentless Rage',
  'Persistent Rage',
  'Indomitable Might',
  'Primal Champion',
  'Hit Points',
  'Improved Combat Superiority',
  'Magic Item Analysis',
  'Tool Expertise',
  'Wondrous Invention',
  'Infuse Magic',
  'Superior Attunement',
  'Mechanical Servant',
  'Soul of Artifice',
  'Rage',
  'Unarmored Defense',
  'Reckless Attack',
  'Danger Sense',
  'Fast Movement',
  'Feral Instinct',
  'Brutal Critical',
  'Relentless Rage',
  'Persistent Rage',
  'Indomitable Might',
  'Primal Champion',
  'Bardic Inspiration',
  'Jack of all Trades',
  'Song of Rest',
  'Expertise',
  'Countercharm',
  'Magical Secrets',
  'Superior Inspiration',
  'Channel Divinity',
  'Turn Undead (Channel Divinity)',
  'Divine Intervention',
  'Druidic',
  'Wild Shape',
  'Timeless Body',
  'Beast Spells',
  'Archdruid',
  'Fighting Style (Fighter)',
  'Second Wind',
  'Action Surge',
  'Indomitable',
  'Remarkable Athlete',
  'Unarmored Defense',
  'Martial Arts',
  'Ki',
  'Flurry of Blows',
  'Patient Defense',
  'Step of the Wind',
  'Unarmored Movement',
  'Deflect Missiles',
  'Slow Fall',
  'Stunning Strike',
  'Ki-Empowered Strikes',
  'Evasion',
  'Stillness of Mind',
  'Purity of Body',
  'Tongue of the Sun and Moon',
  'Diamond Soul',
  'Timeless Body',
  'Empty Body',
  'Perfect Self',
  'Divine Sense',
  'Lay on Hands',
  'Fighting Style (Paladin)',
  'Divine Smite',
  'Divine Health',
  'Channel Divinity',
  'Aura of Protection',
  'Aura of Courage',
  'Improved Divine Smite',
  'Cleansing Touch',
  'Favored Enemy',
  'Natural Explorer',
  'Fighting Style (Ranger)',
  'Primeval Awareness',
  "Land's Stride",
  'Hide in Plain Sight',
  'Vanish',
  'Feral Senses',
  'Foe Slayer',
  'Favored Enemy',
  'Natural Explorer',
  'Fighting Style (Ranger UA)',
  'Primeval Awareness',
  'Greater Favored Enemy',
  'Fleet of Foot',
  'Hide in Plain Sight',
  'Vanish',
  'Feral Senses',
  'Foe Slayer',
  'Expertise',
  'Sneak Attack',
  "Thieves' Cant",
  'Cunning Action',
  'Uncanny Dodge',
  'Evasion',
  'Reliable Talent',
  'Blindsense',
  'Slippery Mind',
  'Elusive',
  'Sorcery Points',
  'Flexible Casting',
  'Metamagic',
  'Sorcerous Restoration',
  'Careful Spell',
  'Distant Spell',
  'Empowered Spell',
  'Extended Spell',
  'Heightened Spell',
  'Quickened Spell',
  'Subtle Spell',
  'Twinned Spell',
  'Eldritch Invocations',
  'Pact Boon',
  'Mystic Arcanum',
  'Eldritch Master',
  'Arcane Recovery',
  'Spell Mastery',
  'Signature Spells',
  'Ability Score Increase',
  'Age',
  'Languages',
  'Size',
  'Alignment',
  'Speed',
  'Proficiencies',
  'Ability Score Improvement',
  'Hit Points',
  'Roving (6th Level)',
  'Eldritch Mind',
  'Ghostly Gaze',
  'Misty Visions',
  'Humanoids',
  'Forest',
  'Colossus Slayer',
  'Divine Strike',
  'Draconic Ancestry'
]

export const NON_BUFF_RACIAL_TRAITS = [
  'Resourceful',
  'Skillful',
  'Versatile',
  'Breath Weapon',
  'Damage Resistance',
  'Draconic Flight',
  'Activate Large Form',
  'Celestial Revelation',
  'Giant Ancestry',
  'Bite',
  'Alignment',
  'Evasive',
  'Dwarven Resilience',
  'Bewitching Guile',
  'Dwarven Combat Training',
  'Skills',
  'Stonecunning',
  'Superior Darkvision',
  'Drow Weapon Training',
  'Dwarven Toughness',
  'Drow Magic',
  'Feat',
  'Specialized Design',
  'Integrated Protection',
  'Sentry’s Rest',
  'Constructed Resilience',
  'Claws',
  'Shell Defense',
  'Natural Armor',
  'Nature’s Intuition',
  'Hold Breath',
  'Skill Versatility',
  'Creature Type',
  'Size',
  'Speed',
  'Flight',
  'Wind Caller',
  'Ability Score Increases',
  'Languages',
  'Adrenaline Rush',
  'Powerful Build',
  'Breath Weapon (Acid)',
  'Breath Weapon (Fire)',
  'Breath Weapon (Lightning)',
  'Breath Weapon (Poison)',
  'Breath Weapon (Cold)'
]
